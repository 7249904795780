export const placeNames = [
    "Улица",
    "Переулок",
    "Перекресток",
    "Проспект",
    "Площадь",
    "Бульвар",
    "Квартал",
    "Микрорайон",
    "Проезд",
    "Тупик",
    "Станция",
    "Шоссе",
    "Спуск",
    "Набережная",
    "Дачный кооператив",
    "Лесничество",
    "Бухта",
    "Пожарный городок",
    "Поселок",
    "Село",
]