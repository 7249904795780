import React, { useEffect, useState } from 'react'
import CustomAccordion from './utils/CustomAccordion'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Typography } from '@mui/material'
import Select from '@mui/material/Select'
import { FormattedMessage, useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { getComp } from '../../../utils/utils/DI'
import DataUnit from '../DataUnit/DataUnit'
import checkIcon from '../../../assets/images/common/check.svg'
import cross from '../../../assets/images/common/cross_dark.svg'
import { isAdmin, isAdminType2, isOperator } from '../../../services/AccountService'
import { openModalFn } from '../../../utils/controllers/ModalController'
import OrdersStore from '../../../stores/OrdersStore'
import RequestsTableStore from '../../../views/RequestsManagement/components/RequestsTableOptimized/RequestsTableOptimizedStore'

function FinancialSourceAccordion({
    api,
    ids,
    createRequest = false,
    changeFinancialSource,
    baseInputsFormStore,
    setFinancialSourceId,
    data,
    onEdit = false,
    massChange = false,
    massChangeTypeSubsidy,
    flagResonInstallation,
    addReq,
    ordersStore,
    someFullDotationOld,
}) {
    const statusStore = getComp('StatusesStore')
    const territoryStore = getComp('TerritoryStore')
    const { financialSources, getFinancialSourceElement, finSourceId, setFinSourceId, resetFinValue } = statusStore
    const { territories } = territoryStore
    const { someFullDotation } = RequestsTableStore
    const intl = useIntl()
    const [isEdit, setIsEdit] = useState(createRequest)
    const [value, setValue] = useState(getFinancialSourceElement(data?.financialSourceId) || null)

    useEffect(() => {
        if (data) {
            const { territory, district, locality } = data
            const findTerritory = territories[territory]
            const findDistrict = findTerritory?.Territory?.Districts.find((el) => el.Name === district)
            const findLocality = findDistrict?.Localities.find((el) => el.Name === locality)
            const fins = findLocality?.AllowedFinancialSourceIds
            setFinSourceId(fins)
        } else {
            if (!addReq) {
                setFinSourceId(null)
            }
        }
    }, [finSourceId])
    useEffect(() => {
        if (setFinancialSourceId) {
            setFinancialSourceId(value?.id)
        }
    }, [value, finSourceId])

    const financialSource = () => {
        if (isOperator()) {
            if (data?.reasonId === 3 || baseInputsFormStore?.data?.reasonId === '3') {
                return financialSources.filter((item) => [2, 3, 4].includes(item.id))
            } else {
                return financialSources.filter((item) => [3, 4].includes(item.id))
            }
        } else if (baseInputsFormStore?.data?.reasonId == 1 || baseInputsFormStore?.data?.reasonId == 2) {
            return financialSources.filter((item) => item.id === 1 || item.id === 3)
        } else if (data?.reasonId == 1 || data?.reasonId == 2) {
            return financialSources.filter((item) => item.id === 1 || item.id === 3)
        } else if (flagResonInstallation ? flagResonInstallation() : false) {
            return financialSources.filter((item) => item.id === 1 || item.id === 3)
        } else {
            return financialSources.filter((item) => ![4].includes(item.id))
        }
    }
    someFullDotation
    const hendleEdit = () => {
        setIsEdit(!isEdit)
    }

    return (
        <div>
            <CustomAccordion
                summary={intl.formatMessage({ id: 'Тип оплаты' })}
                defaultOpenState={true}
                onEdit={onEdit ? hendleEdit : null}
            >
                {isEdit ? (
                    <Box sx={{ display: 'flex' }}>
                        <FormControl
                            disabled={
                                baseInputsFormStore ? (baseInputsFormStore?.data?.reasonId ? false : true) : false
                            }
                            fullWidth
                            error={value ? false : api?.showErrors}
                        >
                            <InputLabel id='demo-simple-select-label'>
                                <FormattedMessage id={'Оплата'} />
                            </InputLabel>
                            <Select
                                value={value}
                                label={intl.formatMessage({ id: 'Оплата' })}
                                disabled={
                                    baseInputsFormStore ? (baseInputsFormStore?.data?.reasonId ? false : true) : false
                                }
                                onChange={(e) => setValue(e.target.value)}
                            >
                                {financialSource()
                                    .filter((el) => {
                                        // Проверяем результат someFullDotation
                                        const shouldExcludeId1 = massChange
                                            ? isAdmin()
                                                ? someFullDotation()
                                                : someFullDotationOld()
                                            : false

                                        // Если результат true и текущий элемент имеет id 1, исключаем его
                                        if (shouldExcludeId1 && el.id === 1) {
                                            return false
                                        }

                                        // Основная логика фильтрации по finSourceId
                                        if (finSourceId && !isAdminType2()) {
                                            return isOperator()
                                                ? [...finSourceId, 4].includes(el.id)
                                                : finSourceId.includes(el.id)
                                        }

                                        return true
                                    })
                                    .map((el) => (
                                        <MenuItem key={el.id} value={el}>
                                            <Box className={'aln-center'}>
                                                {el.imageSrc ? <img alt={''} src={el.imageSrc} /> : null}
                                                <Box ml={1}>
                                                    <Typography sx={{ fontWeight: '500' }} variant={'subtitle2'}>
                                                        {el.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {(onEdit || (massChange && value)) && (
                            <Box className={'aln-center'} ml={1.5}>
                                <IconButton
                                    onClick={() => {
                                        if (massChangeTypeSubsidy) {
                                            openModalFn['transaction-confirmation']({
                                                text1: `Подтвердить изменение `,
                                                value1: `${ids.length}`,
                                                text2:
                                                    ids.length > 1 ? `заявкам типа оплаты на` : `заявке типа оплаты на`,
                                                value2: `${value?.name}?`,
                                                action: () => massChangeTypeSubsidy(ids, +value.id, ordersStore),
                                                processText: `Выполняется изменение типа оплаты на ${value?.name}`,
                                            })
                                        }
                                        changeFinancialSource && changeFinancialSource(+data.id, +value.id)
                                        setIsEdit(massChange ? true : false)
                                    }}
                                >
                                    <img alt={'Подтвердить'} src={checkIcon} />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setValue(null)
                                        setIsEdit(massChange ? true : false)
                                    }}
                                >
                                    <img alt={'Отменить'} src={cross} width={20} height={20} />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <DataUnit value={value?.name} icon={value?.imageSrc} />
                )}
            </CustomAccordion>
        </div>
    )
}

export default observer(FinancialSourceAccordion)
