const orderHistoryStyles = (theme) => {
    return {
        infoElement: {
            borderRadius: "4px",
            border: "1px solid #EBE8E8",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
            padding: "8px 16px",
            marginTop: "2px"
        },
        imgIcon: {
            width: "20px",
            height: "20px"
        },
        status: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            padding: "4px 0"
        },

        title: {
            fontSize: "12px",
            fontWeight: 400
        },
        text: {
            fontSize: "14px",
            fontWeight: 500
        }
    }
}

export default orderHistoryStyles
