import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import { getComp } from '../../../../utils/utils/DI'
import { isAdmin, isAdministration, isDealer, isOperator } from '../../../../services/AccountService'
import { closeModalFn, openModalFn } from '../../../../utils/controllers/ModalController'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import avatarIcon from '../../../../assets/images/common/avatar.svg'
import { useIntl } from 'react-intl'
import { getStyles } from '../../../../utils/utils/commonUtils'
import requestFiltersStyles from '../../styles/requestFiltersStyles'
import NewFilters from '../../../../components/NewFilters/NewFilters'
import RequestFilterChip from './components/RequestFilterChip'
import newRequest from '../../../../assets/images/orderStatuses/newWhite.svg'
import AddRequestFormStore from '../../../../components/AddRequestDrawer/stores/AddRequestFormStore'
import { useLocation } from 'react-router'
import { getRequestToReport } from '../../../../services/ApiService'
import paramsFilterStore from '../../../SummaryStatisticsDealers/store/ParamsFilterStore'

const NewRequestFilters = ({
    totalCollectionLength,
    defaultParams,
    filtersStore,
    isReportCreate,
    selectOrdersStore,
    openAddRequestDrawer,
    reportRequests,
    actionButtonProps,
    filterRowStyles,
    ordersStore,
    isAgreement,
    operatorStore = {},
    reportMonth,
    scrollIsAlreadyLocked,
    downloadRequestsListPDF,
    selectOrderCollection,
    gray,
    clear,
    search,
    isAddRequestsToReportOpen,
    SSDPageParams = [],
}) => {
    const [formStore] = useState(() => new AddRequestFormStore())
    const classes = getStyles(requestFiltersStyles)
    const statusStore = getComp('StatusesStore')
    const intl = useIntl()
    const authorsStore = getComp('AuthorsStore')
    const categoriesStore = getComp('CategoriesStore')
    const { reasons } = getComp('InstallationReasonStore')
    const sendersStore = getComp('SendersStore')
    const dealersStore = getComp('DealersStore')
    const dataCheckingStatuses = statusStore.getDataCheckingStatuses({ dealerReportsFilters: isReportCreate })
    const sources = getComp('SourcesStore').sources
    const territoryStore = getComp('TerritoryStore')
    const location = useLocation()
    const currentPath = location.pathname
    const filtersData = [
        {
            type: 'select',
            propName: 'statuses',
            multiple: true,
            name: intl.formatMessage({ id: 'Статуc' }),
            collection: statusStore
                .getStatusesByRole()
                .filter((el) =>
                    currentPath === '/summary-statistics-dealers' && !paramsFilterStore.statusesPortal.includes('all')
                        ? paramsFilterStore.statusesPortal.includes(el.id)
                        : true
                ),
            setMenuItem: (data) => (
                <MenuItem value={data.id === 0 ? '' : data.id} key={data.id}>
                    <Box className={'aln-center'}>
                        <img alt={''} src={data.imageSrc} />
                        <Box ml={1} sx={classes.selectTextContainer}>
                            <Typography variant={'subtitle2'} sx={classes.selectText}>
                                {data.name}
                            </Typography>
                        </Box>
                    </Box>
                </MenuItem>
            ),
        },
        [
            {
                type: 'input',
                propName: 'id',
                name: intl.formatMessage({ id: 'Номер заявки' }),
            },
            {
                type: 'input',
                propName: 'phone',
                mask: '+7 (999)-999-99-99',
                name: intl.formatMessage({ id: 'Телефон' }),
            },
        ],
        [
            {
                type: 'input',
                propName: 'surnamePartial',
                name: intl.formatMessage({ id: 'Фамилия' }),
            },
            {
                type: 'input',
                propName: 'namePartial',
                name: intl.formatMessage({ id: 'Имя' }),
            },
        ],
        {
            type: 'input',
            propName: 'fullName',
            name: intl.formatMessage({ id: 'ФИО' }),
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
        },
        {
            propName: 'territory',
            name: intl.formatMessage({ id: 'Регион' }),
            type: 'select',
            collection: territoryStore.collection,
            setMenuItem: (el) => (
                <MenuItem key={el} value={el}>
                    {el}
                </MenuItem>
            ),
        },
        {
            propName: 'district',
            name: intl.formatMessage({ id: 'Округ' }),
            autocompleteOptions: { territory: filtersStore.data.territory, type: 'district' },
            readOnly: filtersStore.data.territory === '',
            disabled: filtersStore.data.territory === '',
            territorySelect: true,
            type: 'autocomplete',
            offError: true,
        },
        {
            propName: 'locality',
            name: intl.formatMessage({ id: 'Населенный пункт' }),
            autocompleteOptions: {
                territory: filtersStore.data['territory'],
                district: filtersStore.data['district'],
                type: 'locality',
            },
            readOnly: filtersStore.data.district === '',
            disabled: filtersStore.data.district === '',
            territorySelect: true,
            type: 'autocomplete',
            offError: true,
        },

        {
            type: 'input',
            propName: 'address',
            name: intl.formatMessage({ id: 'Регион, МО или ГО, Населенный пункт, Адрес' }),
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
            mb: !isOperator() && 2,
        },
        {
            type: 'select',
            multiple: true,
            propName: 'priorities',
            name: intl.formatMessage({ id: 'Приоритет' }),
            placeholder: intl.formatMessage({ id: 'Приоритет' }),
            collection: statusStore.priorityRequestList,
            setMenuItem: (data) => {
                return (
                    <MenuItem key={data.key} value={data.value}>
                        {data.key}
                    </MenuItem>
                )
            },
        },
        {
            type: 'select',
            multiple: true,
            propName: 'financialSourceIds',
            name: intl.formatMessage({ id: isReportCreate ? 'Тип оплаты' : 'Тип дотации' }),
            placeholder: intl.formatMessage({ id: isReportCreate ? 'Тип оплаты' : 'Тип дотации' }),
            collection: statusStore.financialSources.filter((el) => el.id !== 4),
            setMenuItem: (el) => {
                return (
                    <MenuItem key={el.id} value={el.id === 0 ? '' : el.id}>
                        <Box className={'aln-center'}>
                            <img alt={''} src={el.imageSrc} />
                            <Box ml={1}>
                                <Typography variant={'subtitle2'}>{el.name}</Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                )
            },
        },
        [
            {
                type: 'date',
                propName: 'from',
                name: intl.formatMessage({ id: 'Дата создания заявки' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
            },
            {
                type: 'date',
                propName: 'to',
                name: intl.formatMessage({ id: 'Дата создания заявки' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
            },
        ],

        {
            type: 'select',
            propName: 'source',
            name: intl.formatMessage({ id: 'Способ добавления' }),
            collection: sources,
            setMenuItem: (data) => (
                <MenuItem key={data.id} value={data.id}>
                    {data.name}
                </MenuItem>
            ),
        },
        {
            type: 'select',
            propName: 'authorId',
            name: intl.formatMessage({ id: 'Автор' }),
            collection: authorsStore.authors,
            setMenuItem: (data) => {
                return (
                    <MenuItem key={data.id} value={data.id}>
                        {data.name}
                    </MenuItem>
                )
            },
        },
        {
            type: 'select',
            propName: 'sender',
            name: intl.formatMessage({ id: 'Источник' }),
            collection: sendersStore.allSenders,
            setMenuItem: (data) => (
                <MenuItem key={data.id} value={data.id}>
                    {data.name}
                </MenuItem>
            ),
        },

        {
            type: 'input',
            propName: 'reason',
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
            name: intl.formatMessage({ id: 'Причина' }),
        },
        {
            type: 'select',
            propName: 'reasonId',
            name: intl.formatMessage({ id: 'Причина установки' }),
            collection: reasons,
            setMenuItem: (data) => {
                return <MenuItem value={data.value}>{data.key}</MenuItem>
            },
        },
        [
            {
                type: 'date',
                propName: 'installedFrom',
                name: intl.formatMessage({ id: 'Дата установки оборудования' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
                defaultMonth: reportMonth ? moment(reportMonth, 'MM') : moment(),
            },
            {
                type: 'date',
                propName: 'installedTo',
                name: intl.formatMessage({ id: 'Дата установки оборудования' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
                defaultMonth: reportMonth ? moment(reportMonth, 'MM') : moment(),
            },
        ],
        {
            type: 'select',
            propName: 'dealerIds',
            name: intl.formatMessage({ id: 'Дилер' }),
            multiple: true,
            collection: dealersStore.collection,
            setMenuItem: (data) => (
                <MenuItem value={data.aid} key={data.aid} sx={{ padding: '20px 12px' }}>
                    <Box className={'aln-center'} height={20}>
                        <img alt={''} src={avatarIcon} height={20} width={20} />
                        <Box ml={1} sx={classes.selectTextContainer}>
                            <Typography
                                variant={'body2'}
                                sx={{ ...classes.selectTextContainer, ...classes.selectText }}
                            >
                                {data.displayName}
                            </Typography>
                        </Box>
                    </Box>
                </MenuItem>
            ),
        },
        {
            type: 'input',
            propName: 'equipmentId',
            name: intl.formatMessage({ id: 'Id оборудования' }),
        },
        {
            type: 'select',
            propName: 'dataCheckingStatus',
            name: intl.formatMessage({ id: 'Статус проверки данных' }),
            placeholder: intl.formatMessage({ id: 'Статус проверки данных' }),
            collection: dataCheckingStatuses.map((checkingStatus) => {
                return { key: checkingStatus.name, value: checkingStatus.id, color: checkingStatus.color }
            }),
            multiple: true,
        },
        {
            type: 'select',
            propName: 'agreementCheckingStatus',
            name: intl.formatMessage({ id: 'Статус проверки договора' }),
            placeholder: intl.formatMessage({ id: 'Статус проверки договора' }),
            collection: statusStore.agreementCheckingStatuses,
            multiple: true,
            setMenuItem: (data) => (
                <MenuItem value={data.id} key={data.id}>
                    <Box className={'aln-center'}>
                        <img alt={''} src={data.imageSrc} />
                        <Box ml={1} sx={classes.selectTextContainer}>
                            <Typography variant={'subtitle2'} sx={classes.selectText}>
                                {data.name}
                            </Typography>
                        </Box>
                    </Box>
                </MenuItem>
            ),
        },
        {
            type: 'input',
            propName: 'preferentialCategory',
            name: intl.formatMessage({ id: 'Льготная категория' }),
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
        },
        {
            type: 'select',
            propName: 'categories',
            name: intl.formatMessage({ id: 'Льготные категории' }),
            placeholder: intl.formatMessage({ id: 'Льготные категории' }),
            collection: categoriesStore.categoriesMenuData,
            multiple: true,
        },
        {
            type: 'checkbox',
            propName: 'hasDuplicates',
            name: intl.formatMessage({ id: 'Повторное обращение' }),
            visible: true,
            containerStyles: { background: 'white' },
        },
        {
            type: 'checkbox',
            propName: 'isExpired',
            name: intl.formatMessage({ id: 'Просроченные заявки' }),
            visible: true,
            containerStyles: { background: 'white' },
        },
        {
            type: 'checkbox',
            propName: 'isSuspicious',
            name: intl.formatMessage({ id: 'Отмеченные как подозрительные' }),
            visible: true,
            containerStyles: { background: 'white' },
        },
    ]

    const getAdminFilters = () => {
        return currentPath === '/summary-statistics-dealers'
            ? [
                  ['id', 'statuses', 'financialSourceIds', 'reasonId', 'equipmentId'].filter((el) =>
                      paramsFilterStore.statusesPortal.includes(2) ? el !== 'equipmentId' : true
                  ),
              ]
            : [
                  [
                      'statuses',
                      'id',
                      'surnamePartial',
                      'fullName',
                      'territory',
                      'district',
                      'locality',
                      'address',
                      'priorities',
                      'financialSourceIds',
                      'from',
                      'source',
                      'authorId',
                      'sender',
                      'reason',
                      'reasonId',
                  ].filter((el) => currentPath !== '/requests' || el !== 'priorities'),
                  [
                      'installedFrom',
                      'dealerIds',
                      'equipmentId',
                      'dataCheckingStatus',
                      'preferentialCategory',
                      'categories',
                      'hasDuplicates',
                      'isExpired',
                      'isSuspicious',
                  ],
              ]
    }

    const getDealerFilters = () =>
        isReportCreate
            ? isAddRequestsToReportOpen
                ? [
                      ['id', 'fullName'],
                      ['installedFrom', 'address'],
                  ]
                : [
                      ['id', 'fullName', 'financialSourceIds'],
                      ['installedFrom', 'address'],
                  ]
            : [
                  ['statuses', 'id', 'fullName', 'address', 'financialSourceIds', 'installedFrom', 'equipmentId'],
                  ['dataCheckingStatus', 'categories', 'hasDuplicates', 'isExpired', ],
              ]

    const getOperatorFilters = () => {
        const baseFilters = ['id', 'fullName', 'address', 'priorities', 'financialSourceIds'].filter(
            (el) => currentPath !== '/requests' || el !== 'priorities'
        )

        if (operatorStore.selectedTab === 0) {
            return [baseFilters, ['categories', 'hasDuplicates']]
        } else if ([2, 3].includes(operatorStore.selectedTab)) {
            return [[...baseFilters, 'from'], ['hasDuplicates']]
        } else {
            return [baseFilters, ['categories', 'hasDuplicates']]
        }
    }

    const getFiltersData = useMemo(() => {
        let availableFilters = []

        if (isAgreement && isAdmin()) {
            availableFilters = [
                ['id', 'fullName', 'address', 'from', 'dealerIds', 'equipmentId'],
                ['agreementCheckingStatus', 'preferentialCategory', 'categories', 'hasDuplicates'],
            ]
        } else if (isAgreement && isDealer()) {
            availableFilters = [
                ['id', 'fullName', 'address', 'priorities', 'from', 'equipmentId'].filter(
                    (el) => currentPath !== '/requests' || el !== 'priorities'
                ),
                ['agreementCheckingStatus', 'preferentialCategory', 'categories', 'hasDuplicates'],
            ]
        } else if (isAdmin()) {
            availableFilters = getAdminFilters()
        } else if (isDealer()) {
            availableFilters = getDealerFilters()
        } else if (isOperator()) {
            availableFilters = getOperatorFilters()
        } else if (isAdministration()) {
            availableFilters = [
                ['statuses', 'id', 'fullName', 'address', 'priorities', 'financialSourceIds'].filter(
                    (el) => currentPath !== '/requests' || el !== 'priorities'
                ),
                ['from', 'reasonId', 'categories', 'hasDuplicates'],
            ]
        }

        if (reportRequests) {
            const reportRequestsFilters = [
                'id',
                'fullName',
                'address',
                'from',
                'installedFrom',
                'preferenceCategory',
                'categories',
                'dataCheckingStatus',
            ]
            availableFilters = availableFilters.map((el) =>
                el.filter((filterName) => reportRequestsFilters.includes(filterName))
            )
        }

        const setFilterData = () =>
            availableFilters.reduce((acc, filtersArr) => {
                const newFilters = filtersArr.map((propName) => {
                    return filtersData.find((el) =>
                        Array.isArray(el) ? el[0].propName === propName : el.propName === propName
                    )
                })
                acc.push(newFilters)
                return acc
            }, [])

        return setFilterData()
    }, [reasons, filtersData, sources, operatorStore.selectedTab])

    useEffect(() => {
        return () => filtersStore.clear()
    }, [])
    function normalizePhoneNumber(phoneNumber) {
        return phoneNumber.replace(/\D/g, '')
    }
    const onSearch = async () => {
        openModalFn['progress-backdrop']()
        const param = {}

        for (const key in filtersStore.data) {
            if (!!filtersStore.data[key]) {
                switch (key) {
                    case 'dealerIds':
                        if (filtersStore.data[key] === 'none') {
                            param.withNoDealer = true
                            break
                        }
                        param[key] = filtersStore.data[key].map((aid) => dealersStore.getDealerBuyAid(aid)?.subjectId)
                        break
                    case 'from':
                    case 'to':
                    case 'installedFrom':
                    case 'installedTo':
                        param[key] = filtersStore.data[key].format('YYYY-MM-DDTHH:mm:ssZ')
                        break
                    case 'source':
                        param['sourceId'] = filtersStore.data[key]
                        break
                    case 'sender':
                        param['senderId'] = filtersStore.data[key]
                        break
                    case 'id':
                        param['orderId'] = filtersStore.data[key]
                        break
                    case 'phone':
                        param['phone'] = normalizePhoneNumber(filtersStore.data[key])
                        break
                    case 'phoneNumber':
                        param['phoneNumber'] = normalizePhoneNumber(filtersStore.data[key])
                        break
                    default:
                        param[key] = filtersStore.data[key]
                        break
                }
            }
        }

        if (filtersStore.data.priorities.includes('Без приоритета')) {
            const index = param['priorities'].indexOf('Без приоритета')
            if (index !== -1) {
                param['priorities'][index] = ''
            }
        }

        filtersStore.makeParamCopy({ ...param, ...(defaultParams ? defaultParams : {}) })
        if (selectOrdersStore && !isReportCreate) {
            selectOrdersStore.clearSelectOrders()
        }

        const mergeParams = (param, SSDPageParams) => {
            const result = { ...SSDPageParams }

            Object.keys(param).forEach((key) => {
                const value = param[key]
                if (
                    (Array.isArray(value) && value.length > 0) ||
                    (typeof value === 'number' && value >= 0) ||
                    (typeof value === 'boolean' && value) ||
                    (typeof value === 'string' && value)
                ) {
                    result[key] = value
                }
            })

            return result
        }
        if (search) {
            await ((isOperator() && operatorStore.selectedTab === 0 && operatorStore) || ordersStore)
                .reLoad({
                    reportId: search,
                    params: {
                        ...{ ...mergeParams(param, SSDPageParams) },
                        size: defaultParams ? 2000 : ordersStore.pageSize,
                    },
                })
                .then(() => {
                    filtersStore.setSelectedFilters()
                    filtersStore.makeHighlightTrue()
                })
                .finally(() => closeModalFn['progress-backdrop']())
        } else {
            await ((isOperator() && operatorStore.selectedTab === 0 && operatorStore) || ordersStore)

                .reLoad({
                    ...{ ...mergeParams(param, SSDPageParams), ...defaultParams },
                    size: defaultParams ? 2000 : ordersStore.pageSize,
                })
                .then(() => {
                    filtersStore.setSelectedFilters()
                    filtersStore.makeHighlightTrue()
                })
                .finally(() => closeModalFn['progress-backdrop']())
        }
    }
    const handleDeleteChip = (propName, multiselectValue) => {
        filtersStore.deleteFilter(propName, multiselectValue)
        clear ? clear() : onSearch()
    }
    const setChip = (params, withAction) => <RequestFilterChip {...params} onClick={withAction && handleDeleteChip} />
    return (
        <NewFilters
            gray={gray}
            selectOrdersStore={selectOrdersStore}
            selectOrderCollection={selectOrderCollection}
            downloadRequestsListPDF={downloadRequestsListPDF}
            totalCollectionLength={totalCollectionLength}
            filtersStore={filtersStore}
            onClear={
                clear
                    ? () => {
                          filtersStore.clear()
                          clear()
                      }
                    : null
            }
            filtersData={getFiltersData}
            onSearch={onSearch}
            setChip={setChip}
            actionButtonProps={
                actionButtonProps ||
                (openAddRequestDrawer && {
                    name: intl.formatMessage({ id: 'Создать заявку' }),
                    onClick: openAddRequestDrawer,
                    icon: newRequest,
                })
            }
            filterRowStyles={filterRowStyles}
            scrollIsAlreadyLocked={scrollIsAlreadyLocked}
        />
    )
}

export default observer(NewRequestFilters)
