import { makeObservable, observable } from 'mobx'
import RequestBaseStore from '../../../stores/RequestBaseStore'
import {
    addComment,
    endOrderClarifyData,
    orderCheck,
    orderClarifyData,
    orderToClarify,
    removeOrders,
    updateRequest,
} from '../../../services/ApiService'
import AddPhotosStore from './AddPhotosStore'
import { notifyError, notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'
import React from 'react'

class ReportOrderDetailsStore extends RequestBaseStore {
    constructor({ reportId, ordersStore }) {
        super()
        this.reportId = reportId
        this.addPhotosStore = new AddPhotosStore()
        this.isOrderDetailsOpen = false
        this.ordersStore = ordersStore
        this.isOrderEdit = false
        this.comment = ''
        this.newEquipment = 0
        this.attachImageError = false
        this.currentOpenedRowIndex = null // индекс строки в дровере
        makeObservable(this, {
            reportId: observable,
            isOrderDetailsOpen: observable,
            isOrderEdit: observable,
            comment: observable,
            attachImageError: observable,
            currentOpenedRowIndex: observable,
            newEquipment: observable,
        })
    }

    getCurrentOrder() {
        return this.ordersStore.collection.find((order) => order.id === this.data.id)
    }

    setOrderDataRequest = (isSuspicious) => {
        const reloadCallback = () => {
            this.ordersStore.updateOrder(this.data.id).then((order) => {
                // В случае отсутствия ордера в таблице - закрываем дровер
                if (!order) {
                    return this.closeOrderDetails()
                }
                return this.setData(order)
            })
        }
        if (isSuspicious) {
            this.data.isSuspicious = !this.data.isSuspicious
            this.setOrderData(this.data.id, { isSuspicious: this.data.isSuspicious }, reloadCallback)
        } else {
            this.data.hasDuplicates = !this.data.hasDuplicates
            this.setOrderData(this.data.id, { hasDuplicates: this.data.hasDuplicates }, reloadCallback)
        }
    }

    handleEditOrder = () => {
        this.isOrderEdit = !this.isOrderEdit
        this.addPhotosStore.clear()
    }

    setNewEquipment = (value) => {
        this.newEquipment = value
    }
    changeEquipment = (callback) => {
        updateRequest(this.data.id, {
            equipmentId: this.newEquipment,
            updateComment: this.comment,
        })
            .then(() => {
                // this.reLoad()

                this.ordersStore.updateOrder(this.data.id).then((order) => {
                    if (!order) {
                        return this.closeOrderDetails()
                    }
                    notifySuccess('Id оборудования обновлён')
                    this.comment = ''
                    return this.setData(order)
                })
            })
            .then(() => {
                if (callback) {
                    callback()
                }
            })
    }

    openOrderDetails = (params) => {
        this.addPhotosStore.clear()
        this.comment = ''
        this.clear()

        this.setData(params.data)
        this.getImages()
        this.isOrderDetailsOpen = true
    }

    setCurrentOpenedRowIndex = (rowIndex) => {
        this.isOrderEdit = false
        this.currentOpenedRowIndex = rowIndex
    }

    closeOrderDetails = () => {
        this.isOrderDetailsOpen = false
        this.isOrderEdit = false
        this.addPhotosStore.clear()
        this.comment = ''
        this.clear()
    }

    setComment = (e) => {
        this.comment = e.target.value
    }

    updateOrders = (newOrders, callback) => {
        this.ordersStore.collection.map((item) => {
            const updatedOrder = newOrders.find((order) => order.id === item.id)
            return updatedOrder ? { ...item, ...updatedOrder } : item
        })
        if (callback) {
            callback()
        }
    }

    apiCallCallback = () => {
        this.comment = ''
        this.ordersStore.updateOrder(this.data.id).then((order) => {
            if (order) {
                this.setData(order)
            } else {
                if (window.location.pathname !== '/summary-statistics-dealers') {
                    this.closeOrderDetails()
                }
            }
        })
    }

    toClarify = () => {
        orderToClarify({ orderId: this.data.id, comment: this.comment }).then(() => this.apiCallCallback())
    }

    toAddComment = (callback) => {
        if (this.comment) {
            addComment({ orderId: this.data.id, text: this.comment })
                .then(() => (this.comment = ''))
                .then(() => this.apiCallCallback())
                .then(() => callback())
                .then(() => notifySuccess('Заявка обновлена'))
        }
    }

    checkOrder = () => {
        orderCheck({ orderId: this.data.id }).then(() => this.apiCallCallback())
    }

    removeOrder = (id, callback) => {
        const actionFunction = () => {
            this.currentOpenedRowIndex = null
            removeOrders({
                reportId: this.reportId,
                orderIdList: this.data.id ? [this.data.id] : [...id],
            })
                .then(() => {
                    closeModalFn['progress-backdrop']()
                    notifySuccess(
                        id.length > 1
                            ? `Заявки №${this.data.id ? this.data.id : id} удалены из отчета  №${this.reportId}`
                            : `Заявка №${this.data.id ? this.data.id : id} удалена из отчета  №${this.reportId}`
                    )
                    this.closeOrderDetails()
                    this.ordersStore.reLoad()
                })
                .catch(() => closeModalFn['progress-backdrop']())
        }

        openModalFn['transaction-confirmation']({
            text1: id.length > 1 ? 'Подтвердить удаление заявок' : 'Подтвердить удаление заявки',
            value1: `№${this.data.id ? this.data.id : id}`,
            text2: 'из отчета',
            value2: `№${this.reportId}?`,
            action: actionFunction,
            collback: callback,
            processText:
                id.length > 1
                    ? `Выполняется удаление заявок №${this.data.id ? this.data.id : id} из отчета №${this.reportId}  `
                    : `Выполняется удаление заявки №${this.data.id ? this.data.id : id} из отчета №${this.reportId}  `,
        })
    }

    checkUploadedImages = (res) => {
        const { uploaded, message, images } = res
        const errorImages = images.filter((el) => !el.isValid)
        if (uploaded) {
            notifySuccess(message)
        }
        if (errorImages.length) {
            errorImages.forEach((el) => {
                notifyError(el.error + ': ' + el.filename)
            })
        }
        if (!uploaded) {
            if (!errorImages.length) {
                notifyError(message)
            }
            this.attachImageError = true
        }
    }

    clarifyData = (data, reloadCallback) => {
        this.attachImageError = false
        const files = this.addPhotosStore.data.images.install.map((el) => el.file)
        const params = { orderId: this.data.id, ...data, ...files }
        orderClarifyData(params).then((res) => {
            this.checkUploadedImages(res)
            if (!this.attachImageError) {
                if (reloadCallback) {
                    reloadCallback()
                    this.getImages()
                    return
                }
                this.updateDataCallback()
            }
        })
    }

    updateDataCallback = () => {
        this.ordersStore.updateOrder(this.data.id, this.reportId).then((order) => {
            if (order) {
                this.setData(order)
            }
            this.isOrderEdit = false
        })
        this.getImages()
    }

    updateAdminData = (data) => {
        return updateRequest(data.orderId, data).then(() => {
            this.updateDataCallback()
        })
    }

    endClarifyData = () => {
        const params = this.comment.trim().length
            ? { orderId: this.data.id, comment: this.comment }
            : { OrderId: this.data.id }
        endOrderClarifyData(params).then(() => {
            this.apiCallCallback()
        })
    }
}

export default ReportOrderDetailsStore
