import React from "react"
import {Checkbox} from "@mui/material"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CustomAccordion from "./utils/CustomAccordion"
import {useIntl} from "react-intl"
import {observer} from "mobx-react"
import { isLooker } from "../../../services/AccountService"

const HasDuplicates = ({onChange, data}) => {
    const intl = useIntl()
    return (
        <CustomAccordion
            summary={intl.formatMessage({id: "Повторное обращение"})}
            defaultOpenState={false}
        >
            <Box
                className={"fx-nowrap aln-center"}
                sx={{cursor: "pointer"}}
                
                onClick={isLooker()?()=>alert():()=>onChange(false)}
            >
                <Checkbox
                    checked={data.hasDuplicates}
                    disabled={isLooker()}
                />
                <Typography variant={"subtitle1"}>
                    {intl.formatMessage({id: "Отметить как повторное обращение"})}
                </Typography>
            </Box>
        </CustomAccordion>

    )
}

export default observer(HasDuplicates)