import React from 'react'
import { observer } from 'mobx-react'
import DataUnit from '../DataUnit/DataUnit'
import Box from '@mui/material/Box'
import moment from 'moment'
import CustomAccordion from './utils/CustomAccordion'
import { getComp } from '../../../utils/utils/DI'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { isAdmin, isAdministration, isDealer, isLooker, isOperator } from '../../../services/AccountService'
import { formatID } from 'src/helpers/ordersUtiles'
import OrderInfoChips from '../../OrderInfoChips/OrderInfoChips'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router'

function RequestInfo({ request, defaultOpenState, openReportDetails }) {
    const location = useLocation();
    const currentPath = location.pathname;
    const serviceOffLinck = currentPath === '/service' ? false : true
    const intl = gState['intl']
    const { getSourceName } = getComp('SourcesStore')
    const authorsStore = getComp('AuthorsStore')
    const { created, source, authorId, sender, reportId, status } = request
    const filteredAuthor = authorsStore.authors.find((item) => item.id === authorId)
    return (
        <CustomAccordion
            defaultOpenState={defaultOpenState}
            summary={intl.formatMessage({ id: 'Информация о заявке' })}
        >
            <DataUnit name={intl.formatMessage({ id: 'Номер заявки' })} value={formatID(request)} />
            <Box mt={2}>
                <DataUnit
                    name={intl.formatMessage({ id: 'Дата создания' })}
                    value={moment(created).format('DD.MM.YYYY, HH:mm')}
                />
            </Box>
            {isAdmin() ? (
                <>
                    <Box mt={2}>
                        <DataUnit
                            name={intl.formatMessage({ id: 'Способ добавления' })}
                            value={getSourceName(source)}
                        />
                    </Box>
                    <Box mt={2}>
                        <DataUnit
                            name={intl.formatMessage({ id: 'Автор' })}
                            value={filteredAuthor ? filteredAuthor.name : ''}
                        />
                    </Box>
                </>
            ) : null}
            {isAdmin() || isOperator() || isAdministration() ? (
                <Box mt={2}>
                    <DataUnit name={intl.formatMessage({ id: 'Источник' })} value={sender} />
                </Box>
            ) : null}
            {isAdmin() && !!reportId  ? (
                // В некоторых условиях номер отчета кликабельный и открывает окно отчета
                <Box mt={2} style={{ display: 'flex' }}>
                    {status >= 12 && !isLooker() && serviceOffLinck ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography variant={'body2'} color={'grey.500'} sx={{ lineHeight: 1 }}>
                                {intl.formatMessage({ id: 'В отчёте' })}
                            </Typography>
                            <OrderInfoChips
                                openReportDetails={openReportDetails}
                                id={reportId}
                                propsStyle={{ margin: '4px 0 0 0', padding: 0 }}
                            />
                        </Box>
                    ) : (
                        <DataUnit name={intl.formatMessage({ id: 'В отчёте' })} value={reportId} />
                    )}
                </Box>
            ) : null}
        </CustomAccordion>
    )
}

export default observer(RequestInfo)
