import {observer} from "mobx-react"
import React, {useEffect} from "react"
import {FormattedMessage} from "react-intl"
import RightDrawer from "../../../../components/customElements/RightDrawer/RightDrawer"
import ReportInfo from "./utils/ReportInfo"
import ReportCommentBlock from "./utils/ReportCommentBlock"
import ActionButtons from "./utils/ActionButtons"
import ReportChangeStatus from "./utils/ReportChangeStatus"

function ReportInfoDrawer({store, onTop, updateRequestManagmentTable}) {
    const {isReportDrawerOpen, closeReportDrawer, data} = store
    useEffect(() => {
        if (isReportDrawerOpen) {
            store.reLoad()
        }
    }, [isReportDrawerOpen])
    return (
        <RightDrawer
            open={isReportDrawerOpen}
            onClose={closeReportDrawer}
            title={
                <>
                    <FormattedMessage id={"Отчет"} /> №{data.id}
                </>
            }
            backButtonClick={store.contentState === "default" ? closeReportDrawer : store.toDefaultState}
            onTop={onTop}
        >
            {isReportDrawerOpen ? (
                <>
                    {store.contentState === "default" && (
                        <>
                            <ReportInfo report={data} />
                            <ReportCommentBlock store={store} status={data.status} />
                        </>
                    )}
                    {store.contentState === "changeRechecking" && <ReportChangeStatus store={store} />}
                    <ActionButtons store={store} updateRequestManagmentTable={updateRequestManagmentTable} />
                </>
            ) : null}
        </RightDrawer>
    )
}

export default observer(ReportInfoDrawer)
