import { observer } from 'mobx-react'
import CustomAccordion from './utils/CustomAccordion'
import React from 'react'
import { gState } from '../../../utils/controllers/GlobalStateController'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import UploadedPhoto from '../UploadedPhoto/UploadedPhoto'
import { isAdmin, isAdministration, isDealer, isOperator } from '../../../services/AccountService'

function UploadedPhotos({ store, onEdit, defaultOpenState, reloadCallback }) {
    const intl = gState['intl']
    const { install, impossible, uncompleted, clarify } = store.data ? store.data.images : store.images
    const { photoPermission } = store.photoSettings
    const data = [
        { name: intl.formatMessage({ id: 'Установка' }), collection: install },
        { name: intl.formatMessage({ id: 'Установка невозможна' }), collection: impossible },
        { name: intl.formatMessage({ id: 'На доработку' }), collection: uncompleted },
        { name: intl.formatMessage({ id: 'Уточнение данных' }), collection: clarify },
    ]
    const { status, dataCheckingStatus, reportStatus } = store.data

    const deletePhoto = () => {
        if (isAdmin()) {
            if ([2].includes(reportStatus)) {
                // Проверка отчетов
                return [13].includes(status) && [0, 3].includes(dataCheckingStatus);
            }
           
        }
        if (isDealer()) {
            if ([3].includes(reportStatus)) {
                // Отчеты
                return [13, 14].includes(status) && [3].includes(dataCheckingStatus);
            }
            // Заявки на установку
            return  ([6].includes(status) && [2, 3].includes(dataCheckingStatus));
        }
    }
    
    

    

    const setImagesBlock = ({ name, collection }) => {
        return collection?.length ? (
            <Box mb={1}>
                <Typography variant={'body2'} color={'grey.500'} sx={{ lineHeight: 1, marginBottom: '10px' }}>
                    {name}
                </Typography>
                <Box mt={0.5} sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: '4px' }}>
                    {collection.map((el, i) => {
                        return (
                            <UploadedPhoto
                                key={el.url}
                                collection={collection}
                                photo={el}
                                i={i}
                                // последние 2 фотки подраздела Установлено удалить нельзя. Остальные можно.
                                isForDelete={
                                    deletePhoto()
                                        ? true
                                        : name !== 'Установка' ||
                                          (name === 'Установка' && [...collection.filter((el) => !el.isRemoved)])
                                        ? photoPermission.isForDelete
                                        : false
                                }
                                store={store}
                                reloadCallback={reloadCallback}
                                name={name}
                            />
                        )
                    })}
                </Box>
            </Box>
        ) : (
            <></>
        )
    }

    return photoPermission.oneOfCollectionNotEmpty && !isOperator() && !isAdministration() ? (
        // <CustomAccordion
        //     summary={intl.formatMessage({id: "Фотографии"})}
        //     onEdit={onEdit}
        //     defaultOpenState={defaultOpenState}
        // >
        //  </CustomAccordion>
        <Box mt={2}>{data.map((el) => setImagesBlock(el))}</Box>
    ) : null
}

export default observer(UploadedPhotos)
