import { makeAutoObservable } from 'mobx'
import { gState } from '../../../../utils/controllers/GlobalStateController'
import { isAdmin, isAdministration } from '../../../../services/AccountService'
import enter from 'src/assets/images/common/enter.svg'
import fullSubsidy from 'src/assets/images/financialSource/typeSubsidy.svg'
import massCreated from 'src/assets/images/common/massCreated.svg'
import { getComp } from '../../../../utils/utils/DI'
import { closeModalFn, openModalFn } from '../../../../utils/controllers/ModalController'
import { assignDealer, resetToCreatedGroup, updateFinancialSourceGroup } from '../../../../services/ApiService'
import { notifyError, notifySuccess } from '../../../../services/ToastifyService/ToastifyService'
import OrdersStore from 'src/stores/OrdersStore'
import getNoun from '../../../../utils/utils/getNoun'
class RequestsTableStore {
    data = []
    isTableRowSelected = null
    selected = []
    firstItem = null
    newDealer = null
    massOperation = false
    actionType = ''
    isAssignDealerDrawerOpen = false
    isMassChangePriorityDrawerOpen = false
    isMassChangeTypeSubsidyOpen = false
    isMassChangeStatusDrawerOpen = false
    selectAllMode = false

    constructor() {
        makeAutoObservable(this)
        this.territoryStore = getComp('TerritoryStore')
        this.dealersStore = getComp('DealersStore')
    }

    outlineTrue = (row) => {
        this.data.map((item) => {
            if (item.id === row.id) {
                item.outline = true
            } else {
                item.outline = false
            }
            return item
        })
    }

    closeMassOperation = () => {
        this.massOperation = false
    }

    openMassOperation = () => {
        this.massOperation = true
    }

    addServiceProps = (collection = this.data) => {
        this.data = collection.map((row) => {
            if (this.selected.includes(row.id)) {
                row.checked = true
            } else {
                row.checked = false
            }

            if (this.selectAllMode || [1, 2, 4].includes(row.status)) {
                row.showChecbox = true
            } else {
                row.showChecbox = false
            }

            row.outline = false

            return row
        })

        if (!this.selectAllMode && this.firstItem) {
            this.data.map((item) => {
                item.showChecbox = false
                if (item.status === this.firstItem.status) {
                    item.showChecbox = true
                    return item
                }
            })
        } else
            this.data.map((item) => {
                if (this.selectAllMode || [1, 2, 4].includes(item.status)) {
                    item.showChecbox = true
                } else {
                    item.showChecbox = false
                }
                return item
            })
        // this.clearSelectOrders()
    }

    toggleSelection = (row) => {
        const { id } = row
        if (this.firstItem && this.selected.length === 1 && this.selected.includes(id)) {
            this.firstItem = null
        } else if (!this.firstItem && this.selected.length === 0) {
            this.firstItem = row
        }
        this.data.map((item) => {
            if (item.id === id) {
                item.checked = !item.checked
            }
            return item
        })
        if (!this.selectAllMode && this.firstItem) {
            this.data.map((item) => {
                item.showChecbox = false
                if (item.status === this.firstItem.status) {
                    item.showChecbox = true
                    return item
                }
            })
        } else
            this.data.map((item) => {
                if (this.selectAllMode || [1, 2, 4].includes(item.status)) {
                    item.showChecbox = true
                } else {
                    item.showChecbox = false
                }
                return item
            })

        this.getIdSelectedOrders()
    }

    someFullDotation = () => {
        const selectedRow = this.data.filter((row) => row.checked)
        return selectedRow.some((row) => {
            const districts = this.territoryStore.territories[row.territory]?.Territory.Districts
            const district = districts.find((district) => district.Name === row.district)

            if (!district) return false

            const locality = district.Localities.find((locality) => locality.Name === row.locality)

            if (!locality) return false

            return locality.AllowedFinancialSourceIds.every((id) => id !== 1)
        })
    }
    isSomeChecked = () => {
        return this.data.some((item) => item.checked)
    }

    allSelectedCheckbox = () => {
        if (!this.firstItem && !this.selectAllMode) {
            this.firstItem = this.data.find((item) => [1, 2, 4].includes(item.status))
        }
        const elements = this.firstItem
            ? this.data.filter(
                  (item) =>
                      item.status === this.firstItem.status &&
                      item.financialSourceId === this.firstItem.financialSourceId
              )
            : this.data

        if (elements.every((item) => item.checked)) {
            this.data.map((item) => {
                item.checked = false
                item.showChecbox = this.selectAllMode || [1, 2, 4].includes(item.status)
                this.firstItem = null
                return item
            })
        } else {
            this.data.map((item) => {
                item.checked = this.selectAllMode || item.status === this.firstItem?.status
                item.showChecbox = this.selectAllMode || item.status === this.firstItem?.status
                return item
            })
        }
        this.getIdSelectedOrders()
    }

    isIndeterminate = () => {
        return this.data.some((item) => item.checked) && !this.isAllSelected()
    }

    isAllSelected = () => {
        const elements = this.firstItem ? this.data.filter((item) => item.status === this.firstItem.status) : this.data
        return !elements.some((item) => !item.checked)
    }

    getIdSelectedOrders = () => {
        this.selected = this.data.filter((item) => item.checked).map((item) => item.id)
        this.isTableRowSelected = this.selected.length
    }

    clearSelectOrders = () => {
        this.firstItem = null
        this.selected = []
        // this.data.map((item) => {
        //     item.checked = false
        //     return item
        // })
        this.addServiceProps(this.data)
    }

    updateOrders = (newOrders, callback) => {
        this.data = this.data.map((item) => {
            const updatedOrder = newOrders.find((order) => order.id === item.id)
            return updatedOrder ? { ...item, ...updatedOrder } : item
        })
        if (callback) {
            callback()
        }
    }

    getActionTabs = () => {
        return [
            {
                name: gState['intl'].formatMessage({ id: 'Назначить дилера' }),
                action: this.toggleAssignDealerDrawer,
                icon: enter,
                isActive: isAdmin(),
                status: [1, 2],
            },
            {
                name: gState['intl'].formatMessage({ id: 'Перевести в статус "Создана"' }),
                action: this.toggleMassChangeStatusDrawer,
                icon: massCreated,
                isActive: isAdmin(),
                forStatus: true,
                status: [2, 4],
            },
            // {
            //     name: gState['intl'].formatMessage({ id: 'Изменить приоритет' }),
            //     action: this.toggleMassChangePriorityDrawer,
            //     icon: roundedOne,
            //     isActive: isAdmin() || isOperator(),
            // },
            {
                name: gState['intl'].formatMessage({ id: 'Изменить тип оплаты' }),
                action: this.toggleMassChangeTypeSubsidy,
                icon: fullSubsidy,
                isActive: isAdmin() || isAdministration(),
                status: [1, 2],
            },

            // {
            //     name: gState['intl'].formatMessage({ id: 'Принять заявки' }),
            //     action: this.bulkAccept,
            //     isActive: isDealer(),
            // },
        ]
    }

    toggleAssignDealerDrawer = () => {
        if (this.massOperation) {
            this.closeMassOperation()
        } else {
            this.newDealer = null
            this.openMassOperation()
            this.actionType = 'assignToDealer'
        }
    }

    toggleMassChangeStatusDrawer = () => {
        if (this.massOperation) {
            this.closeMassOperation()
        } else {
            this.openMassOperation()
            this.actionType = 'changeStatus'
        }
    }

    toggleMassChangePriorityDrawer = () => {
        this.isMassChangePriorityDrawerOpen = !this.isMassChangePriorityDrawerOpen
    }

    toggleMassChangeTypeSubsidy = () => {
        if (this.massOperation) {
            this.closeMassOperation()
        } else {
            this.openMassOperation()
            this.actionType = 'changeTypeSubsidy'
        }
    }

    containsSubstring(errorString, substring) {
        return errorString.includes(substring)
    }

    bulkAccept = () => {
        openModalFn['progress-backdrop']()
        bulkAccept({ orderIdList: [...this.selectedOrders] })
            .then(() => {
                this.ordersStore.reLoad().finally(() => closeModalFn['progress-backdrop']())
                this.clearSelectOrders()
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    assignDealer = (ordersStore) => {
        
        const size = this.selected.length
        openModalFn['progress-backdrop']()
        assignDealer({
            dealerId: this.dealersStore.getDealer(this.newDealer)?.subjectId,
            orderIds: [...this.selected],
        })
            .then((res) => {
                const notification = () => {
                    const dealerName = this.dealersStore.getDealer(this.newDealer).displayName
                    closeModalFn['progress-backdrop']()
                    const message = gState['intl'].formatMessage(
                        {
                            id: '{size} {sizeNoun} {sizeNounAction} на дилера {dealer}',
                            defaultMessage: `{size} {sizeNoun} {sizeNounAction} на дилера {dealer}`,
                        },
                        {
                            size: size,
                            sizeNoun: getNoun(size, 'заявка', 'заявки', 'заявок'),
                            sizeNounAction: getNoun(size, 'назначена', 'назначены', 'назначены'),
                            dealer: dealerName,
                        }
                    )
                    notifySuccess(message)
                }
                this.updateOrders(res, notification)
                ordersStore.setData(this.data)
                this.toggleAssignDealerDrawer()
                this.clearSelectOrders()
            })
            .catch((err) => {
                closeModalFn['progress-backdrop']()
            })
            .finally(() => {
                
                closeModalFn['modal-confirmation-submit-dialog']()
                closeModalFn['progress-backdrop']()
            })
    }

    massChangeStatus = (ids, ordersStore) => {
        openModalFn['progress-backdrop']()
        resetToCreatedGroup({ orderIds: [...ids] })
            .then((res) => {
                this.updateOrders(res, notifySuccess('Заявки переведены в статус "Создана"'))

                ordersStore.setData(this.data)
                this.toggleMassChangeStatusDrawer()
                this.clearSelectOrders()
            })
            .catch(() => {
                this.toggleMassChangeStatusDrawer()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }
    massChangeTypeSubsidy = (ids, financialSourceId, ordersStore) => {
        openModalFn['progress-backdrop']()
        updateFinancialSourceGroup({ orderIds: [...ids], financialSourceId: financialSourceId })
            .then((res) => {
                this.updateOrders(
                    res,
                    notifySuccess(ids.length > 1 ? 'Тип оплаты заявок обновлен' : 'Тип оплаты заявки обновлен')
                )
                ordersStore.setData(this.data)
                this.toggleMassChangeTypeSubsidy()
                this.clearSelectOrders()
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
                this.toggleMassChangeTypeSubsidy()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    flagResonInstallation = (ordersStore) => {
        return ordersStore.collection
            .filter((item) => [...this.selected].includes(item.id))
            .some((item) => item.reasonId === 1 || item.reasonId === 2)
    }
}

const store = new RequestsTableStore()
export default store
