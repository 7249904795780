import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DealerCard from './DealerCard'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormInput from '../../../../../../components/customElements/FormInput'
import Stack from '@mui/material/Stack'
import { css } from '@emotion/css'
import { getStyles } from '../../../../../../utils/utils/commonUtils'
import requestsManagementStyles from '../../../../styles/requestsManagementStyles'
import { getComp } from '../../../../../../utils/utils/DI'
import DealerFormStore from '../../../../../../stores/DealerFormStore'
import { gState } from '../../../../../../utils/controllers/GlobalStateController'
import { FormattedMessage } from 'react-intl'
import CustomAccordion from '../../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'

function DealerSelect({ store, massAssign, currentServiceData}) {
    const intl = gState['intl']
    const classes = getStyles(requestsManagementStyles)
    const dealersStore = getComp('DealersStore')
    const [dealerFilterStore] = useState(() => new DealerFormStore())
    
    useEffect(() => {
        store.newDealer = null
    },[])
    const currentDealerId = massAssign ?  null : currentServiceData? currentServiceData?.assignedDealerId : store.ordersStore?.currentServiceData?.assignedDealerId

    const selectDealer = (subjectId) => {
        store.newDealer = subjectId
    }
    const searchTabs = useMemo(
        () => [
            // {propName: 'aid', name: intl.formatMessage({id: 'ID дилера'})},
            { propName: 'displayName', name: intl.formatMessage({ id: 'Имя' }) },
        ],
        []
    )
    const [searchTab, setSearchTab] = useState(searchTabs[0].propName)
    const { subjectId, displayName } = dealerFilterStore.data
        
    const filteredDealers = () => {
        // фильтрация дилеров по текущему дилеру заявки

        const collectionWithoutCurrentDealer = dealersStore.collection.filter((el) => {
            return el.subjectId !== currentDealerId
        })

        if (searchTab === 'subjectId' && subjectId) {
            return collectionWithoutCurrentDealer.filter((el) => {
                return el.subjectId.includes(subjectId)
            })
        } else if (searchTab === 'displayName' && displayName) {
            return collectionWithoutCurrentDealer.filter((el) => {
                return el.displayName.toLowerCase().includes(displayName.toLowerCase())
            })
        } else {
            return collectionWithoutCurrentDealer
        }
    }
    
    return (
        <React.Fragment>
            {/* {!!store.newDealer && (
                <Box name={"chosen-dealer"}>
                    <Typography variant={"body2"}>
                        <FormattedMessage id={"Выбранный"}/>
                    </Typography>
                    <Box mt={0.5}>
                        <DealerCard
                            selected={true}
                            dealer={dealersStore.getDealer(store.newDealer)}
                        />
                    </Box>
                </Box>
            )} */}

            <Box
                name={'dealer-search'}
                sx={{
                    marginTop: store.data?.status === 123 || !store.data ? '20px' : '0px',
                    // marginBottom: '15px', убрал на время, проверить
                }}
            >
                {/* <Tabs value={searchTab} sx={classes.tabs} aria-label="basic tabs example">
                    {searchTabs.map((el) => (
                        <Tab
                            key={el.propName}
                            sx={{...classes.tab, ...(searchTab === el.propName && classes.selectedTab)}}
                            label={el.name}
                            onClick={() => setSearchTab(el.propName)}
                        />
                    ))}
                </Tabs> */}
            </Box>
            <Box mx={-2}>
                <CustomAccordion defaultOpenState={true} summary={intl.formatMessage({ id: 'Назначить на дилера' })}>
                    {searchTabs.map((el, i) => (
                        <Box sx={{ display: searchTab !== el.propName ? 'none' : 'block', marginY: '10px' }}>
                            <FormInput fullWidth api={dealerFilterStore} propName={el.propName} label={el.name} />
                        </Box>
                    ))}
                    <Stack
                        name={'dealers-list'}
                        spacing={1}
                        className={css(classes.dealersScrollContainer)}
                        style={{ minHeight: store.newDealer ? '36vh' : '45vh' }}
                    >
                        <>
                            {filteredDealers().map((dealerItem) => (
                                <DealerCard
                                    key={dealerItem.subjectId}
                                    dealer={dealerItem}
                                    selected={store.newDealer === dealerItem.subjectId}
                                    onSelect={() => selectDealer(dealerItem.subjectId)}
                                />
                            ))}
                        </>
                    </Stack>
                </CustomAccordion>
            </Box>
        </React.Fragment>
    )
}

export default observer(DealerSelect)
