import {observer} from "mobx-react"
import React, {useEffect} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import cross from "src/assets/images/common/cross_dark.svg"
import Paper from "@mui/material/Paper"
import {getStyles} from "src/utils/utils/commonUtils"
import detailsPageStyles from "src/components/DetailsPage/styles/detailsPageStyles"

function DetailsPage({onClose, title, children, topBackdropStart, stickyTitle = false, propStyles = {}}) {
    const classes = getStyles(detailsPageStyles)

    // при событии скролла меняю стили заголовка
    useEffect(() => {
        const detailsPaper = document.getElementById("details-page-paper")
        const title = document.getElementById("details-page-title")
        const titleText = document.getElementById("details-page-title-text")

        const handleScroll = () => {
            if (stickyTitle) {
                if (detailsPaper.scrollTop > 0) {
                    title.style.zIndex = "1002"
                    title.style.paddingTop = "0"
                    title.style.boxShadow = "0px 5px 5px -5px rgba(34, 60, 80, 0.6)"
                    titleText.style.fontSize = "24px"
                } else {
                    title.style.zIndex = "1000"
                    title.style.paddingTop = "24px"
                    titleText.style.fontSize = "32px"
                    title.style.boxShadow = "none"
                }
            }
        }

        detailsPaper.addEventListener("scroll", handleScroll)

        return () => detailsPaper.removeEventListener("scroll", handleScroll)
    }, [])

    return (
        <Box
            sx={{
                ...classes.backdrop,
                top: topBackdropStart || "var(--appbar-height)",
                ...propStyles
            }}
            id="details-page"
        >
            <Paper type={"container"} sx={classes.paper} id="details-page-paper">
                <Box
                    mb={4}
                    className={"jst-sb aln-start"}
                    sx={stickyTitle ? {...classes.stickyTitleStyles} : {paddingTop: "24px"}}
                    id="details-page-title"
                >
                    <Typography variant={"h1"} id="details-page-title-text">
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <img alt="" src={cross} />
                    </IconButton>
                </Box>
                {children}
            </Paper>
        </Box>
    )
}

export default observer(DetailsPage)
