import React from "react"
import {FileUploader} from "react-drag-drop-files"

function FileInput({file, setFile, fileTypes, classes, multiple, ...otherProps}) {
    const handleChange = (file) => {
        setFile(file)
    }
    return (
        <FileUploader
            multiple={multiple}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes={classes}
            {...otherProps}
        />
    )
}

export default FileInput