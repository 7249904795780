import { observer } from 'mobx-react'
import InputField from '../InputField/InputField'
import CustomAccordion from './utils/CustomAccordion'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import checkIcon from '../../../assets/images/common/check.svg'
import cross from '../../../assets/images/common/cross_dark.svg'
import IconButton from '@mui/material/IconButton'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { isAdmin, isAdminLabelAA, isDealer, isOperator } from '../../../services/AccountService'
import DataUnit from '../../../components/customElements/DataUnit/DataUnit'
import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'

function SetEquipmentId({ store, updateHistory, setUpdateHistory }) {
    const intl = gState['intl']
    const { setNewEquipment, data, newEquipment, changeEquipment, isEdit, comment, setComment } = store
    const { equipmentId, dataCheckingStatus, status, reportStatus } = data

    const onEditEquipmentId = () => {
        if (status === 6 && isDealer()) {
            switch (true) {
                case dataCheckingStatus === 0:
                    return isEdit
                case dataCheckingStatus === 2:
                    return false
                case dataCheckingStatus === 3:
                    return isEdit
                default:
            }
        } else {
            return false
        }
    }
    const [editEquipmentId, setEditEquipmentId] = useState(!onEditEquipmentId())
    
    return (
        <CustomAccordion
            onEdit={onEditEquipmentId() ? () => setEditEquipmentId(!editEquipmentId) : false}
            summary={intl.formatMessage({ id: 'Id оборудования' })}
        >
            {editEquipmentId ? (<>
                <Box className={ (isAdminLabelAA() && [15, 16].includes(status) && ![5, 100, 101].includes(reportStatus))? null:'aln-center jst-sb'} sx={{ paddingTop: '20px' }}>
                    <InputField
                        value={newEquipment}
                        onChange={
                            (isAdmin() &&
                                equipmentId &&
                                status !== 16 &&
                                status !== 15 &&
                                status !== 12 &&
                                status !== 14) ||
                            isEdit
                                ? (event) => setNewEquipment(event.target.value)
                                : isAdminLabelAA() && [15, 16].includes(status) && ![5, 100, 101].includes(reportStatus)
                                ? (event) => setNewEquipment(event.target.value)
                                : null
                        }
                        label={intl.formatMessage({ id: 'Id оборудования' })}
                        readOnly={isOperator() || !equipmentId}
                        fullWidth={(isAdminLabelAA() && [15, 16].includes(status) && ![5, 100, 101].includes(reportStatus))}
                    />
                    {(isAdmin()  || (isEdit && status === 6)) && !!equipmentId && newEquipment !== equipmentId && !(isAdminLabelAA() && [15, 16].includes(status) && ![5, 100, 101].includes(reportStatus)) && (
                        <Box className={'aln-center'}>
                            <IconButton
                                onClick={() => {
                                    changeEquipment(() => setUpdateHistory(!updateHistory))
                                }}
                            >
                                <img alt={''} src={checkIcon} />
                            </IconButton>
                            <IconButton className={'ml-8'} onClick={() => setNewEquipment(equipmentId)}>
                                <img alt={''} src={cross} width={20} height={20} />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                {(isAdminLabelAA() && [15, 16].includes(status) && ![5, 100, 101].includes(reportStatus) && !!equipmentId && newEquipment !== equipmentId ?<Box>
                        <Box mt={2} mb={1}>
                            <InputField
                                error={!comment}
                                multiline
                                value={comment}
                                onChange={setComment}
                                rows={4}
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                            />
                        </Box>
                    
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                            onClick={()=>changeEquipment(() => setUpdateHistory(!updateHistory))}
                            disabled={(!!equipmentId && newEquipment !== equipmentId)? !comment : true}
                        >
                            <FormattedMessage id={'Принять изменения'} />
                        </Button>
                    </Box>
                    : null)
        }
                                </>
            ) : (
                <DataUnit value={equipmentId ?? newEquipment} />
            )}
        </CustomAccordion>
    )
}

export default observer(SetEquipmentId)
