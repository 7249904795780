import {useIntl} from "react-intl"
import CustomAccordion from "src/components/customElements/RequestDataAccordions/utils/CustomAccordion"
import Box from "@mui/material/Box"
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import DataUnit from "src/components/customElements/DataUnit/DataUnit"
import StoragesStore from "src/views/Reports/stores/StoragesStore"
import SelectedReportStore from "src/views/Reports/stores/SelectedReportStore"
import {CircularProgress} from "@mui/material"

const Content = ({order}) => {
    const [selectedReportStore] = useState(() => new SelectedReportStore({id: order.reportId}))
    useEffect(() => {
        selectedReportStore.load()
    }, [])
    const {data, isReady} = selectedReportStore
    const intl = useIntl()
    const {getReportStorageData, storagesName, shelvName, boxName} = StoragesStore
    return (
        <CustomAccordion summary={intl.formatMessage({id: "Адрес хранения"})} defaultOpenState={false}>
            {isReady ? (
                <>
                    <DataUnit name={storagesName} value={getReportStorageData(data.storage, 1)} />
                    <Box mt={2}>
                        <DataUnit name={shelvName} value={getReportStorageData(data.storage, 2)} />
                    </Box>
                    <Box mt={2}>
                        <DataUnit name={boxName} value={getReportStorageData(data.storage, 3)} />
                    </Box>
                </>
            ) : (
                <Box className={"jst-center"}>
                    <CircularProgress />
                </Box>
            )}
        </CustomAccordion>
    )
}

export default observer(Content)
