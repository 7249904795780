import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { openModalFn } from '../../../utils/controllers/ModalController'
import { css } from '@emotion/css'
import Box from '@mui/material/Box'
import crossLight from '../../../assets/images/common/cross_light.svg'
import commonStyles from '../../../styles/commonStyles'
import { getStyles } from '../../../utils/utils/commonUtils'

const UploadedPhoto = ({ collection, i, photo, isForDelete, store, name }) => {
    const classes = getStyles(commonStyles)

    const [visible, setVisible] = useState(false)
    const [restoreVisible, setRestoreVisible] = useState(false)

    return (
        <Box
            onClick={() => {
                openModalFn['photo-gallery']({ collection, initialIndex: i, name })
            }}
            onMouseEnter={() => {
                if (!photo.isRemoved) {
                    return setVisible(true)
                }

                return setRestoreVisible(true)
            }}
            onMouseLeave={() => {
                if (!photo.isRemoved) {
                    return setVisible(false)
                }

                return setRestoreVisible(false)
            }}
            sx={{ cursor: 'pointer', position: 'relative' }}
        >
            {isForDelete && !photo.isRemoved && (
                <Box
                    sx={{ ...classes.photoIcon, display: visible ? 'block' : 'none' }}
                    onClick={(e) => {
                        e.stopPropagation()
                       
                      
                      
                        openModalFn['transaction-confirmation']({
                            text1: `Подтвердить удаление фотографии из заявки?`,

                            action: () => store.deleteImage(photo.id),
                            processText: `Выполняется удаление фотографии из заявки`,
                        })
                    }}
                >
                    <img alt={'remove photo'} src={crossLight} width={20} height={20} />
                </Box>
            )}
            {store.isRestorable() && photo.isRemoved && (
                <Box
                    sx={{
                        ...classes.photoIcon,
                        display: restoreVisible ? 'block' : 'none',
                        transform: 'rotate(45deg)',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()

                        store.restoreImage(photo.id)
                    }}
                >
                    <img alt={'restore photo'} src={crossLight} width={20} height={20} />
                </Box>
            )}
            <img
                alt={''}
                src={photo.url}
                width={64}
                height={64}
                className={css({ borderRadius: '8px', border: photo.isRemoved ? '1px solid red' : '1px solid green' })}
            />
        </Box>
    )
}

export default observer(UploadedPhoto)
