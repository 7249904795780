import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { inject, observer } from 'mobx-react'
import RequestsTable from 'src/components/RequestsTable/RequestsTable'
import newRequest from 'src/assets/images/orderStatuses/newWhite.svg'
import DatesStore from 'src/stores/DatesStore'
import { getPeriodDaysString } from 'src/helpers/getReportPeriodDate'
import ReportOrderDetailsDrawer from 'src/views/Reports/components/ReportOrderDetailsDrawer/ReportOrderDetailsDrawer'
import RequestsFilterStore from 'src/views/RequestsManagement/stores/RequestsFilterStore'
import NewRequestFilters from 'src/views/RequestsManagement/components/NewRequestFilters/NewRequestFilters'
import { useIntl } from 'react-intl'
import ReportInfoDrawer from 'src/views/Reports/components/ReportInfoDrawer/ReportInfoDrawer'
import SelectedReportStore from 'src/views/Reports/stores/SelectedReportStore'
import ProgressBackdrop from 'src/components/ProgressBackdrop/ProgressBackdrop'
import DetailsPage from 'src/components/DetailsPage/DetailsPage'
import TransactionConfirmation from '../../../components/TransactionConfirmation/TransactionConfirmation'
import { isDealer } from '../../../services/AccountService'

function ReportDetails({
    selectedReportId,
    onClose,
    scrollIsAlreadyLocked,
    topBackdropStart,
    isReportDetailsOpen,
    updateRequestManagmentTable,
}) {
    const intl = useIntl()
    const [selectedReportStore] = useState(() => new SelectedReportStore({ id: selectedReportId, onClose }))

    const [filterStore] = useState(new RequestsFilterStore())
    const { data, openReportDrawer, ordersStore, reportOrderDetailsStore } = selectedReportStore
    const { setCurrentOpenedRowIndex, currentOpenedRowIndex, openReportDetails } = reportOrderDetailsStore
    const { getMonthName } = new DatesStore()
    const { id, month, year, created, interval, creationTime } = data || {}

    useEffect(() => {
        selectedReportStore.reLoad()
        ordersStore.reLoad({
            page: 0,
            size: ordersStore.pageSize,
            reportId: selectedReportId,
        })
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    const reportTitle = useMemo(
        () =>
            !interval
                ? intl.formatMessage(
                      {
                          id: 'Отчет №{id} за {month} {year} года',
                          defaultMessage: 'Отчет №{id} за {month} {year} года',
                      },
                      {
                          id: selectedReportId,
                          month: getMonthName(month),
                          year: year,
                      }
                  )
                : intl.formatMessage(
                      {
                          id: 'Отчет №{id} за {days} {month} {year} года',
                          defaultMessage: 'Отчет №{id} за {days} {month} {year} года',
                      },
                      {
                          id,
                          month: getMonthName(month, true),
                          year: year,
                          day: created,
                          days: getPeriodDaysString(interval, month, year),
                      }
                  ),
        [data]
    )

    return selectedReportStore.isReady ? (
        //prop stickyTitle делает заголовок окна фиксированным при скролле
        <DetailsPage onClose={onClose} title={reportTitle} topBackdropStart={topBackdropStart} stickyTitle={true}>
            <Box mb={2}>
                <NewRequestFilters
                    filterRowStyles={{ background: '#fff' }}
                    totalCollectionLength={ordersStore.total}
                    filtersStore={filterStore}
                    defaultParams={{ reportId: id }}
                    reportMonth={month}
                    reportRequests={true}
                    ordersStore={ordersStore}
                    actionButtonProps={{
                        name: intl.formatMessage({ id: 'Детали отчета' }),
                        onClick: () => openReportDrawer(isDealer()),
                        icon: newRequest,
                    }}
                    scrollIsAlreadyLocked={scrollIsAlreadyLocked}
                />
            </Box>
            <RequestsTable
                store={ordersStore}
                filterStore={filterStore}
                onRowClick={reportOrderDetailsStore.openOrderDetails}
                rowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                reportRequests={true}
                doublePagination={true}
                setCurrentOpenedRowIndex={setCurrentOpenedRowIndex}
                currentOpenedRowIndex={currentOpenedRowIndex}
                hideUninformative={true}
                isReportDetailsOpen={isReportDetailsOpen}
            />
            <ReportOrderDetailsDrawer
                store={reportOrderDetailsStore}
                reportStatus={selectedReportStore.data.status}
                ordersStore={ordersStore}
                openReportDetails={openReportDetails}
                onTop={true}
                currentOpenedRowIndex={reportOrderDetailsStore.currentOpenedRowIndex}
                onRowClick={reportOrderDetailsStore.openOrderDetails}
                // isReportDetailsOpen={isReportDetailsOpen}
                disableLinks={true} // запретить открытие отчета по ссылке в истории заявок
            />
            <ReportInfoDrawer
                onTop={true}
                store={selectedReportStore}
                updateRequestManagmentTable={updateRequestManagmentTable}
            />
            <TransactionConfirmation />
        </DetailsPage>
    ) : (
        <ProgressBackdrop local={true} isOpen={true} />
    )
}

export default observer(ReportDetails)
