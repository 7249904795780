import React, {useMemo} from "react"
import {observer} from "mobx-react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import PhotoUploadForm from "./PhotoUploadForm"
import Stack from "@mui/material/Stack"

function PhotosUpload({store, title, reportOrder}) {
    const {data, newStatus, photoSettings: {photoPermission}} = reportOrder ? store.addPhotosStore : store
    const collectionName = useMemo(() => {
        return newStatus === 7 ? "impossible" : newStatus === 11 ? "uncompleted" : "install"
    }, [newStatus])

    return (
        <Box my={2}>
            {title ? <Typography variant={"body1"}>
                {title}
            </Typography> : null
            }
            <Stack mt={2} direction={"column"} spacing={1.5}>
                {(reportOrder
                        ? (photoPermission.totalLimit - store.data.images.clarify.length - data.images[collectionName].length) > 0 && photoPermission.maxLimitPerLoad - data.images[collectionName].length > 0
                            ? [...data.images[collectionName], null]
                            : data.images[collectionName]
                        : (photoPermission.maxLimitPerLoad - data.images[collectionName].length) > 0
                            ? [...data.images[collectionName], null]
                            : data.images[collectionName]
                ).map((image, i) => {
                    return (
                        <PhotoUploadForm
                            key={i}
                            api={reportOrder ? store.addPhotosStore : store}
                            collectionName={collectionName}
                            image={image}
                            reportCollectionLength={reportOrder ? store.data.images.clarify.length : undefined}
                            reportOrder={reportOrder}
                        />)
                })}
            </Stack>
        </Box>
    )
}

export default observer(PhotosUpload)
