import PagedCollectionStore from '../../../utils/stores/PagedCollectionStore'
import { getReports } from '../../../services/ApiService'
import { computed, makeObservable, observable } from 'mobx'
import { getComp } from '../../../utils/utils/DI'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'
import moment from 'moment'
import ReportsFilterStore from 'src/views/Reports/stores/ReportsFilterStore'
import { isAdmin, isDealer } from '../../../services/AccountService'
import ReportStoragesStore from 'src/views/Reports/stores/ReportStoragesStore'

class ReportsStore extends PagedCollectionStore {
    constructor() {
        super({ action: getReports, lazyLoad: true })
        const { reportStatuses } = getComp('StatusesStore')
        this.reportStatuses = reportStatuses
        this.loadParams = {
            ...this.loadParams,
            size: isAdmin() || isDealer() ? (this.pageSize = 100) : this.pageSize,
            page: this.pageNumber,
        }
        this.isCreateReportOpen = false
        this.isAddRequestsToReportOpen = false
        this.reportsFilterStore = new ReportsFilterStore()
        this.isReportDetailsOpen = false
        this.selectedReportId = null
        this.created = false
        this.reportStorageStore = new ReportStoragesStore()

        this.allReports = 0
        makeObservable(this, {
            selectedReportId: observable,
            isCreateReportOpen: observable,
            isReportDetailsOpen: observable,
            reportStatuses: observable,
            allReports: observable,
            isAddRequestsToReportOpen: observable,
            reportCollection: computed,
            created: observable,
        })
    }

    get reportCollection() {
        return this.collection
    }

    setCreated = (value) => {
        this.created = value
    }

    // если данные уже загружены и не утановлено никаких фильтров то сохраняю в переменную количество всех отчетов
    getAllReportsNumber = () => {
        if (this.data && this.reportsFilterStore.selectedFilters.length === 0) {
            this.allReports = this.data.totalSize
        }
    }

    getMonth = (date) => {
        return moment(date).utc().format('MM YYYY').split(' ')[0]
    }

    handlePageChange = (event, page) => {
        if (page >= 0) {
            this.loadPageAction(page)
        }
    }

    reLoad = (params = this.loadParams, disableLoader) => {
        if (!disableLoader) {
            openModalFn['progress-backdrop']()
        }
        return super.reLoad(params).finally(() => {
            closeModalFn['progress-backdrop']()
        })
    }

    selectReport = (params) => {
        if (params) {
            this.selectedReportId = (params.data ? params.data : params).id
        } else {
            this.selectedReportId = null
        }
    }

    openReportDetails = (params) => {
        this.isReportDetailsOpen = true
        this.created = false
        this.selectReport(params)
    }

    closeReportDetails = () => {
        this.isReportDetailsOpen = false
        this.selectReport()
    }

    openAddReportDialog = () => {
        this.isCreateReportOpen = true
    }

    openAddRequestsToReport = () => {
        this.isAddRequestsToReportOpen = true
    }

    closeAddRequestsToReport = () => {
        this.isAddRequestsToReportOpen = false
    }

    closeCreateReport = () => {
        this.loadParams.page = 0
        this.isCreateReportOpen = false
    }
}

export default ReportsStore
