import React, { useEffect, useMemo, useState } from 'react'
import CustomAccordion from './utils/CustomAccordion'
import Box from '@mui/material/Box'
import InputField from '../InputField/InputField'
import { isAdmin, isAdminLabelAA, isDealer } from '../../../services/AccountService'
import IconButton from '@mui/material/IconButton'
import checkIcon from '../../../assets/images/common/check.svg'
import cross from '../../../assets/images/common/cross_dark.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment/moment'
import Tooltip from '@mui/material/Tooltip'
import tooltipIcon from '../../../assets/images/common/tooltipIcon.svg'
import { checkEditCreationDate } from 'src/helpers/ordersUtiles'
import { observer } from 'mobx-react-lite'
import { Button } from '@mui/material'

const isLabelAAAndStatusValid = (requestStatus, reportStatus) => {
    return isAdminLabelAA() && [15, 16].includes(requestStatus) && ![5, 100, 101].includes(reportStatus)
}

const ChangeInstallationDate = ({
    isDefaultOpen = false,
    inputData,
    requestId,
    requestStatus,
    changeInstallationDate,
    reloadAction,
    reportOrder,
    setUpdateHistory,
    updateHistory,
    reportStatus,
    store,
}) => {
    const formatDate = (date) => {
        return moment(date).startOf('day').format('DD-MM-YYYY')
    }
    const splitDate = (date) => {
        return moment(date).format('DD-MM')
    }
    const intl = useIntl()
    const [visible, setIsVisible] = useState(false)
    const [value, setValue] = useState(() => {
        return formatDate(inputData)
    })

    useEffect(() => {
        setValue(formatDate(inputData))
    }, [inputData])

    const isEditable = useMemo(() => {
        let result = false
        if (isDealer()) {
            result = requestStatus === 6 || requestStatus === 14
        }
        if (isAdmin() && !reportOrder) {
            result = requestStatus === 6
        }
        if (isLabelAAAndStatusValid(requestStatus, reportStatus)) {
            result = true
        }
        return result
    }, [requestStatus])

    const isNoFuture = useMemo(() => {
        return moment().diff(moment(value, 'DD-MM-YYYY')) > 0
    }, [value])

    const validationOnReportOrder = useMemo(() => {
        return {
            forTrue: reportOrder
                ? checkEditCreationDate(
                      moment(isAdmin() ? value.substring(0, 11) : value, 'DD-MM-YYYY')
                          .utc(false)
                          .format(),
                      inputData
                  )
                : true,
            forError: reportOrder
                ? !checkEditCreationDate(
                      moment(isAdmin() ? value.substring(0, 11) : value, 'DD-MM-YYYY')
                          .utc(false)
                          .format(),
                      inputData
                  )
                : false,
        }
    }, [reportOrder, value, inputData])

    const errorMessage = useMemo(() => {
        return reportOrder ? ' ' : intl.formatMessage({ id: 'Введите корректную дату' })
    }, [reportOrder, intl])

    return (
        // <CustomAccordion summary={intl.formatMessage({ id: 'Дата установки' })} defaultOpenState={isDefaultOpen}>
        <Box className={isLabelAAAndStatusValid(requestStatus, reportStatus) ? null : 'aln-center jst-sb'} mt={1.5}>
            <Box
                className={isLabelAAAndStatusValid(requestStatus, reportStatus) ? null : 'aln-center'}
                sx={{ gap: '8px' }}
            >
                {reportOrder && (!isNoFuture || validationOnReportOrder.forError) && (
                    <Tooltip
                        title={intl.formatMessage({
                            id: 'Дату установки можно отредактировать только в рамках отчётного периода текущего отчёта',
                        })}
                    >
                        <img src={tooltipIcon} alt={''} />
                    </Tooltip>
                )}

                <InputField
                    value={value}
                    onChange={(e) => {
                        if (isEditable) {
                            setValue(e.target.value)
                            setIsVisible(true)
                        }
                    }}
                    label={intl.formatMessage({ id: 'Дата установки' })}
                    mask={false ? '99-99-9999, 00:00(UTC+3)' : '99-99-9999'}
                    placeholder={'DD-MM-YYYY'}
                    error={(value.length < 10 || validationOnReportOrder.forError || !isNoFuture) && errorMessage}
                    fullWidth={true}
                />
            </Box>
            {isLabelAAAndStatusValid(requestStatus, reportStatus) && visible ? (
                <Box mt={2} mb={1}>
                    <InputField
                        error={!store.comment}
                        multiline
                        value={store.comment}
                        onChange={store.setComment}
                        rows={4}
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                    />
                    <Box mt={2}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                            onClick={() => {
                                changeInstallationDate &&
                                    changeInstallationDate(requestId, store.comment, value, reloadAction).then(() => {
                                        setUpdateHistory(!updateHistory)
                                    })
                                setIsVisible(false)
                                store.comment = ''
                            }}
                            disabled={!store.comment}
                        >
                            <FormattedMessage id={'Принять изменения'} />
                        </Button>
                    </Box>
                </Box>
            ) : null}

            {isLabelAAAndStatusValid(requestStatus, reportStatus) ? null : (
                <Box className={'aln-center'}>
                    {isEditable && visible && validationOnReportOrder.forTrue && isNoFuture && (
                        <>
                            <IconButton
                                onClick={() => {
                                    changeInstallationDate &&
                                        changeInstallationDate(
                                            requestId,
                                            store?.comment || '',
                                            value,
                                            reloadAction
                                        ).then(() => {
                                            setUpdateHistory(!updateHistory)
                                        })
                                    setIsVisible(false)
                                }}
                            >
                                <img alt={''} src={checkIcon} />
                            </IconButton>
                            <IconButton
                                className={'ml-8'}
                                onClick={() => {
                                    setIsVisible(false)
                                    setValue(() =>
                                        inputData
                                            ? moment(inputData).startOf('day').format('DD-MM-YYYY')
                                            : moment().startOf('day').format('DD-MM-YYYY')
                                    )
                                }}
                            >
                                <img alt={''} src={cross} width={20} height={20} />
                            </IconButton>
                        </>
                    )}
                </Box>
            )}
        </Box>
        // </CustomAccordion>
    )
}

export default observer(ChangeInstallationDate)

