import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function DataUnit({ name = '', value, icon }) {
    return (
        <Box>
            <Typography variant={'body2'} color={'grey.500'} sx={{ lineHeight: 1 }}>
                {name}
            </Typography>

            <Box mt={0.5} className={'aln-center'}>
                {!!icon && <img alt={''} src={icon} className={'mr-8'} style={{ width: '20px', height: '20px' }} />}
                <Typography sx={value === 'Обслуживание невозможно' ? { width: '110px' } : null} variant={'subtitle1'}>
                    {value || '-'}
                </Typography>
            </Box>
        </Box>
    )
}

export default observer(DataUnit)
