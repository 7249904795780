import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import Typography from '@mui/material/Typography'
import { getComp } from '../../utils/utils/DI'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import CustomTable from '../../utils/components/CustomTable/CustomTable'
import {
    isAdmin,
    isAdministration,
    isDealer,
    isDealerOperator,
    isLooker,
    isOperator,
} from '../../services/AccountService'
import { gState } from '../../utils/controllers/GlobalStateController'
import arrowRight from '../../assets/images/common/arrowRight.svg'
import IconButton from '@mui/material/IconButton'
import { Checkbox } from '@mui/material'
import onCall from '../../assets/images/operatorOrderStatuses/on_call.svg'
import assignedToMe from '../../assets/images/operatorOrderStatuses/assigned_to_me.svg'
import copyIcon from '../../assets/images/common/copy.svg'
import StackActions from '../../utils/components/StackActions/StackActions'
import StackActionStore from '../../utils/components/StackActions/StackActionStore'
import TableSortIcon from '../customElements/TableSortIcon/TableSortIcon'
import { convertId, formatAddress, formatName } from 'src/helpers/ordersUtiles'
import { debounce, debounceReport } from '../../utils/debounce/debounce'
import switchPriorityColor from '../../helpers/switchPriorityColor'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import { useTheme } from '@emotion/react'
import { checkMobileDevice } from '../../utils/utils/commonUtils'
import { getIconFinancialSource, getTitleFinancialSource } from '../../helpers/switchFinancialSource'
import { useLocation } from 'react-router'
import deleteRequest from '../../assets/images/reportStatuses/delete.svg'
import isExpiredAssign from 'src/assets/images/isExpiredAssign.svg'
import isSuspicious from 'src/assets/images/isSuspicious.svg'

function RequestsTable({
    containerStyle,
    store,
    reportRequests,
    onRowClick,
    selectedTab,
    filterStore,
    doublePagination,
    selectOrdersStore = {},
    addReport,
    rowsPerPageOptions,
    isAgreement,
    tableSettingsStore,
    handleSettings,
    currentOpenedRowIndex,
    setCurrentOpenedRowIndex,
    width,
    hideUninformative,
    isReportDetailsOpen,
    isServiceTable,
    operatorStore,
    isReportCreate,
    filterFromReports,
    frontendPagination,
    remuveRequest,
    reportStatus,
    ...otherProps
}) {
    const intl = gState['intl']
    const theme = useTheme()
    const {
        firstItem,
        isOrdersSelected,
        selectedOrders,
        selectOrder,
        selectOrders,
        checkShowSelect,
        isTableHeadCheckDisabled,
        checkIsOrderCanBeSelected,
        checkFinancialSource,
        checkSelectDisabled,
        checkOrderSelected,
        clearSelectOrders,
        filterSelectedOrders,
    } = selectOrdersStore
    const statusStore = getComp('StatusesStore')
    const dealersStore = getComp('DealersStore')
    const categoriesStore = getComp('CategoriesStore')
    const [stackActionStore] = useState(new StackActionStore({ store: selectOrdersStore }))
    const {
        collection,
        pageSize,
        pageNumber,
        reversPage,
        maintenanceReversePage,
        sort,
        checkIsRevers,
        tableCollection,
        lazyLoad,
    } = store
    const setting = tableSettingsStore ? tableSettingsStore.checkedRequestColumns : null
    const location = useLocation()
    const currentPath = location.pathname
    const [redDelete, setRedDelete] = useState([])

    // useEffect(() => {
    //     if (selectedOrders?.size > 0 && !!filterFromReports) {
    //         setRedDelete([...selectedOrders])
    //     } else {
    //         setRedDelete([])
    //     }
    // }, [selectedOrders?.size])

    useEffect(() => {
        if (tableSettingsStore) tableSettingsStore.actualizeColumns()
    }, [])

    const firstItemColl = collection.find((item) => (isDealer() ? item.status : [1, 2, 4].includes(item.status))) // позволяет выбрать все по статусу первой заявки

    let collectionPage = collection.filter((item) => item.status === (firstItem?.status || firstItemColl?.status))

    let collectionReport = collection.filter((item) => {
        if (checkIsOrderCanBeSelected && checkFinancialSource) {
            return checkIsOrderCanBeSelected(item.installationDate) && checkFinancialSource(item.financialSourceId)
        }
    })

    useEffect(() => {
        // нужен для корректной работы логики чекбоксов при изменения размера таблицы
        if (!isReportCreate) {
            filterSelectedOrders && filterSelectedOrders(addReport ? collectionReport : collectionPage)
        }
    }, [collection])

    const markText = useCallback((string, query) => {
        if (!query || !string) {
            return string
        }

        if (string === query) {
            return (
                <span>
                    <span style={{ background: 'yellow' }}>{query}</span>
                </span>
            )
        }
        const noSpecialChars = query.replace(/[^а-яА-Яa-zA-Z0-9]/g, '')
        const parts = string.split(new RegExp(`(${noSpecialChars})`, 'gi'))
        return (
            <span>
                {parts.map((part, i) => {
                    return (
                        <span
                            key={part + i}
                            style={part.toLowerCase() === noSpecialChars.toLowerCase() ? { background: 'yellow' } : {}}
                        >
                            {part}
                        </span>
                    )
                })}
            </span>
        )
    }, [])

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)

        if (match) {
            return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}-${match[5]}`
        }

        return null
    }

    const getColumns = useMemo(() => {
        let columns = [
            {
                propName: 'isExpiredAssign',
                field: 'isExpiredAssign',
                // headerName: intl.formatMessage({id: ""}),
                renderCell: (params) => {
                    return (
                        <Tooltip title={ params.isSuspicious && isAdmin()? 'Заявка отмечена как подозрительная' : 'Заявка не выполнена в срок'} placement={'top-start'}>
                            <Box>
                                {params.isSuspicious && isAdmin()? <img alt={''} src={isSuspicious} /> : (params.isExpiredAssign || params.isExpiredInstall) && (
                                    <img alt={''} src={isExpiredAssign} />
                                )}
                            </Box>
                        </Tooltip>
                    )
                },
            },
            {
                propName: 'checkBox',
                headerElement: '',

                minWidth: checkMobileDevice() && isDealer() ? '40px' : '51px',
                cellWidth: checkMobileDevice() && isDealer() ? '40px' : '51px',
                cellMinWidth: checkMobileDevice() && isDealer() ? '40px' : '56px',
                renderCell: (params) => {
                    const showCheckbox = (!!checkShowSelect && checkShowSelect(params)) || !!filterFromReports
                    const disabled = addReport
                        ? selectedOrders?.size > 0 &&
                          (!checkIsOrderCanBeSelected(params.installationDate)
                              ? true
                              : !checkFinancialSource(params.financialSourceId))
                        : checkSelectDisabled && checkSelectDisabled(params)
                    const checked = checkOrderSelected && checkOrderSelected(params)

                    // const ref = useRef(null)

                    const onChange = () => {
                        selectOrder(params)
                    }

                    // при изменении чекбокса изменяются стили строки. через реф получаю доступ к предку.
                    // useEffect(() => {
                    //     if (ref.current && ref.current.parentElement.parentElement.parentElement) {
                    //         ref.current.parentElement.parentElement.parentElement.style = checked
                    //             ? 'background: rgba(93, 175, 239, 0.20)'
                    //             : ''
                    //     }
                    // }, [checked])

                    const onClick = (e) => e.stopPropagation()
                    return showCheckbox ? (
                        <Checkbox
                            // ref={ref}
                            checked={checked}
                            onChange={onChange}
                            onClick={onClick}
                            disabled={disabled}
                            sx={{ [theme.breakpoints.down('sm')]: { padding: '0 0 0 9px' } }}
                        />
                    ) : (
                        <Box sx={{ height: '40.5px', width: '40.5px' }} />
                    )
                },
            },
            // {
            //     propName: 'serviceCheckbox',
            //     headerElement: (
            //         <Checkbox
            //             checked={isOrdersSelected}
            //             onChange={() => selectOrders(collection)}
            //             disabled={isTableHeadCheckDisabled}
            //             onClick={(e) => e.stopPropagation()}
            //             sx={{ [theme.breakpoints.down('sm')]: { padding: '0 0 0 9px' } }}
            //         />
            //     ),
            //     renderCell: (params) => {
            //         return <Checkbox sx={{ [theme.breakpoints.down('sm')]: { padding: '0 0 0 9px' } }} />
            //     },
            // },
            {
                propName: 'agreementCheckingStatus',
                field: 'agreementCheckingStatus',
                // headerName: intl.formatMessage({id: ""}),
                renderCell: (params) => {
                    const { getAgreementStatusData } = statusStore
                    const data = getAgreementStatusData(params.agreementCheckingStatus)
                    return (
                        <Tooltip title={data?.name} placement={'top-start'}>
                            <img alt={''} src={data?.imageSrc} />
                        </Tooltip>
                    )
                },
            },
            {
                propName: 'status',
                field: 'status',
                headerName: intl.formatMessage({ id: 'Статус' }),
                minWidth: checkMobileDevice() && isDealer() ? '40px' : '51px',
                cellWidth: checkMobileDevice() && isDealer() ? '40px' : '51px',
                cellMinWidth: checkMobileDevice() && isDealer() ? '40px' : '56px',
                renderCell: (params) => {
                    const {
                        getStatusName,
                        getStatusImage,
                        getReportOrderStatusName,
                        getReportOrderStatusImage,
                        getServiceOrderStatusName,
                        getServiceOrderStatusImage,
                    } = statusStore
                    let name = null
                    let icon = null

                    if (isServiceTable || (isOperator() && selectedTab > 1)) {
                        name = getServiceOrderStatusName(params.status)
                        icon = getServiceOrderStatusImage(params.status)
                    } else {
                        name = reportRequests ? getReportOrderStatusName(params.status) : getStatusName(params.status)
                        icon = reportRequests ? getReportOrderStatusImage(params.status) : getStatusImage(params.status)
                    }
                    return (
                        <Tooltip title={name} placement={'top-start'}>
                            <img alt={''} src={icon} />
                        </Tooltip>
                    )
                },
            },
            {
                propName: 'financial',
                field: 'financial',
                headerName: intl.formatMessage({ id: 'Оплата' }),
                handleClick:
                    (isAdmin() || isOperator() || isDealer() || isAdministration()) && !reportRequests
                        ? () => {
                              reversPage('financial-source')
                          }
                        : null,
                icon:
                    (isAdmin() || isOperator() || isDealer() || isDealerOperator() || isAdministration()) &&
                    !reportRequests ? (
                        <TableSortIcon isReverse={checkIsRevers('financial-source')} />
                    ) : null,

                renderCell: (params) => {
                    return (
                        <>
                            {getIconFinancialSource(params.financialSourceId) ? (
                                <Tooltip
                                    title={
                                        statusStore?.financialSourcesName[params.financialSourceId - 1] ||
                                        getTitleFinancialSource(params.financialSourceId)
                                    }
                                    placement={'top-start'}
                                >
                                    <img
                                        alt={
                                            statusStore?.financialSourcesName[params.financialSourceId - 1] ||
                                            getTitleFinancialSource(params.financialSourceId)
                                        }
                                        src={getIconFinancialSource(params.financialSourceId)}
                                    />
                                </Tooltip>
                            ) : null}
                            {params.financialSourceId === 4 ? null : (
                                <Typography ml={2} sx={{ fontSize: '14px', fontWeight: '500', width: '60%' }}>
                                    {statusStore?.financialSourcesName[params.financialSourceId - 1] ||
                                        getTitleFinancialSource(params.financialSourceId)}
                                </Typography>
                            )}
                        </>
                    )
                },
            },
            {
                propName: 'priority',
                field: 'priority',
                headerName: intl.formatMessage({ id: 'Приоритет' }),
                handleClick:
                    (isAdmin() || isOperator() || isDealer() || isAdministration()) && !reportRequests
                        ? () => {
                              reversPage('priority')
                          }
                        : null,
                icon:
                    (isAdmin() || isOperator() || isDealer() || isDealerOperator() || isAdministration()) &&
                    !reportRequests ? (
                        <TableSortIcon isReverse={checkIsRevers('priority')} />
                    ) : null,
                renderCell: (params) => {
                    return params.priority || params.priority === 0 ? (
                        <Typography
                            sx={{
                                background: switchPriorityColor(params.priority),
                                borderRadius: '50%',
                                color: 'white',
                                width: 22,
                                height: 22,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 500,
                                paddingTop: '1px',
                            }}
                        >
                            {params.priority}
                        </Typography>
                    ) : null
                },
            },
            {
                propName: 'assignDealer',
                headerName: intl.formatMessage({ id: 'Дилер' }),
                minWidth: '220px',
                renderCell: (params) => {
                    return (
                        <Box className={'aln-start'} width={'100%'}>
                            {/* {!!params.dealer && <img alt={""} src={avatarIcon} width={20} height={20} />} */}
                            <Box ml={1} sx={{ padding: '0 0 0 50px' }}>
                                {!!params.dealer && (
                                    <Typography variant={'body2'}>
                                        {dealersStore.getDealer(params.dealer)?.displayName}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                },
                headerStart: true,
                justifyContent: 'flex-start',
                padding: '0 0 0 54px',
            },
            {
                propName: 'sender',
                headerName: intl.formatMessage({ id: 'Источник' }),
                minWidth: '100px',
                renderCellValue: (params) => params.sender,
                justifyContent: 'flex-start',
                headerStart: true,
                fontWeight: 500,
            },
            {
                propName: 'id',
                field: 'id',
                minWidth: '130px',
                headerName: intl.formatMessage({ id: 'Номер заявки' }),
                handleClick: () =>
                    isOperator() && selectedTab === 3 ? maintenanceReversePage('id') : reversPage('id'),
                icon: <TableSortIcon isReverse={checkIsRevers('id')} />,
                renderCellValue: (params) => {
                    return (
                        <Box
                            className={'fx-nowrap fx-center'}
                            sx={{ gap: '4px', [theme.breakpoints.down('sm')]: { fontSize: '16px' } }}
                        >
                            {navigator.clipboard ? (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        navigator.clipboard.writeText(String(params.id).padStart(6, '0'))
                                    }}
                                >
                                    <img alt={''} src={copyIcon} />
                                </IconButton>
                            ) : null}
                            {convertId(params.id)}
                        </Box>
                    )
                },
            },
            {
                propName: 'creationDate',
                field: 'creationDate',
                headerName: intl.formatMessage({ id: 'Дата создания' }),
                handleClick: () =>
                    isOperator() && selectedTab === 3 ? maintenanceReversePage('created') : reversPage('created'),
                // !reportRequests
                //         ? isOperator() && selectedTab
                //             ? () => reversPage("installed")
                //             : () => reversPage("created")
                //         : null,
                icon: <TableSortIcon isReverse={checkIsRevers('installed', 'created')} />,
                minWidth: '130px',
                renderCellValue: (params) => (params.created ? moment(params.created).format('DD.MM.YYYY') : null),
            },
            {
                propName: 'installationDate',
                field: 'installationDate',
                headerName: intl.formatMessage({ id: 'Дата установки' }),
                handleClick: () => reversPage('installed'),
                icon: <TableSortIcon isReverse={checkIsRevers('installed')} />,
                minWidth: '135px',
                renderCellValue: (params) =>
                    params.installationDate ? moment(params.installationDate).format('DD.MM.YYYY') : null,
            },
            {
                propName: 'fullName',
                field: 'fullName',
                headerName: intl.formatMessage({ id: 'ФИО' }),
                handleClick: isAdmin() && !reportRequests ? () => reversPage('fullname') : null,
                minWidth: '190px',
                justifyContent: 'flex-start',
                headerStart: true,
                textAlign: 'left',
                icon: isAdmin() && !reportRequests ? <TableSortIcon isReverse={checkIsRevers('fullname')} /> : null,
                renderCellValue: (params) => {
                    if (filterStore.isHighlighted) {
                        const copy = filterStore.getParamCopy()
                        return markText(formatName(params), copy.fullName)
                    }
                    return formatName(params)
                },
            },
            {
                propName: 'address',
                field: 'address',
                headerName: intl.formatMessage({ id: 'Адрес' }),
                minWidth: '240px',
                handleClick: isAdmin() && !reportRequests ? () => reversPage('locality') : null,
                icon: isAdmin() && !reportRequests ? <TableSortIcon isReverse={checkIsRevers('locality')} /> : null,
                renderCellValue: (params) => {
                    if (filterStore.isHighlighted) {
                        const copy = filterStore.getParamCopy()
                        return (
                            <Tooltip title={markText(formatAddress(params), copy.address)} placement={'top-start'}>
                                {<div>{markText(formatAddress(params), copy.address)}</div>}
                            </Tooltip>
                        )
                    }
                    return (
                        <Tooltip title={formatAddress(params)} placement={'top-start'}>
                            {<div>{formatAddress(params)}</div>}
                        </Tooltip>
                    )
                },
                // renderCell: (params) => {
                //     render(<Tooltip></Tooltip>)
                // }
                headerStart: true,
                justifyContent: 'flex-start',
                textAlign: 'left',
            },
            {
                propName: 'phone',
                field: 'phone',
                headerName: intl.formatMessage({ id: 'Телефон' }),
                minWidth: '150px',
                headerStart: true,
                justifyContent: 'flex-start',
                textAlign: 'left',
                renderCellValue: (params) => formatPhoneNumber(params.phone),
            },

            {
                propName: 'preferentialCategory',
                field: 'preferentialCategory',
                headerName: intl.formatMessage({ id: 'Льготная категория' }),
                justifyContent: 'flex-start',
                headerStart: true,
                renderCellValue: (params) => {
                    if (filterStore.isHighlighted) {
                        const copy = filterStore.getParamCopy()
                        return markText(params.preferentialCategory, copy.preferentialCategory)
                    }
                    return params.preferentialCategory
                },
            },

            // Development #25592 удалил столбец таблицы на странице Управления заявками
            // {
            //     propName: "categories",
            //     field: "categories",
            //     headerStart: true,
            //     headerName: intl.formatMessage({id: "Льготные категории"}),
            //     justifyContent: "flex-start",
            //     renderCellValue: (params) => {
            //         return categoriesStore.formatCategory(params.categories)
            //     }
            // }
        ]

        if (isDealer()) {
            if (isReportDetailsOpen) {
                columns = columns.filter(
                    (column) => column.propName !== 'preferentialCategory' && column.propName !== 'priority'
                )
            }

            columns.push({
                propName: 'deleteRequest',
                field: 'deleteRequest',

                renderCell: (params) => {
                    const onClick = (e) => {
                        e.stopPropagation()
                        setRedDelete([params.id])
                        remuveRequest([params.id], () => setRedDelete([]))
                    }
                    return (
                        <Tooltip title={'Удалить заявку'} placement={'top-start'}>
                            <Box onClick={onClick}>
                                <img alt={''} src={deleteRequest} />
                            </Box>
                        </Tooltip>
                    )
                },
            })
        }

        // Предварительная фильтрация столбцов из окна настроек таблицы
        if (tableSettingsStore) {
            columns = columns.filter((column) => {
                if (
                    tableSettingsStore.columns.find((el) => {
                        if (el.propName === column.propName && el.checked === true) {
                            return el
                        }
                    }) ||
                    // эти три столбца всегда присутствуют на странице
                    column.propName === 'checkBox' ||
                    column.propName === 'status' ||
                    column.propName === 'id'
                ) {
                    return column
                }
            })
        }

        if (onRowClick && isDealer()) {
            columns.push({
                propName: 'details',
                field: 'details',
                headerName: intl.formatMessage({ id: 'Подробнее' }),
                renderCell: () => (
                    <IconButton>
                        <img alt={'open row'} src={arrowRight} />
                    </IconButton>
                ),
            })
        }

        const rolesSwitchResult = () => {
            switch (true) {
                case isLooker():
                    return columns.filter(
                        (el) => el.propName !== 'checkBox' && el.propName !== 'agreementCheckingStatus'
                    )
                case isAdministration() || isAdmin():
                    if (isAgreement) {
                        const availableProps = [
                            'agreementCheckingStatus',
                            'status',
                            // 'financial',
                            // 'priority',
                            'id',
                            'creationDate',
                            'fullName',
                            'address',
                            'phone',
                            'assignDealer',
                            'installationDate',
                        ]
                        return columns.filter((el) => availableProps.includes(el.propName))
                    } else {
                        let result = columns.filter(
                            (el) =>
                                !['preferentialCategory', 'priority', 'agreementCheckingStatus'].includes(el.propName)
                        )
                        result = isAdministration()
                            ? columns.filter((el) => !['priority'].includes(el.propName))
                            : result
                        if (reportRequests) {
                            return result.filter((el) => el.propName !== 'checkBox' && el.propName !== 'priority')
                        }

                        return result
                    }
                case isDealer():
                    if (isAgreement) {
                        const availableProps = [
                            'checkBox',
                            'agreementCheckingStatus',
                            'status',
                            // 'financial',
                            // 'priority',
                            'id',
                            'creationDate',
                            'fullName',
                            'address',
                            'phone',
                            'installationDate',
                        ]
                        return columns.filter((el) => availableProps.includes(el.propName))
                    } else if (isReportCreate) {
                        const availableProps = [
                            'checkBox',
                            'status',
                            'financial',
                            'id',
                            'installationDate',
                            'fullName',
                            'address',
                            'phone',
                        ]
                        return columns.filter((el) => availableProps.includes(el.propName))
                    } else {
                        const availableProps = isReportDetailsOpen
                            ? [
                                  'checkBox',
                                  'status',
                                  'id',
                                  'financial',
                                  'priority',
                                  'creationDate',
                                  'fullName',
                                  'address',
                                  'phone',
                                  'preferentialCategory',
                                  'categories',
                                  'installationDate',
                                  'deleteRequest',
                              ]
                                  .filter((el) =>
                                      collection.length !== 1 && reportStatus === 1
                                          ? true
                                          : !['deleteRequest', 'checkBox'].includes(el)
                                  )
                                  .filter((el) => (currentPath === '/requests' ? el !== 'priority' : true))
                            : [
                                  'isExpiredAssign',
                                  'checkBox',
                                  'status',
                                  'id',
                                  'financial',
                                  'priority',
                                  'creationDate',
                                  'fullName',
                                  'address',
                                  'phone',
                                  'preferentialCategory',
                                  'categories',
                                  'installationDate',
                              ].filter((el) => (currentPath === '/requests' ? el !== 'priority' : true))

                        const mobileAvailableProps = [
                            'checkBox',
                            'status',
                            'financial',
                            'priority',
                            'id',
                            'details',
                        ].filter((el) => (currentPath === '/requests' ? el !== 'priority' : true))

                        let correntArray = isWidthUp('sm', width) ? availableProps : mobileAvailableProps

                        return columns.filter((el) => correntArray.includes(el.propName))
                    }

                default:
                    const intersectionForRole = new Set(
                        [
                            'checkBox',
                            'agreementCheckingStatus',
                            'status',
                            'id',
                            'financial',
                            'priority',
                            'creationDate',
                            'fullName',
                            'address',
                            'phone',
                            'preferentialCategory',
                            'categories',
                        ].filter((el) => (currentPath === '/requests' ? el !== 'priority' : true))
                    )

                    let newColumn = columns.filter((el) => intersectionForRole.has(el.propName))
                    if (isOperator() && (selectedTab === 0 || selectedTab === 3)) {
                        newColumn = newColumn.filter(
                            (el) => el.propName !== 'checkBox' && el.propName !== 'agreementCheckingStatus'
                        )

                        newColumn.unshift({
                            propName: 'isTaken',
                            field: 'isTaken',
                            headerName: intl.formatMessage({ id: 'Обработка' }),
                            renderCell: (params) => {
                                return params.isTakenByMe ? (
                                    <Tooltip
                                        title={intl.formatMessage({ id: 'В обработке у меня' })}
                                        placement={'top-start'}
                                    >
                                        <img src={assignedToMe} alt={''} />
                                    </Tooltip>
                                ) : params.isTaken ? (
                                    <Tooltip
                                        title={intl.formatMessage({ id: 'В обработке у другого оператора' })}
                                        placement={'top-start'}
                                    >
                                        <img src={onCall} alt={''} />
                                    </Tooltip>
                                ) : (
                                    ''
                                )
                            },
                        })
                    }

                    if (isServiceTable) {
                        newColumn.filter((el) => el.propName !== 'preferentialCategory')
                    }

                    return newColumn
            }
        }

        const normalizeForServiceTables = () => {
            let result = rolesSwitchResult()

            if (isServiceTable) {
                result = result.filter((el) => {
                    if (
                        el.propName !== 'preferentialCategory' &&
                        el.propName !== 'phone' &&
                        el.propName !== 'installationDate' &&
                        el.propName !== 'sender' &&
                        el.propName !== 'checkBox' &&
                        el.propName !== 'isExpiredAssign' &&
                        el.propName !== 'financial' &&
                        el.propName !== 'assignDealer'
                    ) {
                        return el
                    }
                })

                const serviceTableCustomColumns = [
                    {
                        propName: 'assignDealer',
                        headerName: intl.formatMessage({ id: 'Дилер' }),
                        minWidth: '220px',
                        renderCell: (params) => {
                            return (
                                <Box className={'aln-start'} width={'100%'}>
                                    {/* {!!params.dealer && <img alt={""} src={avatarIcon} width={20} height={20} />} */}
                                    <Box ml={1} sx={{ padding: '0 0 0 50px' }}>
                                        {!!params.assignedDealerId && (
                                            <Typography variant={'body2'}>
                                                {dealersStore.getDealer(params.assignedDealerId)?.displayName}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )
                        },
                        headerStart: true,
                        justifyContent: 'flex-start',
                        padding: '0 0 0 54px',
                    },
                    {
                        propName: 'serviceCheckbox',
                        // headerElement: (
                        //     <Checkbox
                        //         onClick={(e) => e.stopPropagation()}
                        //         onChange={() => store.checkAllOrdersFunc()}
                        //         sx={{ [theme.breakpoints.down('sm')]: { padding: '0 0 0 9px' } }}
                        //     />
                        // ),
                        renderCell: (params) => {
                            let checked = selectedOrders.has(params.id)

                            const onChange = () => {
                                selectOrder(params.id)
                                // store.checkOrderFunc(params)
                            }
                            if (params.status === 1 && isAdmin()) {
                                return (
                                    <Checkbox
                                        onClick={(e) => e.stopPropagation()}
                                        checked={checked}
                                        onChange={onChange}
                                        sx={{ [theme.breakpoints.down('sm')]: { padding: '0 0 0 9px' } }}
                                    />
                                )
                            }
                           
                        },
                    },

                    {
                        propName: 'phoneNumber',
                        field: 'phoneNumber',
                        headerStart: true,
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        headerName: intl.formatMessage({ id: 'Телефон' }),
                        minWidth: '150px',
                        renderCellValue: (params) => formatPhoneNumber(params.phoneNumber),
                    },
                ]

                serviceTableCustomColumns.forEach((el) => {
                    if (
                        (isDealer() || isAdministration() || isOperator()) &&
                        (el.propName === 'assignDealer' || el.propName === 'serviceCheckbox')
                    ) {
                        return
                    }
                    if (el.propName === 'serviceCheckbox') {
                        return result.unshift(el)
                    }
                    if (el.propName === 'assignDealer') {
                        return result.splice(1, 0, el)
                    }

                    return result.push(el)
                })
            }

            return result
        }

        return normalizeForServiceTables()
    }, [onRowClick, sort, selectedOrders, selectedTab, collection, tableSettingsStore?.actionIndicator])

    const onPageChange = useCallback(
        (event, page) => {
            if (page >= 0) {
                store.loadPageAction(page)
            }
        },
        [store]
    )

    const onPageSizeChange = useCallback(
        (event) => {
            store.pageSize = event.target.value
            onPageChange(0, 0)
        },
        [onPageChange, store]
    )

    return (
        <CustomTable
            redDelete={redDelete}
            filterFromReports={filterFromReports}
            rowsPerPageOptions={isDealer() && currentPath === '/requests' ? [50, 100] : rowsPerPageOptions}
            doublePagination={doublePagination}
            containerStyle={containerStyle}
            rows={isServiceTable ? store.serviceCollection : lazyLoad ? tableCollection : collection}
            columns={getColumns}
            pageSize={pageSize}
            page={pageNumber}
            onRowClick={onRowClick}
            total={store.total || collection.length}
            onPageChange={
                isReportDetailsOpen ? debounceReport(onPageChange, 300) : debounce(clearSelectOrders, onPageChange, 300)
            }
            onPageSizeChange={onPageSizeChange}
            actionsBlock={
                isServiceTable
                    ? !isOperator() && (
                          <StackActions
                              stackActionStore={stackActionStore}
                              status={firstItem?.status}
                              collection={collection}
                          />
                      )
                    : !addReport &&
                      !reportRequests &&
                      !isAgreement &&
                      (!isOperator() || selectedTab !== 0) && (
                          <StackActions
                              stackActionStore={stackActionStore}
                              status={firstItem?.status}
                              collection={isDealer() && collection}
                          />
                      )
            }
            isLoading={!store.isLoaded}
            handleSettings={handleSettings}
            setCurrentOpenedRowIndex={!isServiceTable && setCurrentOpenedRowIndex}
            currentOpenedRowIndex={currentOpenedRowIndex}
            checkedCount={selectOrdersStore?.totalSelectedRows}
            frontendPagination={frontendPagination}
            acceptBulkAction={selectOrdersStore?.bulkAccept}
            hideUninformative={hideUninformative}
            showAcceptBulkMobileBtn={true}
            {...otherProps}
            headCheackbox={{
                disabled: addReport && selectedOrders.size === 0,
                onChange: () => selectOrders(collectionPage),
                allChecked: () =>
                    addReport
                        ? selectedOrders?.size === collectionReport.length && collectionReport.length !== 0
                        : (selectedOrders?.size === collectionPage.length && collectionPage.length !== 0) ||
                          selectedOrders?.size > collectionPage.length,
                toggletoggleGroupSelected: () =>
                    addReport
                        ? selectedOrders?.size < collectionReport.length && selectedOrders?.size !== 0
                            ? true
                            : false
                        : selectedOrders?.size < collectionPage.length && selectedOrders?.size !== 0
                        ? true
                        : false,
            }}
        />
    )
}
export default withWidth()(observer(RequestsTable))
