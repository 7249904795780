import { isOperator } from '../../../services/AccountService'
import FormStore from '../../../utils/stores/FormStore'
import moment from 'moment'

const initState = {
    surname: '',
    name: '',
    patronymic: '',
    phone: '',
    territory: '',
    district: '',
    locality: '',
    addressType: '',
    address: '',
    house: '',
    building: '',
    apartment: '',
    preferentialCategory: '',
    created: '',
    additionalInfo: '',
    senderId: '',
    equipmentId: '',
    reason: '',
    reasonId: '',
    financialSourceId: '',
    categories: [],
    hasDuplicates: false,
    // requisition: '',
}

const validateDataTemplate = {
    surname: { required: true },
    name: { required: true },
    territory: { required: true },
    district: { required: true },
    address: { required: true },
    locality: { required: true },
    financialSourceId: { required: true },

    created: {
        required: true,
        validators: [
            (value) => value.length < 16,
            (value) => {
                return moment().utc(true).diff(moment(value, 'DD-MM-YYYY,HH:mm').utc(true)) < 0
            },
        ],
    },
    senderId: { required: true },
}

class AddRequestFormStore extends FormStore {

    

    constructor() {

        const validateData = { ...validateDataTemplate };

        // if (/*!isOperator()*/true) {
        //     validateData.financialSourceId = { required: true };
        // }
        super({ initState, validateData })
        this.showErrors = true
        this.isValid = false
    }
    setFinancialSourceId = (financialSourceId) => this.data.financialSourceId = +financialSourceId
}

export default AddRequestFormStore
