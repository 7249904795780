import React from 'react'
import PDFConstructorFin from './PDFConstructorFin'

function ReportPDF({ id, orders, date, intl, contract,fin }) {
    
    const columns = [
        {
            title: intl.formatMessage({id: "Номер п/п"}),
            width: "8%",
            getContent: (item, i) => i + 1
        },
        {
            title: intl.formatMessage({ id: 'Номер Заявки' }),
            width: '12%',
            getContent: (item) => item.id,
        },
        {
            title: intl.formatMessage({ id: 'Услуга Исполнителя' }),
            width: '35%',
            getContent: (item) =>
                intl.formatMessage({
                    id:
                        orders[0].financialSourceId === 2
                            ? 'установка и настройка одного комплекта приемного оборудования по программе «Выгодный обмен»'
                            : 'установка и настройка 1 (одного) комплекта приемного оборудования',
                }),
        },
        {
            title: intl.formatMessage({ id: 'Идентификационный номер смарт-карты (ID Абонента)' }),
            width: '25%',
            getContent: (item) => item.equipmentId,
        },
        {
            title: intl.formatMessage({ id: 'Стоимость услуг, руб.' }),
            width: '20%',
            getContent: (item) => (item.financialSourceId === 2 ? '1700' : '6000'),
        },
    ]

    return <PDFConstructorFin data={orders} contract={contract} id={id} fin={fin} columns={columns} intl={intl} />
}

export default ReportPDF
