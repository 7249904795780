import {getReportStorages} from "src/services/ApiService"
import {makeAutoObservable} from "mobx"
import {getReportStorageTypes} from "../../../services/ApiService"

class StoragesStore {
    storageTypes = []
    storages = []
    shelvs = []
    boxes = []

    storagesName = ""
    shelvName = ""
    boxName = ""

    #availableReportStatuses = [100, 101, 102, 200]
    constructor() {
        makeAutoObservable(this)
    }

    checkIsReportAvailableToSelect = (status) => {
        return this.#availableReportStatuses.includes(status)
    }

    getReportStorageData = (storage, id) => {
        if (storage?.storageTypeId === id) {
            return storage.name
        } else if (storage?.parent) {
            return this.getReportStorageData(storage.parent, id)
        } else {
            return ''
        }
    }

    // Получение типов хранения
    loadStorageTypes = () => {
        getReportStorageTypes()
            .then((res) => {
                this.storageTypes = res.storageTypes
            })
            .then(() => {
                // получение названий хранений
                this.getStorageNames(this.storageTypes)
            })
            .then(() => {
                // получение данных о хранении
                this.loadStorages()
            })
    }

    getStorageNames = (array = []) => {
        const sortedArray = array.sort((a, b) => {
            if (a.nestingLvl > b.nestingLvl) {
                return 1
            }
            if (a.nestingLvl < b.nestingLvl) {
                return -1
            }
            return 0
        })

        if (sortedArray.length) {
            this.storagesName = sortedArray[0].name
            this.shelvName = sortedArray[1].name
            this.boxName = sortedArray[2].name
        }
    }

    loadStorages = () => {
        getReportStorages().then((res) => {
            this.storages = res.reportStorages
            const shelvs = []
            const boxes = []
            this.storages.forEach((el) => {
                if (el.childs) {
                    shelvs.push(...el.childs)
                }
            })
            shelvs.forEach((el) => {
                if (el.childs) {
                    boxes.push(...el.childs)
                }
            })
            this.shelvs = shelvs.map((el) => ({key: el.name, value: el.id}))
            this.boxes = boxes.map((el) => ({key: el.name, value: el.id}))
        })
    }

    getStorageName = (id) => {
        const value = this.shelvs.find((el) => el.value === id) || this.boxes.find((el) => el.value === id)
        return value?.key || id
    }
}

export default new StoragesStore()
