import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import RequestCommentsBlock from './RequestCommentsBlock'
import AddCommentBlock from './AddCommentBlock'
import RequestCommentsStore from './stores/RequestCommentsStore'
import { isAdministration, isOperator } from '../../services/AccountService'
import { observer } from 'mobx-react'


const CommentsBlock = ({store}) => {

    const { data } = store
    const { id,
        status,
        isTakenByMe,
        hasDuplicates,
        reportId,
        reportStatus,
        financialSourceId,
        dealer,
        dataCheckingStatus,
        additionalInfo,
        address,
        addressType,
        apartment,
        author,
        authorId,
        authorName,
        building,
        categories,
        created,
        district,
        equipmentId,
        house,
        images,
        installationDate,
        isTaken,
        locality,
        name,
        patronymic,
        phone,
        preferentialCategory,
        priority,
        reason,
        reasonId,
        receiverId,
        sender,
        source,
        surname,
        territory, } = data
   
    const [commentsStore, setCommentsStore] = useState({})
    const getComments = (id) => {
        if (id) {
            const commentsStore = new RequestCommentsStore({ id })
            setCommentsStore(() => commentsStore)
            commentsStore.load()
        }
    }

    useEffect(() => {
        getComments(id)
    }, [id,
        status,
        isTakenByMe,
        hasDuplicates,
        reportId,
        reportStatus,
        financialSourceId,
        dealer,
        dataCheckingStatus,
        additionalInfo,
        address,
        addressType,
        apartment,
        author,
        authorId,
        authorName,
        building,
        categories,
        created,
        district,
        equipmentId,
        house,
        images,
        installationDate,
        isTaken,
        locality,
        name,
        patronymic,
        phone,
        preferentialCategory,
        priority,
        reason,
        reasonId,
        receiverId,
        sender,
        source,
        surname,
        territory,])

    if (!isOperator() && !isAdministration()) {
        return (
            <>
                <RequestCommentsBlock store={commentsStore} defaultOpenState />
                <AddCommentBlock store={store} getComments={getComments} id={id} />
            </>
        )
    }

    return null
}

export default observer(CommentsBlock)
