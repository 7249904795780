import PagedCollectionStore from "../utils/stores/PagedCollectionStore"
import {getAgreementOrders, getOrders, getRequestToReport} from "../services/ApiService"
import {observable, when} from "mobx"
import {closeModalFn, openModalFn} from "../utils/controllers/ModalController"
import {checkMobileDevice} from "src/utils/utils/commonUtils"
import {isDealer} from "../services/AccountService"

class OrdersStore extends PagedCollectionStore {
   
    constructor({isAgreement, isAddRequestsToReportOpen} = {}) {
        super({
            action: isAgreement ? getAgreementOrders : isAddRequestsToReportOpen? getRequestToReport: getOrders,
            lazyLoad: true
        })
        this.isAgreement = isAgreement
        this.isAddRequestsToReportOpen = isAddRequestsToReportOpen
        this.pageSize = checkMobileDevice() ? 50 : 100
        this.loadParams = {
            page: 0,
            size: this.pageSize,
            sort: isDealer() && checkMobileDevice() ? "priority" : ""
        }
    }

    loadPageAction(page, pageSize = null) {
        openModalFn["progress-backdrop"]()
        super.loadPageAction(page, pageSize)
        when(() => this.isLoaded, closeModalFn["progress-backdrop"])
    }

    getOrder = (id) => {
        return this.collection.find((el) => el.id === id)
    }

    getCreateReportOrders = () => {
        this.isLoaded = false
        getOrders({
            status: 6,
            page: 0,
            dataCheckingStatus: [0, 3],
            size: 2000
        }).then((res) => {
            this.setData(res.content) 
            this.isLoaded = true
        })
    }
    
    
    updateOrder = (orderId, reportId) => {
        const data = {orderId}
        if (reportId) {
            data.reportId = reportId
        }

        
       
        
        return (this.isAgreement ? getAgreementOrders : this.isAddRequestsToReportOpen? getRequestToReport: getOrders)(data).then((res) => {
            if (res.content.length) {
                const newOrder = observable(res.content[0])
                let orderIndex = null
                this.collection.forEach((order, index) => {
                    if (order.id === orderId) {
                        orderIndex = index
                    }
                })
                if (typeof orderIndex === "number") {
                    this.collection.splice(orderIndex, 1, newOrder)
                    this.tableCollection.splice(orderIndex, 1, newOrder)
                    return newOrder
                } else {
                    this.reLoad()
                }
            } else {
                this.reLoad()
            }
        })
    }
}

export default OrdersStore
