import React from 'react'
import CustomDialog from '../CustomDialog/CustomDialog'
import { closeModalFn, isModalOpen, modalData, openModalFn } from '../../utils/controllers/ModalController'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { getStyles } from '../../utils/utils/commonUtils'
import { TransactionConfirmationStyles } from './styles/TransactionConfirmationStyles'
import infoIcon from '../../assets/images/common/info_blue.svg'
import { notifyInfo } from '../../services/ToastifyService/ToastifyService'

const TransactionConfirmation = () => {
    const intl = useIntl()
    const classes = getStyles(TransactionConfirmationStyles)

    const data = modalData['transaction-confirmation']

    const { text1, value1, text2, value2, processText, action, callback } = data

    const handleAction = () => {
        closeModalFn['transaction-confirmation']()
        openModalFn['progress-backdrop']()
        notifyInfo({
            text: processText,
            action: action,
            timer: 2500,
            width: '516px',
            callback: callback,
        })
    }

    return (
        <CustomDialog
            open={isModalOpen['transaction-confirmation']}
            onClose={closeModalFn['transaction-confirmation']}
            title={intl.formatMessage({ id: 'Подтверждение операции' })}
            styles={{ maxWidth: '429px', padding: '8px 16px 16px' }}
            smallTitle={true}
        >
            <>
                <Box sx={classes.container}>
                    <img src={infoIcon} />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }} ml={1} pt={0.5}>
                        <Typography>{text1}</Typography>
                        <Typography sx={{ fontWeight: '500', wordBreak: 'break-all' }}>{value1}</Typography>
                        <Typography>{text2}</Typography>
                        <Typography sx={{ fontWeight: '500' }}>{value2}</Typography>
                    </Box>
                </Box>
                <Box sx={classes.buttonContainer}>
                    <Button variant={'contained'} color={'primary'} onClick={handleAction} fullWidth={true}>
                        <FormattedMessage id={'Подтвердить'} />
                    </Button>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => {
                            closeModalFn['transaction-confirmation']()
                            callback()
                        }}
                        fullWidth={true}
                    >
                        <FormattedMessage id={'Отмена'} />
                    </Button>
                </Box>
            </>
        </CustomDialog>
    )
}

export default observer(TransactionConfirmation)
