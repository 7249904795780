import React from 'react'
import Box from '@mui/material/Box'
import { Button, Checkbox, MenuItem, Select, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import shevronDown from '../../../assets/images/common/shevronDown.svg'
import FormControl from '@mui/material/FormControl'
import stackActionsSelectStyles from './stackActionsSelectStyles'
import { getStyles } from '../../utils/commonUtils'
import { isDealer } from '../../../services/AccountService'

function StackActions({ stackActionStore, collection, status,}) {
    const {
        availableTabs,
        setSelectedTabName,
        selectedTabName,
        selectedTabAction,
        setSelectedChanged,
        selectChanged,
        store,
        resetSelect,
    } = stackActionStore
    const { isTableRowSelected, totalSelectedRows, selectAllCollection } = store || {}
    const classes = getStyles(stackActionsSelectStyles)
    

    let selectDisabled = isDealer() && (status !== 2) ? true : !isTableRowSelected

    return availableTabs.length ? (
        <>
            {collection && (
                <Box sx={{ ...classes.showOnMobile, ...classes.tableHeadCheckboxWrapper }}>
                    <Checkbox
                        checked={!!totalSelectedRows}
                        onChange={() => selectAllCollection(collection)}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ padding: '0 0 0 5px' }}
                    />
                    {!!store.totalSelectedRows && (
                        <Typography sx={{ fontSize: '11px', fontWeight: 400 }}>
                            Выбрано: {store.totalSelectedRows}
                        </Typography>
                    )}
                </Box>
            )}
            <Box className={'fx-nowrap'} pr={2} sx={isDealer() ? { ...classes.hideOnMobile } : {}}>
                <FormControl
                    sx={{
                        '& .MuiInputBase-root': {
                            margin: 0,
                        },
                    }}
                >
                    <Select
                        disabled={selectDisabled}
                        value={selectedTabName}
                        onOpen={(e) => {
                            if (!selectChanged) {
                                setSelectedTabName(e)
                            }
                        }}
                        onChange={(e) => {
                            setSelectedChanged(true)
                            setSelectedTabName(e)
                        }}
                        renderValue={(selectedTabName) => {
                            if ((!selectChanged && selectedTabName === 'Выберите действие') || selectDisabled) {
                                return (
                                    <Typography sx={{ fontWeight: 500, color: '#5F5F5F' }}>
                                        Выберите действие
                                    </Typography>
                                )
                            }

                            const item = availableTabs.find((el) => {
                                if (el.name === selectedTabName) {
                                    return el
                                }
                            })
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {item && item.icon ? <img src={item.icon} alt='item icon' /> : null}
                                    <Typography sx={{ ...classes.selectedItem }}>{item.name}</Typography>
                                </Box>
                            )
                        }}
                        sx={{
                            ...classes.customSelect,
                            fontWeight: selectDisabled && 700,
                            background: selectDisabled ? null : 'white',
                            borderRadius: '4px 0 0 4px',

                            '& .MuiOutlinedInput-notchedOutline': {
                                border: !selectDisabled && selectedTabAction ? '1px solid #1668B9' : null,
                            },
                        }}
                        MenuProps={{
                            sx: classes.menuProps,
                        }}
                        IconComponent={(e) => (
                            <img
                                className={e.className}
                                style={{ opacity: selectDisabled ? '0.3' : null }}
                                src={shevronDown}
                                alt={'arrow'}
                            />
                        )}
                    >
                        {availableTabs.map((el) => {
                            return (
                                <MenuItem value={el.name} key={el.name} sx={classes.menuItem}>
                                    {el.icon ? (
                                        <img style={{ marginRight: '4px' }} src={el.icon} alt={el.name} />
                                    ) : null}
                                    {el.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Button
                    sx={{
                        ...classes.actionBtn,
                        '&:hover': selectedTabAction
                            ? {
                                  background: '#19F',
                                  color: 'white',
                                  border: '1px solid #19F',
                                  borderLeft: 'none',
                              }
                            : {
                                  background: 'none',
                                  border: '1px solid #A5A5A5',
                                  borderLeft: 'none',
                              },
                        cursor: selectedTabAction ? 'pointer' : 'default',
                        background: selectedTabAction ? null : 'none',
                        color: !selectedTabAction ? '#A5A5A5' : null,
                        border: selectedTabAction ? '1px solid #1668B9' : '1px solid #A5A5A5',
                        borderLeft: 'none',
                    }}
                    onClick={() => {
                        if (selectedTabAction) {
                            selectedTabAction()
                            setSelectedChanged(false)
                        }
                    }}
                    variant={'contained'}
                >
                    <FormattedMessage id={'Выполнить'} />
                </Button>
            </Box>
        </>
    ) : (
        <div></div>
    )
}

export default observer(StackActions)
