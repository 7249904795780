import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import CustomAccordion from './utils/CustomAccordion'
import Stack from '@mui/material/Stack'
import DataUnitEditable from '../DataUnit/DataUnitEditable'
import { getComp } from '../../../utils/utils/DI'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormInput from '../FormInput'
import { updateRequest } from '../../../services/ApiService'
import { FormControl } from '@mui/material'
import { gState } from '../../../utils/controllers/GlobalStateController'
import AddRequestFormStore from '../../AddRequestDrawer/stores/AddRequestFormStore'
import { isAdmin, isAdministration, isAdminLabelAA, isDealer, isOperator, isSuspiciousDealer } from '../../../services/AccountService'
import MultipleSelect from '../MultipleSelect/MultipleSelect'
import { closeModalFn } from '../../../utils/controllers/ModalController'
import { notifyError, notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { formatAddress, formatName } from 'src/helpers/ordersUtiles'
import { placeNames } from 'src/helpers/globalVariables'
import InputField from '../InputField/InputField'

function InstallationData({
    children,
    order,
    operatorStore,
    defaultOpenState,
    filterStore,
    onEdit,
    isEdit,
    reportStore,
    requestStore,
    ordersStore,
}) {
    const intl = gState['intl']
    const dealersStore = getComp('DealersStore')
    const { shownSenders, getSenderId } = getComp('SendersStore')
    const {
        id,
        phone,
        territory,
        preferentialCategory,
        additionalInfo,
        status,
        equipmentId,
        reason,
        reasonId,
        financialSourceId,
        dataCheckingStatus,
    } = order
    const [formStore] = useState(() => new AddRequestFormStore())
    const categoriesStore = getComp('CategoriesStore')
    const { reasons, getInstallationReasonName, getInstallationReasonId } = getComp('InstallationReasonStore')
    const [comment, setComment] = useState('')

    const [error, setError] = useState(null)
    const [fins, setFins] = useState([])

    useEffect(() => {
        const territoryStore = getComp('TerritoryStore')
        const { territories } = territoryStore
        const { district, locality, territory } = order

        if (!territories || Object.keys(territories).length === 0) {
            setError('No territories available in the store')
            return
        }

        const findTerritory = territories[territory]
        if (!findTerritory) {
            setError(`Territory ${territory} not found`)
            return
        }

        const findDistrict = findTerritory.Territory?.Districts.find((el) => el.Name === district)
        if (!findDistrict) {
            setError(`District ${district} not found in territory ${territory}`)
            return
        }

        const findLocality = findDistrict.Localities.find((el) => el.Name === locality)
        if (!findLocality) {
            setError(`Locality ${locality} not found in district ${district}`)
            return
        }

        const allowedFinancialSourceIds = findLocality.AllowedFinancialSourceIds
        if (!allowedFinancialSourceIds) {
            setError(`Allowed financial source IDs not found for locality ${locality}`)
            return
        }
        
        setFins(allowedFinancialSourceIds)
        setError(null)
    }, [order])
    

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)

        if (match) {
            return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}-${match[5]}`
        }

        return null
    }
    const shownData = useMemo(() => {
        return [
            { value: territory, label: intl.formatMessage({ id: 'Регион' }) },
            {
                value: formatAddress(order),
                label: intl.formatMessage({ id: 'Адрес' }),
            },
            { value: formatName(order), label: intl.formatMessage({ id: 'Заказчик' }) },
            { value: reason, label: intl.formatMessage({ id: 'Причина' }) },
            { value: getInstallationReasonName(reasonId), label: intl.formatMessage({ id: 'Причина установки' }) },
            {
                value: formatPhoneNumber(phone),
                mask: '+7 (999)-999-99-99',
                label: intl.formatMessage({ id: 'Телефон' }),
            },
            { value: preferentialCategory, label: intl.formatMessage({ id: 'Льготная категория' }) },
            {
                value: categoriesStore.formatCategory(order.categories)
                    ? categoriesStore.formatCategory(order.categories)
                    : '-',
                label: intl.formatMessage({ id: 'Льготные категории' }),
            },
            { value: additionalInfo, label: intl.formatMessage({ id: 'Дополнительная информация' }) },
            ...(reportStore ? [{ value: equipmentId, label: intl.formatMessage({ id: 'Id оборудования' }) }] : []),
        ]
    }, [isEdit, id])

    const getFilteredReasons = () => {
        if (status === 6 && dataCheckingStatus === 0) {
            if (reasonId === 2 && financialSourceId === 1) {
                return reasons.filter((el) => true)
            } else if (reasonId === 3 && financialSourceId === 1) {
                return reasons.filter((el) => true)
            } else {
                return reasons
            }
        }

        return reasons.filter((el) => (isDealer() && [2, 4, 5].includes(status) ? el.value !== 1 : true))
    }

    const getDisplay = () => {
        const propToDisplay1 = [
            'territory',
            'district',
            'locality',
            'address',
            'addressType',
            'house',
            'building',
            'apartment',
            'surname',
            'name',
            'patronymic',
            'phone',
            'senderId',
        ]
        const propToDisplay2 = ['reasonId', 'surname', 'name', 'patronymic', 'phone']
        const propToDisplay3 = ['surname', 'name', 'patronymic', 'phone']
        const propToDisplay4 = ['categories', 'equipmentId', 'reasonId']
        const propToDisplay5 = ['categories', 'equipmentId']
        const propToDisplay6 = ['reasonId']
        if (isAdminLabelAA() && [15, 16].includes(status)) {
            return propToDisplay1
        }
        if (isDealer() && !isSuspiciousDealer()) {
            if ([2, 4, 5].includes(status)) {
                if ([1].includes(financialSourceId) && fins.includes(1)) {
                    return propToDisplay2
                } else {
                    return propToDisplay3
                }
            }
            if ([6].includes(status)) {
                if ([0].includes(dataCheckingStatus)) {
                    return propToDisplay2
                }
            }
        }
        if(isSuspiciousDealer()){
            if([2, 4, 5].includes(status)){
                if ([1].includes(financialSourceId) && fins.includes(1)){
                    return propToDisplay6
                }
            }
            if ([6].includes(status) && [2].includes(dataCheckingStatus) || [14].includes(status) ){
                
                   return propToDisplay4
                
                
            }
        }
    }

    const editData = [
        {
            type: 'select',
            propName: 'reasonId',
            validName: 'reasonId',
            error: reasonId === 1,
            label: intl.formatMessage({ id: 'Причина установки' }),
            items: getFilteredReasons(),
            defaultValue: getInstallationReasonId(order.reasonId),
        },

        {
            propName: 'surname',
            validName: 'surname',
            label: intl.formatMessage({ id: 'Фамилия' }),
            capitalizeFirstLetter: true,
        },
        { propName: 'name', validName: 'name', label: intl.formatMessage({ id: 'Имя' }), capitalizeFirstLetter: true },
        {
            propName: 'patronymic',
            validName: 'patronymic',
            label: intl.formatMessage({ id: 'Отчество' }),
            capitalizeFirstLetter: true,
        },
        { propName: 'phone', mask: '+7 (999)-999-99-99', type: 'text', label: intl.formatMessage({ id: 'Телефон' }) },
        {
            propName: 'territory',
            validName: 'territory',
            label: intl.formatMessage({ id: 'Регион' }),
            territorySelect: true,
            type: 'autocomplete',
            autocompleteOptions: { type: 'territory' },
            noView: isAdministration(),
        },
        {
            propName: 'district',
            validName: 'district',
            label: intl.formatMessage({ id: 'Округ' }),
            territorySelect: true,
            type: 'autocomplete',
            autocompleteOptions: { territory: formStore.data['territory'], type: 'district' },
        },
        {
            propName: 'locality',
            validName: 'locality',
            label: intl.formatMessage({ id: 'Населенный пункт' }),
            territorySelect: true,
            type: 'autocomplete',
            autocompleteOptions: {
                territory: formStore.data['territory'],
                district: formStore.data['district'],
                type: 'locality',
            },
        },
        { propName: 'address', validName: 'address', label: intl.formatMessage({ id: 'Наименование топонима' }) },
        {
            propName: 'addressType',
            validName: 'addressType',
            label: intl.formatMessage({ id: 'Топонимы' }),
            type: 'autocomplete',
            items: placeNames,
        },
        { propName: 'house', validName: 'house', label: intl.formatMessage({ id: 'Дом' }), noView: isAdministration() },
        {
            propName: 'building',
            validName: 'building',
            label: intl.formatMessage({ id: 'Корпус' }),
            noView: isAdministration(),
        },
        {
            propName: 'apartment',
            validName: 'apartment',
            label: intl.formatMessage({ id: 'Квартира' }),
            noView: isAdministration(),
        },
        {
            propName: 'preferentialCategory',
            validName: 'preferentialCategory',
            label: intl.formatMessage({ id: 'Льготная категория' }),
            noView: isAdministration(),
        },
        {
            type: 'select',
            propName: 'categories',
            validName: 'categories',
            label: intl.formatMessage({ id: 'Льготные категории' }),
            items: categoriesStore.collection.map((el) => {
                return { key: el.name, value: el.id }
            }),
            multiple: true,
            noView: isAdministration(),
        },
        { propName: 'reason', label: intl.formatMessage({ id: 'Причина' }), noView: isAdministration() },

        ...((status === 11 && isOperator()) || reportStore
            ? []
            : [
                  {
                      type: 'select',
                      propName: 'senderId',
                      validName: 'senderId',
                      defaultValue: getSenderId(order.sender),
                      label: intl.formatMessage({ id: 'Источник' }),
                      items: shownSenders.map((el) => ({ key: el.name, value: el.id })),
                      noView: isAdministration(),
                  },
              ]),
        ...(reportStore
            ? [
                  {
                      propName: 'equipmentId',
                      validName: 'equipmentId',
                      label: intl.formatMessage({ id: 'Id оборудования' }),
                  },
              ]
            : []),
    ].filter((el) =>getDisplay()? getDisplay()?.includes(el.propName) : true )

    

    
    

    useEffect(() => {
        if (isEdit) {
            const newData = {}
            editData.forEach((el) => {
                if (el.propName === 'categories') {
                    newData[el.propName] = categoriesStore.getInputCategories(order[el.propName])
                } else if (el.propName === 'reasonId') {
                    newData[el.propName] = getInstallationReasonId(order.reasonId)
                } else {
                    newData[el.propName] = el.defaultValue ? el.defaultValue : order[el.propName] || ''
                }
            })
            formStore.setData(newData)
        }
    }, [isEdit])

    // предварительная проверка данных на изменение полей добавлением пробелов
    const validateOnSpaces = (e) => {
        let validate = true
        Object.keys(formStore.data).forEach((key) => {
            if (formStore.data[key] !== order[key] && !!formStore.data[key]) {
                if (typeof formStore.data[key] === 'string' && typeof order[key] === 'string') {
                    if (formStore.data[key].trim() === order[key].trim()) {
                        // если поля не совпадают только по количетсву пробелов в начале или в конце - дальнейшая функция handleAccept не запускается
                        validate = false

                        const item = editData.find((el) => el.propName === key)
                        return notifyError(`Поле ${item.label} отредактировано некорректно`)
                    }
                }
            }
        })

        return validate ? handleAccept(e) : null
    }

    const handleAccept = () => {
        function normalizePhoneNumber(phoneNumber) {
            return phoneNumber.replace(/\D/g, '')
        }
        const data = {}
        if (comment && isAdminLabelAA() && [15, 16].includes(status)) {
            formStore.data['updateComment'] = comment
        }
        Object.keys(formStore.data).forEach((key) => {
            if (typeof formStore.data[key] === 'string') {
                formStore.data[key] = formStore.data[key].trim()
            }
            if (key === 'categories') {
                if (
                    formStore.data[key].length !== order[key].filter((el) => el).length ||
                    !formStore.data[key].every((newData) => order[key][newData])
                ) {
                    if (formStore.data[key].length > 0) {
                        data[key] = formStore.data[key]
                    }
                }
            } else if (formStore.data[key] !== order[key]) {
                if (key === 'created') {
                    if (formStore.data[key]) {
                        data[key] = Number(formStore.data[key])
                    }
                } else if (key === 'senderId') {
                    if (formStore.data[key] && getSenderId(order.sender) !== Number(formStore.data[key])) {
                        data[key] = Number(formStore.data[key])
                    }
                } else if (key === 'phone' && formStore.data[key]) {
                    data[key] = normalizePhoneNumber(formStore.data[key])
                } else if (key === 'reasonId') {
                    if (+formStore.data[key]) {
                        data[key] = +formStore.data[key]
                    }
                } else if (!!formStore.data[key]) {
                    return (data[key] = formStore.data[key])
                }
            }
        })

        if (reportStore) {
            data.orderId = id
            if (isAdmin()) {
                reportStore.updateAdminData(data).then(() => {
                    notifySuccess(intl.formatMessage({ id: 'Заявка обновлена' }))
                })
            } else {
                reportStore.clarifyData(data)
            }
        } else {
            updateRequest(id, data)
                .then(() => {
                    let param = {}
                    if (filterStore) {
                        Object.keys(filterStore.data).forEach((key) => {
                            if (!!filterStore.data[key]) {
                                if (key === 'dealer') {
                                    const dealer = dealersStore.getDealer(filterStore.data[key])?.subjectId
                                    if (dealer) {
                                        param[key] = dealersStore.getDealer(filterStore.data[key])?.subjectId
                                    }
                                } else if (key === 'id') {
                                    param['orderId'] = filterStore.data[key]
                                } else if (key === 'from') {
                                    param[key] = filterStore.data[key].format()
                                } else if (key === 'to') {
                                    param[key] = filterStore.data[key].format()
                                } else if (filterStore.data[key]) {
                                    param[key] = filterStore.data[key]
                                }
                            }
                        })
                    }
                    if (isOperator() && operatorStore.selectedTab === 0) {
                        operatorStore
                            .updateOrder(id)
                            .then((order) => {
                                if (requestStore) {
                                    if (order) {
                                        if (order.equipmentId) {
                                            requestStore(order.equipmentId)
                                        }
                                        requestStore.setData(order)
                                        requestStore.getImages()
                                    } else {
                                        closeModalFn['request-details']()
                                    }
                                }
                            })
                            .then(() => onEdit())
                    } else {
                        ordersStore
                            .updateOrder(id)
                            .then((order) => {
                                if (requestStore) {
                                    if (order) {
                                        if (order.equipmentId) {
                                            requestStore.setNewEquipment(order.equipmentId)
                                        }
                                        requestStore.setData(order)
                                    } else {
                                        closeModalFn['request-details']()
                                    }
                                }
                            })
                            .then(() => onEdit())
                    }
                })
                .then(() => {
                    notifySuccess(intl.formatMessage({ id: 'Заявка обновлена' }))
                })
        }
    }
    const handleReject = () => {
        onEdit()
    }
    const handleEdit = () => {
        onEdit()
    }

    return (
        <CustomAccordion
            summary={intl.formatMessage({ id: 'Данные абонента' })}
            defaultOpenState={defaultOpenState}
            onEdit={onEdit ? handleEdit : null}
        >
            <Stack spacing={1.5}>
                {isEdit
                    ? editData.map((el, i) => {
                          const {
                              propName,
                              validName,
                              label,
                              items,
                              autocompleteOptions,
                              multiple,
                              defaultValue,
                              territorySelect,
                              type,
                              capitalizeFirstLetter,
                              noView,
                              mask,
                              error,
                          } = el

                          if (multiple) {
                              return noView ? null : (
                                  <MultipleSelect
                                      key={i}
                                      items={items}
                                      value={formStore.data.categories}
                                      onChange={formStore.onChangeMultiple}
                                      label={label}
                                      propName={propName}
                                      validName={validName}
                                      mask={mask}
                                  />
                              )
                          }
                          return noView ? null : (
                              <FormControl fullWidth>
                                  <FormInput
                                      key={i}
                                      api={formStore}
                                      autocompleteOptions={autocompleteOptions}
                                      type={type || 'text'}
                                      items={!!items ? items : null}
                                      defaultValue={defaultValue}
                                      propName={propName}
                                      validName={validName}
                                      fullWidth
                                      mask={mask}
                                      autoSearch
                                      error={error}
                                      label={label}
                                      withoutErrorText
                                      territorySelect={territorySelect}
                                      capitalizeFirstLetter={capitalizeFirstLetter}
                                  />
                              </FormControl>
                          )
                      })
                    : shownData.map((el) => {
                          const { value, label } = el
                          return <DataUnitEditable key={label} name={label} value={value} />
                      })}
                {isEdit && children}
                {isAdminLabelAA() && [15, 16].includes(status) && isEdit ? (
                    <Box>
                        <Box mt={2} mb={1}>
                            <InputField
                                error={!comment}
                                multiline
                                value={comment}
                                onChange={() => setComment(event.target.value)}
                                rows={4}
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                            />
                        </Box>
                        {/*                 
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        onClick={()=>changeEquipment(() => setUpdateHistory(!updateHistory))}
                        disabled={(!!equipmentId && newEquipment !== equipmentId)? !comment : true}
                    >
                        <FormattedMessage id={'Принять изменения'} />
                    </Button> */}
                    </Box>
                ) : null}

                {onEdit ? (
                    isEdit ? (
                        <Box>
                            <Box className={'jst-sa'} sx={{ gap: '8px' }}>
                                <Button
                                    onClick={() => {
                                        validateOnSpaces()
                                        setComment('')
                                    }}
                                    disabled={isAdminLabelAA() && [15, 16].includes(status) ? !comment : false}
                                    color={'primary'}
                                    variant={'contained'}
                                    fullWidth
                                >
                                    {intl.formatMessage({ id: 'Применить изменения' })}
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleReject()
                                        setComment('')
                                    }}
                                    color={'primary'}
                                    variant={'contained'}
                                    fullWidth
                                >
                                    {intl.formatMessage({ id: 'Отмена' })}
                                </Button>
                            </Box>
                        </Box>
                    ) : null
                ) : null}
            </Stack>
        </CustomAccordion>
    )
}

export default observer(InstallationData)
