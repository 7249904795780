import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import Accordion from '@mui/material/Accordion'
import { css } from '@emotion/css'
import editIcon from '../../../../assets/images/common/edit.svg'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { Checkbox } from '@mui/material'

function CustomAccordion({
    defaultOpenState,
    summary,
    children,
    onEdit,
    height,
    preventHandleOpen = false,
    headColor,
    contentPadding,
    withCheckbox = false,
    checkboxAction,
    orderId,
    checkedServiceOrderId,
    setChecked,
    checked,
    arrowIcon,
    openedAccordionIndex,
    setOpenedAccordionIndex,
    index,
}) {
    useEffect(() => {
        if (setOpenedAccordionIndex) {
            if (openedAccordionIndex === index) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
    }, [openedAccordionIndex])

    const [open, setOpen] = useState(defaultOpenState)

    useEffect(() => {
        if (setChecked && checked !== undefined) {
            if (checkedServiceOrderId === orderId) {
                setChecked(true)
            } else {
                setChecked(false)
            }
        }
    }, [checkedServiceOrderId])

    const handleChange = () => {
        setOpen((open) => !open)

        if (setOpenedAccordionIndex) {
            setOpenedAccordionIndex(index)
        }
    }
    return (
        <Accordion
            expanded={open}
            onChange={preventHandleOpen ? null : () => handleChange()}
            classes={{
                paper: css({ backgroundColor: '#d5d5d5' }),
                root: css({ margin: '0 !important', boxShadow: 'none !important' }),
            }}
            sx={{ background: headColor || '#EDF1F5' }}
        >
            <AccordionSummary
                expandIcon={arrowIcon ? arrowIcon : <ExpandMoreIcon />}
                classes={{
                    content: css({ margin: '4px 0 !important' }),
                    root: css({ minHeight: `${height || '48px'} !important`, flexDirection: 'row-reverse' }),
                }}
                sx={
                    setChecked && {
                        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)',
                        },
                    }
                }
            >
                <Box className={'jst-sb aln-center'} width={1}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{summary}</Typography>
                    {onEdit ? (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpen(true)
                                onEdit()
                            }}
                        >
                            <img alt={''} src={editIcon} />
                        </IconButton>
                    ) : null}
                    {withCheckbox && (
                        <Checkbox
                            checked={checked}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => checkboxAction(orderId)}
                        />
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails 
                classes={{
                    root: css({
                        backgroundColor: '#fff !important',
                    }),
                }}
                sx={{ padding: contentPadding }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default observer(CustomAccordion)
