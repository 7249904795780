import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import { FormattedMessage, useIntl } from 'react-intl'
import OrderStatus from '../../../../components/customElements/RequestDataAccordions/OrderStatus'
import { isAdmin, isDealer, isLooker } from 'src/services/AccountService'
import InstallationData from '../../../../components/customElements/RequestDataAccordions/InstallationData'
import RequestsFilterStore from '../../../RequestsManagement/stores/RequestsFilterStore'
import UploadedPhotos from '../../../../components/customElements/RequestDataAccordions/UploadedPhotos'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import InputField from '../../../../components/customElements/InputField/InputField'
import PhotosUpload from '../../../RequestsManagement/components/utils/PhotosUpload'
import ChangeInstallationDate from '../../../../components/customElements/RequestDataAccordions/ChangeInstallationDate'
import HasDuplicates from '../../../../components/customElements/RequestDataAccordions/HasDuplicates'
import DataUnit from '../../../../components/customElements/DataUnit/DataUnit'
import RequestInfo from '../../../../components/customElements/RequestDataAccordions/RequestInfo'
import OrderHistory from '../../../../components/customElements/RequestDataAccordions/OrderHistory/OrderHistory'
import CommentsBlock from '../../../../components/RequestCommentsBlock/CommentsBlock'
import { closeModalFn } from 'src/utils/controllers/ModalController'
import arrowLeft from '../../../../assets/images/common/arrowLeft.svg'
import OrderInfoChips from '../../../../components/OrderInfoChips/OrderInfoChips'
import { getStyles } from 'src/utils/utils/commonUtils'
import commonStyles from '../../../../styles/commonStyles'
import StorageAccordion from 'src/components/customElements/RequestDataAccordions/StorageAccordion/StorageAccordion'
import { Tooltip } from '@mui/material'
import { getComp } from '../../../../utils/utils/DI'
import FinancialSourceAccordio from '../../../../components/customElements/RequestDataAccordions/FinancialSourceAccordio'
import CustomAccordion from '../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import moment from 'moment'
import { isAdminLabelAA } from '../../../../services/AccountService'
import SetEquipmentId from '../../../../components/customElements/RequestDataAccordions/SetEquipmentId'
import IsSuspicious from '../../../../components/customElements/RequestDataAccordions/IsSuspicious'
function ReportOrderDetailsDrawer({
    store,
    ordersStore,
    reloadCallback,
    fromMainPage,
    openReportDetails,
    onTop,
    currentOpenedRowIndex,
    isReportDetailsOpen,
    disableLinks,
    onRowClick,
}) {
    const intl = useIntl()
    const classes = getStyles(commonStyles)
    const {
        data,
        isOrderDetailsOpen,
        closeOrderDetails,
        isOrderEdit,
        handleEditOrder,
        setComment,
        comment,
        toClarify,
        checkOrder,
        handleEditImages,
        removeOrder,
        endClarifyData,
        changeInstallationDate,
        setOrderDataRequest,
        setCurrentOpenedRowIndex,
        apiCallCallback,
        setNewEquipment,
    } = store

    const changeCreationDateCallback = () => {
        ordersStore.reLoad()
    }

    const { status, id, installationDate, dataCheckingStatus, hasDuplicates, reportId, reportStatus, equipmentId } =
        data

    const [filterStore] = useState(() => new RequestsFilterStore())
    const [updateHistory, setUpdateHistory] = useState(false)

    useEffect(() => {
        setNewEquipment(equipmentId)
    }, [data.equipmentId])

    useEffect(() => {
        if (isOrderDetailsOpen && !isReportDetailsOpen) {
            apiCallCallback()
        }
    }, [isReportDetailsOpen])

    useEffect(() => {
        if (!isOrderDetailsOpen) {
            closeModalFn['photo-gallery']()
        }
    }, [isOrderDetailsOpen])

    // общая функция для изменения текущего индекса строки( в дальнейшем(в компоненте CustomTableBody) по смене индекса открывается дровер строки соотвествующей новому индексу)

    const handleChangeDrawer = (e) => {
        let newRowIndex = currentOpenedRowIndex

        if (e.target.name === 'prev' && currentOpenedRowIndex > 0) {
            newRowIndex = currentOpenedRowIndex - 1
        } else if (e.target.name === 'next' && currentOpenedRowIndex < ordersStore.collection.length - 1) {
            newRowIndex = currentOpenedRowIndex + 1
        }

        setCurrentOpenedRowIndex(newRowIndex)
        onRowClick({ data: ordersStore.collection[newRowIndex] })
    }

    const [editDate, setEditDate] = useState(false)

    const statusStore = getComp('StatusesStore')
    const { getStatusName } = statusStore

    return (
        <RightDrawer
            open={isOrderDetailsOpen}
            onClose={closeOrderDetails}
            isReportDetailsOpen={isReportDetailsOpen}
            title={
                <>
                    <FormattedMessage id={'Заявка №'} /> {id}
                </>
            }
            onTop={onTop}
        >
            <Box sx={classes.drawerStatusesInfo}>
                <OrderStatus status={status} report={!fromMainPage} />
                {/* При соотвествии условиям отображается номер кликабельный номер отчета, по клику открывает отчет */}
                {status >= 12 && (isAdmin() || isDealer()) && !isLooker() ? (
                    <OrderInfoChips
                        tooltip={getStatusName(reportStatus, true)}
                        openReportDetails={openReportDetails} //функция при клике открывает окно отчета
                        id={reportId} // id отчета
                        reportStatus={reportStatus} // статус отчета
                        haveChips={true} // регулирует наличие фона по типу чипса
                        showOrderStatusIcon={true} // регулирует отображение иконки статуса
                    />
                ) : null}
            </Box>
            {hasDuplicates && (
                <Box
                    className={'fx-center'}
                    sx={{
                        padding: '8px 16px 16px',
                        width: '100%',
                    }}
                >
                    <DataUnit value={intl.formatMessage({ id: 'Отмечена как повторная заявка' })} />
                </Box>
            )}
            {isAdminLabelAA() && [4, 102, 200].includes(reportStatus) ? (
                <SetEquipmentId
                    store={store}
                    inputsValues={data}
                    updateHistory={updateHistory}
                    setUpdateHistory={setUpdateHistory}
                />
            ) : null}
            {!isDealer() && <RequestInfo request={data} defaultOpenState openReportDetails={openReportDetails} />}

            <InstallationData
                onEdit={
                    ((isDealer() && (status === 14 || status === 6)) ||
                        (isAdmin() && status === 13) ||
                        (isAdminLabelAA() && [15, 16].includes(status) && [4, 102, 200].includes(reportStatus))) &&
                    handleEditOrder
                }
                isEdit={isOrderEdit}
                order={data}
                defaultOpenState
                drawerOpen={isOrderDetailsOpen}
                filterStore={filterStore}
                reportStore={store}
                reloadCallback={reloadCallback}
                ordersStore={ordersStore}
            >
                {status === 13 && dataCheckingStatus === 2 ? null : <PhotosUpload store={store} reportOrder />}
            </InstallationData>

            <CustomAccordion
                onEdit={
                    isDealer() &&
                    [6, 14].includes(status) &&
                    data.dataCheckingStatus === 2 &&
                    (() => setEditDate(!editDate))
                }
                summary={intl.formatMessage({ id: 'Данные установки' })}
                defaultOpenState={false}
            >
                {(isAdmin() || isDealer()) && (
                    <>
                        {editDate ||
                        (isAdminLabelAA() && [4, 102, 200].includes(reportStatus) && [15, 16].includes(status)) ? (
                            <ChangeInstallationDate
                                requestId={id}
                                isDefaultOpen={true}
                                requestStatus={status}
                                inputData={installationDate}
                                changeInstallationDate={changeInstallationDate}
                                reloadAction={fromMainPage ? reloadCallback : changeCreationDateCallback}
                                reportOrder={!!dataCheckingStatus && status !== 6}
                                store={store}
                            />
                        ) : (
                            <DataUnit value={moment(installationDate).startOf('day').format('DD-MM-YYYY')} />
                        )}
                    </>
                )}
                <UploadedPhotos store={store} onEdit={handleEditImages} order={data} defaultOpenState />
            </CustomAccordion>
            <CommentsBlock store={store} dataCheckingStatus={dataCheckingStatus} />
            <StorageAccordion order={data} />
            <FinancialSourceAccordio data={data} />
            {((isDealer() && dataCheckingStatus === 2) || isAdmin()) && (
                <HasDuplicates data={data} onChange={setOrderDataRequest} />
            )}

            { isAdmin() && (
                <IsSuspicious data={data} onChange={setOrderDataRequest} />
            )}
            <OrderHistory
                id={id}
                isOrderEdit={isOrderEdit}
                openReportDetails={openReportDetails}
                isReportDetailsOpen={isReportDetailsOpen}
                disableLinks={disableLinks}
                updateCondition={updateHistory}
            />

            {dataCheckingStatus === 2 && isDealer() && status !== 13 && (
                <Box width={1} px={2} py={1}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        onClick={() => {
                            endClarifyData()
                        }}
                    >
                        <FormattedMessage id={'Закончить уточнение заявки'} />
                    </Button>
                </Box>
            )}

            {isAdmin() && status === 13 ? (
                <>
                    <Box>
                        <Box
                            width={1}
                            px={2}
                            py={1}
                            sx={{ display: dataCheckingStatus === 2 || reportStatus === 3 ? 'none' : 'block' }}
                        >
                            <Box mb={1}>
                                <InputField
                                    multiline
                                    value={comment}
                                    onChange={setComment}
                                    rows={4}
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {dataCheckingStatus === 1 || reportStatus === 3 || fromMainPage ? null : (
                        <Box width={1} px={2} py={1}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={checkOrder}
                                disabled={isLooker()}
                            >
                                <FormattedMessage id={'Подтвердить'} />
                            </Button>
                        </Box>
                    )}
                    {reportStatus === 2 && !openReportDetails ? (
                        <Box width={1} px={2} py={1}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                disabled={!comment || dataCheckingStatus === 2}
                                onClick={toClarify}
                            >
                                <FormattedMessage id={'Уточнить данные'} />
                            </Button>
                        </Box>
                    ) : null}
                    {reportStatus === 2 && ordersStore.collection.length > 1 && !fromMainPage && (
                        <Box width={1} px={2} py={1}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={removeOrder}
                                disabled={isLooker()}
                            >
                                <FormattedMessage id={'Убрать заявку'} />
                            </Button>
                        </Box>
                    )}
                    {reportStatus === 2 && !openReportDetails && (
                        // блок кнопок для переключения между предыдущей и следующей заявками
                        <Box sx={{ display: 'flex', gap: '10px', marginTop: '30px' }} width={1} px={2} py={1}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                name={'prev'}
                                fullWidth
                                onClick={handleChangeDrawer}
                                disabled={currentOpenedRowIndex === 0}
                                sx={classes.buttonActive}
                            >
                                <img name={'prev'} src={arrowLeft} alt={'Предыдущая заявка'} />
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                name={'next'}
                                fullWidth
                                onClick={handleChangeDrawer}
                                disabled={currentOpenedRowIndex === ordersStore.collection.length - 1}
                                sx={classes.buttonActive}
                            >
                                <img
                                    name={'next'}
                                    style={{ transform: 'rotate(180deg)' }}
                                    src={arrowLeft}
                                    alt={'Следующая заявка'}
                                />
                            </Button>
                        </Box>
                    )}
                </>
            ) : null}
        </RightDrawer>
    )
}

export default observer(ReportOrderDetailsDrawer)
