import React from 'react'
import { observer } from 'mobx-react'
import DataUnit from './DataUnit'
import Box from '@mui/material/Box'
import editIcon from '../../../assets/images/common/edit.svg'
import IconButton from '@mui/material/IconButton'

function DataUnitEditable({ name = '', value, icon, onEdit, copy = false, styleEdit }) {
    return (
        <Box mt={1.5} className={styleEdit ? 'aln-center' : !!onEdit && 'aln-center jst-sb'}>
            <DataUnit {...{ name, value, icon }} />
            {!!onEdit && (
                <IconButton
                    sx={styleEdit ? { padding: '0px', marginLeft: '8px', marginTop: '13px' } : null}
                    onClick={onEdit}
                >
                    <img alt={''} src={copy ? copy : editIcon} />
                </IconButton>
            )}
        </Box>
    )
}

export default observer(DataUnitEditable)
