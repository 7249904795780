import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import { getStyles } from '../../../../../../utils/utils/commonUtils'
import requestsManagementStyles from '../../../../styles/requestsManagementStyles'
import avatar from '../../../../../../assets/images/common/avatar.svg'
import Typography from '@mui/material/Typography'
import { Checkbox } from '@mui/material'

function DealerCard({ dealer, selected, onSelect, onlyShow }) {
    const classes = getStyles(requestsManagementStyles)
    return dealer ? (
        <Box
            sx={{ ...classes.dealerCard, border: onlyShow ? 'none' : classes.dealerCard.border }}
            className={'jst-sb aln-center'}
            onClick={onSelect}
        >
            <Box className={'aln-center'}>
                <img alt={''} src={avatar} style={{ width: '35px', height: '35px' }} />

                <Box ml={1.5}>
                    {/*<Typography variant={'subtitle1'}>*/}
                    {/*    {dealer.aid}*/}
                    {/*</Typography>*/}

                    <Box mt={0.5}>
                        <Typography variant={'subtitle1'}>{dealer.displayName}</Typography>
                    </Box>
                </Box>
            </Box>
            {!onlyShow && <Checkbox checked={selected} onChange={onSelect} />}
        </Box>
    ) : (
        <Typography variant={'subtitle1'}>{'Дилер не назначен'}</Typography> 
    )
}

export default observer(DealerCard)
