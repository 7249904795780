import React from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import {FormattedMessage, useIntl} from "react-intl"
import CustomAccordion from "../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion"
import {isAdmin, isDealer} from "../../../../../services/AccountService"
import Typography from "@mui/material/Typography"
import {useTheme} from "@mui/material"
import Button from "@mui/material/Button"
import InputField from "../../../../../components/customElements/InputField/InputField"

function ReportCommentBlock({store, status}) {
    const intl = useIntl()
    const theme = useTheme()
    const {data, toggleEditComment, isCommentEdit, saveNewComment, newComment, setNewComment} = store
    const {comment} = data

    return isDealer() && !comment ? null : (
        <CustomAccordion
            defaultOpenState={true}
            onEdit={isAdmin() && status !== 200 && toggleEditComment}
            summary={intl.formatMessage({id: "Комментарий"})}
        >
            {!isCommentEdit && comment ? (
                <Box
                    sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        padding: "8px",
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        wordBreak: "break-word"
                    }}
                >
                    <Typography variant={"body1"}>{comment}</Typography>
                </Box>
            ) : null}

            {isCommentEdit ? (
                <Box mt={2}>
                    <Box mb={1}>
                        <InputField
                            multiline
                            value={newComment}
                            onChange={setNewComment}
                            rows={4}
                            fullWidth
                            placeholder={intl.formatMessage({id: "Введите комментарий"})}
                        />
                    </Box>
                    <Button
                        onClick={saveNewComment}
                        disabled={!newComment || comment === newComment}
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                    >
                        <FormattedMessage id={"Оставить комментарий"} />
                    </Button>
                </Box>
            ) : null}
            {!comment && !isCommentEdit && !newComment ? (
                <Typography variant={"body1"}>
                    <FormattedMessage id={"Комментарий отсутствует"} />
                </Typography>
            ) : null}
        </CustomAccordion>
    )
}

export default observer(ReportCommentBlock)
