import React from "react"
import PDFConstructor from "./PDFConstructor"


function ReportPDF({id, orders, date, intl, contract}) {
    const columns = [
        {
            title: intl.formatMessage({id: "Номер п/п"}),
            width: "8%",
            getContent: (item, i) => i + 1
        },
        {
            title: intl.formatMessage({id: "Номер заявки"}),
            width: "12%",
            getContent: (item) => item.id
        },
        {
            title: intl.formatMessage({id: "Услуга Исполнителя"}),
            width: "35%",
            getContent: (item) => intl.formatMessage({id: "Установка и настройка приемного спутникового оборудования"})
        },
        {
            title: intl.formatMessage({id: "Идентификационный номер приемника Абонента"}),
            width: "25%",
            getContent: (item) => item.equipmentId
        },
        {
            title: intl.formatMessage({id: "Стоимость услуг руб."}),
            width: "20%",
            getContent: (item) => item.financialSourceId === 2 ? "1700" : "6000"
        },
    ]

    return (
        <PDFConstructor
            data={orders}
            contract={contract}
            id={id}
            columns={columns}
            intl={intl}
        />
    )
}

export default ReportPDF