import React from 'react'

const switchPriorityColor = (priority) => {
    switch (priority) {
        case 0:
            return '#2B3156'
        case 1:
            return '#03487E'
        case 2:
            return '#0871C4'
        case 3:
            return '#448BC2'
        case 4:
            return '#7091B7'
        case 5:
            return '#8FBFF6'
        default:
            return null
    }
}

export default switchPriorityColor
