import FormStore from "../../../utils/stores/FormStore"
import {action, makeObservable, observable, reaction} from "mobx"
import moment from "moment"
import {getComp} from "src/utils/utils/DI"
import StoragesStore from "src/views/Reports/stores/StoragesStore"

const initState = {
    creationDateFrom: null,
    creationDateTo: null,
    closeDateFrom: null,
    closeDateTo: null,
    status: "",
    statuses: [],
    dealerId: "",
    dealerIds: [],
    reportId: "",
    year: "",
    month: "",
    shelvs: "",
    boxes: [],
    financialSourceIds: [],
}

const validateData = {
    status: {required: true}
}

class ReportsFilterStore extends FormStore {
    constructor() {
        super({
            initState,
            validateData
        })
        this.dateNames = [
            ["creationDateFrom", "creationDateTo"],
            ["closeDateFrom", "closeDateTo"]
        ]
        this.dealersStore = getComp("DealersStore")
        makeObservable(this, {
            isHighlighted: observable,
            makeHighlightTrue: action,
            makeHighlightFalse: action
        })
        reaction(() => this.data.shelvs, () => {
                this.data.boxes = []
            }
        )
    }

    getBoxesFromShelv = () => {
        const {storages} = StoragesStore
        const shelv = storages[0]?.childs.find(shelv => shelv.id === this.data["shelvs"])
        if(shelv && shelv.childs){
            return shelv.childs.map(box => ({
                key: box.name,
                value: box.id
            }))
        } else {
            return []
        }
    }


    isHighlighted = false

    makeHighlightTrue = () => {
        if (this.data.id !== "") {
            return
        }
        this.isHighlighted = true
    }

    makeHighlightFalse = () => (this.isHighlighted = false)

    clear = () => {
        this.setData(initState)
        this.makeHighlightFalse()
        this.param = null
        this.clearSelectedFilters()
    }

    makeParamCopy = (param) => {
        this.param = param
    }

    getParamCopy = () => this.param
    
    getParams = () => {
        const param = {}
        for (const key in this.data) {
            if (!!this.data[key]) {
                switch (key) {
                    case "creationDateFrom":
                    case "creationDateTo":
                    case "closeDateFrom":
                    case "closeDateTo":
                        if (key.includes("From")) {
                            param[key] = this.data[key].format()
                        } else {
                            param[key] = this.data[key].endOf("day").format()
                        }
                        break
                    case "dealerIds":
                        param[key] = this.data[key].map((aid) => this.dealersStore.getDealer(aid)?.subjectId)
                        break
                    case "year":
                        param["year"] = this.data[key]
                        break
                    case "month":
                        param["month"] = this.data[key]
                        param["year"] ? (param["year"] = param["year"]) : (param["year"] = +moment().year())
                        break
                    case "shelvs":
                        if(!param["storageids"]){
                            param["storageids"] = []
                        }
                        if(!this.data["boxes"].length){
                            param["storageids"].push(this.data[key])
                        }
                        break
                    case "boxes":
                        if(!param["storageids"]){
                            param["storageids"] = []
                        }
                        param["storageids"].push(...this.data[key])
                        break
                    default:
                        param[key] = this.data[key]
                        break
                }
            }
        }
        return param
    }
}

export default ReportsFilterStore
