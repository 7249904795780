import React from 'react'
import { observer } from 'mobx-react'
import { css } from '@emotion/css'
import FileInput from '../../../../components/customElements/FileInput/FileInput'
import Box from '@mui/material/Box'
import fxStyles from '../../../../utils/styles/fxStyles'
import Typography from '@mui/material/Typography'
import { notifyError } from '../../../../services/ToastifyService/ToastifyService'
import cross from '../../../../assets/images/common/cross_dark.svg'
import IconButton from '@mui/material/IconButton'
import { gState } from '../../../../utils/controllers/GlobalStateController'

function PhotoUploadForm({ api, image, collectionName, reportCollectionLength, reportOrder }) {
    const {
        photoSettings: { photoPermission },
    } = api
    const intl = gState['intl']

    const setFile = (files) => {
        for (let fileNumber in files) {
            const collectionLength = api.data.images[collectionName].length
            if (
                collectionLength + (reportCollectionLength ? reportCollectionLength : 0) >=
                    photoPermission.totalLimit ||
                collectionLength >= photoPermission.maxLimitPerLoad
            ) {
                break
            } else {
                if (files[fileNumber].size > photoPermission.maxImageSizeInBytes) {
                    notifyError(
                        `${intl.formatMessage(
                            {
                                id: 'Размер файла превышает {size} мегабайт',
                                defaultMessage: 'Размер файла превышает {size} мегабайт',
                            },
                            {
                                size: photoPermission.maxImageSizeInBytes / 1000000,
                            }
                        )} : ${files[fileNumber].name}`
                    )
                } else {
                    api.data.images[collectionName].push({
                        file: files[fileNumber],
                        url: api.getNewImageUrl(files[fileNumber]),
                    })
                }
            }
        }
    }

    return !image ? (
        <FileInput
            multiple={true}
            file={null}
            setFile={setFile}
            fileTypes={['JPEG', 'JPG', 'PNG']}
            classes={css({ height: '76px' })}
            fileName
            label={intl.formatMessage({ id: 'Загрузите или перетащите файл сюда' })}
        />
    ) : (
        <Box sx={{ borderRadius: '8px', background: 'grey[200]', ...fxStyles.alnCenter }}>
            <Box className={'aln-center'}>
                <img alt={''} src={image.url} width={64} height={64} className={css({ borderRadius: '4px' })} />
            </Box>
            <Box ml={1.5} className={'aln-center'}>
                <Typography variant={'body1'} sx={{ overflowWrap: 'anywhere' }}>
                    {api.data.images[collectionName].find((el) => el.url === image.url).file?.name || ''}
                </Typography>
            </Box>
            <IconButton
                sx={{ height: '32px', width: '32px' }}
                onClick={() => api.deleteNewImage(image.url, collectionName)}
            >
                <img alt={''} src={cross} width={18} height={18} />
            </IconButton>
        </Box>
    )
}

export default observer(PhotoUploadForm)
