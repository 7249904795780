import FormStore from "../utils/stores/FormStore"

const initState = {
    "subjectId": "",
    "aid": "",
    "displayName": "",
    "registerTime": ""
}

const validateData = {
    "aid": {required: true},
    "displayName": {required: true},
}

class DealerFormStore extends FormStore {
    constructor() {
        super({
            initState,
            validateData
        })
    }
}

export default DealerFormStore