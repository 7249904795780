import { action, observable, makeObservable, override } from 'mobx'
import DataLoaderStore from './DataLoaderStore'
import { getReworkServiceOrders } from '../../services/ApiService'
import { closeModalFn, openModalFn } from '../controllers/ModalController'

class PagedCollectionStore extends DataLoaderStore {
    collection = []
    tableCollection = []
    total = null
    pageNumber = 0
    pageSize = 10
    sort = ''
    constructor(config = {}) {
        super(config)
        this.sorters = []
        this.filters = {}
        this.wrapRequest = config.wrapRequest || false
        this.wrapPropName = config.wrapPropName
        this.lazyLoad = config.lazyLoad
        this.timeouts = []
        this.loadPage = this.loadPageAction.bind(this)

        makeObservable(this, {
            collection: observable,
            tableCollection: observable,
            total: observable,
            pageNumber: observable,
            pageSize: observable,
            sort: observable,

            setPageSizeAndReload: action,
            loadPageAction: action,
            setData: override,
        })
    }
    isSomeChecked =()=>{
        
        return !this.collection.some((item) => item.checked)
    }

    checkIsRevers = (...params) => {
        return params.some((el) => this.sort.includes(el) && !this.sort.startsWith('-'))
    }

    reversPage = (param) => {
        openModalFn['progress-backdrop']()
        if (this.sort.includes(param)) {
            if (this.sort.startsWith('-')) {
                this.sort = param
            } else {
                this.sort = '-' + param
            }
        } else {
            this.sort = param
        }
        this.loadParams.sort = this.sort
        this.loadParams.page = 0
     
        this.reLoad(this.loadParams)
    }

    maintenanceReversePage = (param) => {
        if (this.sort.includes(param)) {
            if (this.sort.includes('-')) {
                this.sort = param
            } else {
                this.sort = '-' + param
            }
        } else {
            this.sort = param
        }
        this.loadParams.sort = this.sort
        this.loadParams.page = 0

        getReworkServiceOrders(this.loadParams).then((res) => {
            this.setData(res)
        })
    }

    setData(data) {
        closeModalFn['progress-backdrop']()
        // this.lazyLoad = false
        if (this.lazyLoad) {
            
            this.tableCollection = []
            if (this.timeouts.length) {
                this.timeouts.forEach((id) => clearTimeout(id))
                this.timeouts = []
            }
            ;(data.content || data).forEach((order, index, arr) => {
                if (arr.length - this.tableCollection.length < 50) {
                    setTimeout(() =>
                        this.timeouts.push(
                            this.tableCollection.push(...arr.slice(this.tableCollection.length, arr.length)),
                            Math.ceil(index / 50) * 500
                        )
                    )
                } else {
                    if (index % 50 === 0) {
                        this.timeouts.push(
                            setTimeout(() => {
                                this.tableCollection.push(
                                    ...arr.slice(this.tableCollection.length, this.tableCollection.length + 50)
                                )
                            }, (index / 50) * 500)
                        )
                    }
                }
            })
        }
        closeModalFn['progress-backdrop']()
        this.collection = data.content || data

        this.total = data.page !== undefined ? data.totalSize : this.total
        this.pageNumber = data.page !== undefined ? data.page : this.pageNumber
        super.setData(data)
    }

    loadAll = () => {
        const queryParam = this.filters
        this.reLoad(this.wrapRequest ? { queryParam } : queryParam)
    }

    loadPageAction(page, pageSize = null) {
        this.pageNumber = page
        const queryParam = {
            ...this.loadParams,
            page,
            size: pageSize || this.pageSize,
        }
        this.reLoad(queryParam)
    }

    setPageNumber = (value) => {
        this.pageNumber = value
    }

    setPageSizeAndReload = (value) => {
        this.pageSize = value
        this.loadPage(0)
    }
    onPageSizeChange = (e) => {
        this.pageSize = e.target.value
    }
}

export default PagedCollectionStore
