export const orderInfoChipsStyles = (theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
        padding: "4px 8px",
        gap: "8px",
        marginBottom: "8px"
    },
    text: {
        fontWeight: "500"
    }
})
