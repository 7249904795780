import React, { useEffect } from 'react'
import { isAdmin, isDealer, isLooker } from '../../services/AccountService'
import { Box } from '@mui/system'
import InputField from '../customElements/InputField/InputField'
import { Button } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { observer } from 'mobx-react'

const AddCommentBlock = ({ store, getComments, id }) => {
    const intl = useIntl()
    const { data, setComment, comment, toAddComment } = store
    const { status } = data

    const handleAddComment = () => {
        toAddComment(() => getComments(id))
    }

    if (
        (isAdmin() && status === 14)&& !isLooker() ||
        (isAdmin() && status === 6 && data.dataCheckingStatus === 2) ||
        (isDealer() && status === 6 && data.dataCheckingStatus === 2) ||
        (isDealer() && status === 14)
    ) {
        return (
            <Box width={1} px={2} py={1}>
                <Box mb={1}>
                    <InputField
                        multiline
                        value={comment}
                        onChange={setComment}
                        rows={4}
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                    />
                </Box>

                {isAdmin() && (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        disabled={!comment}
                        onClick={handleAddComment}
                    >
                        <FormattedMessage id={'Оставить комментарий'} />
                    </Button>
                )}
            </Box>
        )
    }
    return null
}

export default observer(AddCommentBlock)
