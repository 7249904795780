import { action, computed, makeObservable, observable, reaction } from 'mobx'

class StackActionStore {
    selectedTabName = 'Выберите действие'
    selectChanged = false
    constructor({ store }) {
        this.store = store

        this.tabs = this.store?.getActionTabs?.() || []
        makeObservable(this, {
            selectedTabName: observable,
            setSelectedTabName: action,
            availableTabs: computed,
            availableTabsNames: computed,
            selectedTabAction: computed,
            setSelectedChanged: action,
            selectChanged: observable,
        })

        // функция для сброса селекта если не выделен ни один элемент списка
        reaction(
            () => !this.store.isTableRowSelected,
            () => {
                if (!this.store.isTableRowSelected) {
                    this.setSelectedTabName(null, 'Выберите действие')
                    this.setSelectedChanged(false)
                }
            }
        )
    }

    setSelectedChanged = (value) => {
        this.selectChanged = value
    }

    get availableTabs() {
        if (this.store?.firstItem?.status === 2) {
            return this.tabs.filter((el) => el.isActive)
        }
        if(this.store?.firstItem?.status === 4) {
            return this.tabs.filter((el) => el.forStatus)
        }
        return this.tabs.filter((el) => el.isActive && !el.forStatus)
    }

    get availableTabsNames() {
        return this.availableTabs.map((el) => el.name)
    }

    setSelectedTabName = (e, string) => {
        if (string) {
            return (this.selectedTabName = string)
        }
        if (e !== null) {
            return (this.selectedTabName = e.target.value)
        }
    }
    get selectedTabActionNew() {
        const action = this.store
            ? this.store.isTableRowSelected && this.store.getActionTabs()?.find((el) => el.name === this.selectedTabName)?.action
            : null

        return action
            ? () => {
                  action()
                  this.selectedTabName = 'Выберите действие'
                  // Убрал сброс селекта на первый элемент списка для кастомного селекта
                  // Комментарий на случай если где то в других селектах такое поведение нежелательно
                  //   this.selectedTabName = this.availableTabs[0].name
              }
            : null
    }
     

    get selectedTabAction() {
        const action = this.store
            ? this.store.isTableRowSelected && this.availableTabs.find((el) => el.name === this.selectedTabName)?.action
            : null

        return action
            ? () => {
                  action()
                  this.selectedTabName = 'Выберите действие'
                  // Убрал сброс селекта на первый элемент списка для кастомного селекта
                  // Комментарий на случай если где то в других селектах такое поведение нежелательно
                  //   this.selectedTabName = this.availableTabs[0].name
              }
            : null
    }
}

export default StackActionStore
