import React, { useMemo, useState } from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import PTRootUIBold from '../../../../assets/fonts/Arimo/Arimo-Bold.ttf'
import PTRootUI from '../../../../assets/fonts/Arimo/Arimo-Regular.ttf'
import TimesNewRoman from '../../../../assets/fonts/TimesNewRoman/TimesNewRoman.ttf'
import TimesNewRomanBold from '../../../../assets/fonts/TimesNewRoman/TimesNewRomanBold.ttf'
import TimesNewRomanBoldItalic from '../../../../assets/fonts/TimesNewRoman/TimesNewRomanBoldItalic.ttf'
import moment from 'moment'
import { getComp } from '../../../../utils/utils/DI'
import DatesStore from '../../../../stores/DatesStore'
import { reportingPeriods } from 'src/helpers/getReportPeriodDate'

Font.register({
    family: 'Times New Roman',
    fonts: [
        { src: TimesNewRoman },
        { src: TimesNewRomanBold, fontWeight: 700 },
        { src: TimesNewRomanBoldItalic, fontStyle: 'italic' },
    ],
})

const styles = StyleSheet.create({
    page: {
        padding: 24,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        paddingBottom: 42,
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        height: 24,
        width: 104,
    },
    mainInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    datesContainer: {
        flexDirection: 'row',
    },
    mainBoldText: {
        fontFamily: 'Times New Roman',
        fontWeight: 700,
        fontSize: 10,
        textAlign: 'center',
    },

    tableHeader: {
        backgroundColor: '#afafae',
    },
    tableRow: {
        width: '100%',
        flexDirection: 'row',
    },
    tableHeaderCell: {
        padding: '2px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '2px',
    },

    title: {
        fontFamily: 'Times New Roman',
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'center',
    },
    mainText: {
        fontFamily: 'Times New Roman',
        fontSize: 12,
        textAlign: 'center',
    },
    text: {
        fontFamily: 'Times New Roman',
        fontSize: 12,
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
})

const PDFConstructor = ({ data, columns, intl, id, contract, fin }) => {
    
    const month = moment(data[0].installationDate).format('MM')
    const year = moment(data[0].installationDate).format('YYYY')
    const day = moment(data[0].installationDate).format('DD')
    const period = reportingPeriods[0].from <= Number(day) && reportingPeriods[0].to >= Number(day) ? 1 : 2
    const firstDay = reportingPeriods[period === 1 ? 0 : 1].from
    const lastDay =
        period === 2
            ? year === '2023' && month === '12'
                ? '19'
                : moment(month, 'MM').daysInMonth()
            : reportingPeriods[0].to
    const startPeriodText = moment(`${firstDay} ${month}`, 'DD MM').locale('ru').format('DD MMMM')
    const lastDayPeriodText = moment(`${lastDay} ${month}`, 'DD MM').locale('ru').format('DD MMMM')

    const dealerContractStore = getComp('DealerContractStore')
    const { contractDate, contractNumber } = dealerContractStore.data
    const [dateStore] = useState(new DatesStore())
    const { getMonthName } = dateStore
    const contractNumberString = useMemo(() => {
        return contractNumber < 10 && contractNumber !== 2 && contractNumber !== 7
            ? `0${contractNumber}`
            : contractNumber
    }, [contractNumber])
    function formatDateWithMonthName(dateString) {
        const months = [
            'января',
            'февраля',
            'марта',
            'апреля',
            'мая',
            'июня',
            'июля',
            'августа',
            'сентября',
            'октября',
            'ноября',
            'декабря',
        ]

        const [day, month, year] = dateString.split('.')

        const monthName = months[parseInt(month, 10) - 1]

        return `«${parseInt(day, 10)}» ${monthName} ${year}`
    }
    const currentYear = new Date().getFullYear()
    const lastTwoDigits = currentYear.toString().slice(-2)

    function removeSubstrings(str, substrings) {
        const regex = new RegExp(substrings.join('|'), 'g')
        return str.replace(regex, '')
    }

    return (
        <Document>
            <Page size='A4' style={styles.page}>
                {data.length !== 0 && (
                    <>
                        <View fixed>
                            <View style={styles.header}>
                                {(Number(month) <= 9 && year === '2023') || year < '2023' ? (
                                    <>
                                        <Text style={styles.title}>
                                            {intl.formatMessage({ id: 'ОТЧЁТ' })} №{id}
                                        </Text>
                                        <Text style={styles.mainText}>
                                            по Дилерскому договору оказания услуг №
                                            {contract?.number? contract?.number: fin ===3? '___' :  contractNumberString || '___'}
                                            {contract?.date
                                                ? formatDateWithMonthName(moment(contract?.date).format('DD.MM.YYYY'))
                                                : fin ===3? '____________': contractDate? formatDateWithMonthName(
                                                      moment(contractDate).format('DD.MM.YYYY')
                                                  ): '_______________'}{' '}
                                            г.
                                        </Text>
                                        <Text style={styles.mainText}>
                                            За {getMonthName(month)} {year} года
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text style={styles.title}>ОТЧЁТ</Text>
                                        <Text style={styles.mainText}>
                                            по Дилерскому договору оказания услуг №
                                            {contract?.number? contract?.number: fin ===3? '___' :  contractNumberString || '___'}
                                            {data[0].financialSourceId === 1 ? `/${lastTwoDigits}` : ''} от{' '}
                                            {contract?.date
                                                ? formatDateWithMonthName(moment(contract?.date).format('DD.MM.YYYY'))
                                                : fin ===3? '____________': contractDate? formatDateWithMonthName(
                                                      moment(contractDate).format('DD.MM.YYYY')
                                                  ): '_______________'}{' '}
                                            г.
                                        </Text>
                                        <Text style={[styles.mainText, { fontWeight: 600 }]}>
                                            за период с {startPeriodText} по {lastDayPeriodText} {year} г. №{id}
                                        </Text>
                                    </>
                                )}
                            </View>

                            <View style={[styles.tableRow, styles.tableHeader, { marginTop: 20 }]}>
                                {columns.map((column, index) => {
                                    return (
                                        <View
                                            style={[
                                                styles.tableHeaderCell,
                                                {
                                                    width: column.width,
                                                    borderLeft: !index && '1px solid #000',
                                                    borderRight: '1px solid #000',
                                                },
                                            ]}
                                        >
                                            <Text style={styles.mainBoldText}>{column.title}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                        {data.map((item, itemIndex) => (
                            <View wrap={false} style={[styles.tableRow]}>
                                {columns.map((column, index) => (
                                    <View
                                        style={[
                                            styles.tableCell,
                                            {
                                                width: column.width,
                                                borderLeft: !index && `1px solid #000`,
                                                borderRight: '1px solid #000',
                                            },
                                        ]}
                                    >
                                        <Text style={styles.mainText}>{column.getContent(item, itemIndex)}</Text>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </>
                )}

                <View wrap={false} style={[styles.tableRow, { border: '1px solid #000' }]}>
                    <View style={{ width: '80%', alignItems: 'flex-end', padding: '2px' }}>
                        <Text style={[styles.mainText, { fontWeight: 700 }]}>Итого:</Text>
                    </View>
                    <View style={{ width: '20%', borderLeft: '1px solid #000' }}>
                        <Text style={[styles.mainText]}>
                            {data[0].financialSourceId === 2 ? 1700 * data.length : 6000 * data.length}
                        </Text>
                    </View>
                </View>
            </Page>

            <Page size='A4' style={styles.page}>
                <View style={{ marginTop: '20px' }}>
                    <Text style={[styles.text]}>
                        Использовано {data.length} шт. стандартных комплектов приемного оборудования.
                    </Text>
                </View>

                <View style={{ marginTop: '16px' }}>
                    <Text style={[styles.text]}>Итого стоимость услуг Исполнителя за отчетный период:</Text>
                </View>

                <View style={{ marginTop: '16px', flexDirection: 'row' }}>
                    <View style={{ width: '100%', borderBottom: '1px solid #000', textAlign: 'center' }}>
                        <Text style={[styles.text]}>
                            {data[0].financialSourceId === 2 ? 1700 * data.length : 6000 * data.length} ({' '}
                            <Text style={{ fontFamily: 'Times New Roman', fontSize: 12, fontStyle: 'italic', textAlign: 'center' }}>
                                {removeSubstrings(
                                    getComp('ParseNumberStore').getParsedNumber(
                                        data[0].financialSourceId === 2 ? 1700 * data.length : 6000 * data.length
                                    ),
                                    ['рубль', 'рубля', 'рублей']
                                )}
                            </Text>
                            ) руб.
                        </Text>
                    </View>
                </View>

                <View style={{ marginTop: '16px' }}>
                    <Text style={[styles.text]}>Приложение:</Text>
                </View>
                <View style={{ marginTop: '8px' }}>
                    <Text style={[styles.text]}>- Оригиналы Актов, подписанных Абонентами – {data.length} шт.</Text>
                    <Text style={[styles.text]}>
                        - Оригиналы абонентских договоров, подписанных Абонентами – {data.length} шт.
                    </Text>
                </View>
                <footer style={{ flexDirection: 'row', height: '100px', marginTop: '80px' }}>
                    <View style={{ width: '50%', justifyContent: 'space-between', padding: '4px 4px 12px' }}>
                        <View>
                            <Text style={[styles.text]}>Заказчик:</Text>
                            <Text style={[styles.text]}>ООО "Русский Мир"</Text>
                            <Text style={[styles.text]}>Заместитель исполнительного директора</Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: '50%', borderBottom: '1px solid #000', position: 'relative' }}>
                                <View style={{ position: 'absolute', top: '20px' }}>
                                    <Text style={{ fontSize: 8, fontFamily: 'PT Root UI' }}>М.П.</Text>
                                </View>
                            </View>
                            <Text>/</Text>
                            <Text style={[styles.text, { marginTop: 4 }]}> Гришан Д.Н.</Text>
                        </View>
                    </View>
                    <View style={{ width: '50%', justifyContent: 'space-between', padding: '4px 4px 12px' }}>
                        <Text style={[styles.text]}>Исполнитель:</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: '40%', borderBottom: '1px solid #000', position: 'relative' }}>
                                <View style={{ position: 'absolute', top: '20px' }}>
                                    <Text style={{ fontSize: 8, fontFamily: 'PT Root UI' }}>М.П.</Text>
                                </View>
                            </View>
                            <Text>/</Text>
                            <View style={{ width: '40%', borderBottom: '1px solid #000' }}>
                                <View style={{ position: 'absolute', top: '20px' }}>
                                    <Text style={{ fontSize: 8, fontFamily: 'PT Root UI' }}>ФИО</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </footer>
            </Page>
        </Document>
    )
}

export default PDFConstructor
