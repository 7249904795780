import React from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {isAdmin, isDealer, isDealerOperator} from '../../../../../services/AccountService'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {pdf} from '@react-pdf/renderer'
import ReportPDF from '../../ReportPDF/ReportPDF'
import ReportFinPDF from '../../ReportFinPDF/ReportFinPDF'
import {openModalFn} from '../../../../../utils/controllers/ModalController'
import { getComp } from '../../../../../utils/utils/DI'

function ActionButtons({store, updateRequestManagmentTable}) {
    const dealerContractStore = getComp("DealerContractStore")
    // const {contractDate, contractNumber} = dealerContractStore?.data
    const intl = useIntl()
    const {
        data,
        startDataChecking,
        forceClose,
        allOrdersVerify,
        hasClarifyOrders,
        startReportDataClarifying,
        endReportDataClarifying,
        endDataChecking,
        sendReport,
        downLoadPDF,
        receiveReport,
        startCheckingReport,
        endCheckingReport,
        closeReport,
        allNonChecked,
        checkAllOrdersInReport,
        toChangeRecheckingState,
        contentState,
        newStatus,
        resendToDataCheck,
        resendToDocumentCheck,
        contracts,
        contract
    } = store
    const {status, id} = data
    
    const generatePDFDocument = (collection) => {
        return pdf([1,2,3].includes(collection[0].financialSourceId)?<ReportFinPDF contract={contract} fin={collection[0].financialSourceId} orders={collection} id={id} intl={intl} />:<ReportPDF contract={contract} orders={collection} id={id} intl={intl} />).toBlob()
    }

    const handleReportForce = () => {
        // при расформировании отчета обновляем таблицу на странице заявок
        const callback = () => {
            updateRequestManagmentTable()
        }
        forceClose(callback)
    }

    const handleCloseReport = () => {
        closeReport()
    }

    const buttonsData = [
        {
            isRender: isAdmin() && status === 1,
            name: intl.formatMessage({id: 'Начать проверку'}),
            callback: startDataChecking,
        },
        {
            isRender: isAdmin() && (status === 1 || status === 2 || status === 3),
            name: intl.formatMessage({id: 'Расформировать отчёт'}),
            callback: handleReportForce,
        },
        {
            isRender: isAdmin() && status === 2 && hasClarifyOrders,
            name: intl.formatMessage({id: 'Уточнить данные'}),
            callback: startReportDataClarifying,
            disabled: !allOrdersVerify,
        },
        {
            isRender: isAdmin() && status === 2 && !hasClarifyOrders,
            name: intl.formatMessage({id: 'Подтвердить'}),
            callback: endDataChecking,
            disabled: !allOrdersVerify,
        },
        {
            isRender: (isDealer() || isDealerOperator()) && status === 3,
            name: intl.formatMessage({id: 'Закончить уточнение данных'}),
            callback: endReportDataClarifying,
            disabled: hasClarifyOrders,
        },
        {
            isRender: (isDealer() || isDealerOperator()) && status === 4,
            name: intl.formatMessage({id: 'Скачать отчёт'}),
            callback: () => downLoadPDF(generatePDFDocument),
            disabled: !contracts.length && !dealerContractStore?.data?.contractDate
        },
        {
            isRender: (isDealer() || isDealerOperator()) && status === 4,
            name: intl.formatMessage({id: 'Отправить отчет'}),
            callback: sendReport,
            disabled: !contracts.length && !dealerContractStore?.data?.contractDate
        },
        {
            isRender: isAdmin() && status === 5,
            name: intl.formatMessage({id: 'Принять отчет'}),
            callback: receiveReport,
        },
        {
            isRender: isAdmin() && status === 100,
            name: intl.formatMessage({id: 'Начать проверку отчета'}),
            callback: startCheckingReport,
        },
        {
            isRender: isAdmin() && status === 101 && contentState === 'default',
            name: intl.formatMessage({id: 'Изменить статус'}),
            callback: toChangeRecheckingState,
        },
        {
            isRender: isAdmin() && status === 101 && contentState === 'default',
            name: intl.formatMessage({id: 'Закончить проверку'}),
            callback: endCheckingReport,
        },
        {
            isRender: isAdmin() && status === 101 && contentState === 'changeRechecking' && newStatus === 2,
            name: intl.formatMessage({id: 'Подтвердить изменение статуса'}),
            callback: resendToDataCheck,
        },
        {
            isRender: isAdmin() && status === 101 && contentState === 'changeRechecking' && newStatus === 4,
            name: intl.formatMessage({id: 'Подтвердить изменение статуса'}),
            callback: resendToDocumentCheck,
        },
        {
            isRender: isAdmin() && status === 102,
            name: intl.formatMessage({id: 'Закрыть отчет'}),
            callback: handleCloseReport,
        },
        {
            isRender: isAdmin() && allNonChecked && status === 2,
            name: intl.formatMessage({id: 'Проверить все'}),
            callback: checkAllOrdersInReport,
        },
    ]

    return (
        <>
            {buttonsData.map((button) => {
                const {name, isRender, callback, disabled} = button
                return isRender ? (
                    <Box width={1} p={2} key={name}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                            onClick={callback}
                            disabled={disabled}
                        >
                            {name}
                        </Button>
                    </Box>
                ) : null
            })}
        </>
    )
}

export default observer(ActionButtons)
