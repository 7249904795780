export function debounce(dop, func, wait = 500) {
    let timeout
    function debounced(...args) {
        const later = () => {
            func(...args)
        }
        
        dop && dop()
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }

    debounced.clear = () => {
        clearTimeout(timeout)
    }

    return debounced
}

export function debounceReport(func, wait = 500) {
    let timeout
    function debounced(...args) {
        const later = () => {
            func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }

    debounced.clear = () => {
        clearTimeout(timeout)
    }

    return debounced
}
