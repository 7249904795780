import React from "react"
import {observer} from "mobx-react"
import {isAdmin} from "src/services/AccountService"
import Content from "./Content"
import StoragesStore from "src/views/Reports/stores/StoragesStore"


const StorageAccordion = ({order}) => {
    const {checkIsReportAvailableToSelect} = StoragesStore
    return isAdmin() && checkIsReportAvailableToSelect(order.reportStatus) ? <Content order={order}/> : <></>
}

export default observer(StorageAccordion)