import { makeAutoObservable } from 'mobx'
import { getOrderHistory } from '../../../../../services/ApiService'
import moment from 'moment'
import { getComp } from '../../../../../utils/utils/DI'

class OrderHistoryStore {
    constructor() {
        this.statusesStore = getComp('StatusesStore')
        this.authorsStore = getComp('AuthorsStore')
        this.dealersStore = getComp('DealersStore')
        makeAutoObservable(this)
    }

    historyData = []

    loadHistory = (id) => {
        if (id) {
            getOrderHistory(id).then((res) => {
                this.normalizeData(res)
            })
        }
    }

    normalizeData = (data) => {
        const subscriberInfo = {
            Surname: 'Фамилия: ',
            Name: 'Имя: ',
            Patronymic: 'Отчество: ',
            Phone: 'Номер телефона: ',
            Territory: 'Регион: ',
            District: 'МО\\ГО: ',
            Locality: 'Населённый пункт: ',
            AddressType: 'Топоним адреса: ',
            Address: 'Улица: ',
            House: 'Номер дома: ',
            Building: 'Корпус: ',
            Apartment: 'Квартира: ',
            Reason: 'Причина установки: ',
            PreferentialCategory: 'Льготная категория (легаси): ',
            AdditionalInfo: 'Доп инфа легаси: ',
            EquipmentId: 'id оборудования: ',
            SenderId: 'Источник: ',
            Installed: 'Дата установки: ',
            Categories: 'Льготные категории: ',
        }

        const normalizedData = data.map((item) => {
            item.time = { value: moment(item.time).format('DD.MM.YYYY, HH:mm') /*+ '(UTC)'*/, label: 'Дата и время:' }
            item.status = {
                value: this.statusesStore.getStatusName(item.status),
                icon: this.statusesStore.getStatusImage(item.status),
                label: 'Статус:',
            }
            item.author = {
                value: this.authorsStore.getName(item.author)?.name
                    ? this.authorsStore.getName(item.author).name
                    : item.author,
                label: 'Автор:',
            }
            if (item.info.length) {
                item.info.forEach((element) => {
                    if (element.type === 1) {
                        item.dealer = {
                            value: this.dealersStore.getDealer(element.value)?.displayName,
                            label: 'Дилер:',
                        }
                    }
                    if (element.type === 2) {
                        item.photos = { value: element.value + ' фото добавлено', label: 'Фото:' }
                    }
                    if (element.type === 3) {
                        item.equipmentId = { value: element.value, label: 'Id оборудования:' }
                    }
                    if (element.type === 4) {
                        item.createDate = {
                            value: moment(element.value).format('DD.MM.YYYY, HH:mm'), // + '(UTC)',
                            label: 'Дата создания:',
                        }
                    }
                    if (element.type === 5) {
                        item.installationDate = {
                            value: moment(element.value).format('DD.MM.YYYY, HH:mm'), //+ '(UTC)',
                            label: 'Дата установки:',
                        }
                    }
                    if (element.type === 6) {
                        if (element.value) {
                            item.reportNumber = {
                                value: element.value.isAvailable
                                    ? element.value.id
                                    : `${element.value.id} (отчёт расформирован)`,
                                isAvailable: element.value.isAvailable,
                                label: 'Номер отчета:',
                            }
                        }
                    }
                    if (element.type === 7) {
                        item.financialSource = {
                            value: element.value,
                            label: 'Тип оплаты:',
                        }
                    }
                    if (element.type === 8) {
                        item.serialNumber = {
                            value: element.value,
                            label: 'Серийный номер:',
                        }
                    }
                    if (element.type === 9) {
                        const keys = Object.keys(subscriberInfo)

                        Object.keys(element.value).forEach((key) => {
                            if (keys.includes(key)) {
                                let value = element.value[key]

                                // если это дата установки, форматируем ее
                                if (key === 'Installed') {
                                    value = moment(value).format('DD.MM.YYYY')
                                }

                                // обработка льготных категорий
                                if (key === 'Categories' && Array.isArray(value.categories)) {
                                    value = value.categories
                                }

                                item[key] = { value: value, label: subscriberInfo[key] }
                            }
                        })
                    }
                    if (element.type === 10) {
                        item.reasonId = {
                            value: element.value,
                            label: 'Причина установки: ',
                        }
                    }

                    if (element.type === 11) {
                        let resTerritoryFrom = []
                        let resTerritoryTo = []
                        let resultString = ''
                        element.value.map((el) => {
                            if (
                                [
                                    'Territory',
                                    'District',
                                    'Locality',
                                    'AddressType',
                                    'Address',
                                    'House',
                                    'Building',
                                    'Apartment',
                                ].includes(el.property)
                            ) {
                                if (el.from) {
                                    resTerritoryFrom.push(el.from)
                                }
                                if (el.to) {
                                    resTerritoryTo.push(el.to)
                                }
                            } else {
                                item[el.property] = {
                                    value: el.from ? `${el.from} → ${el.to || '-'}` : el.to || '-',
                                    label: subscriberInfo[el.property],
                                }
                            }
                        })
                        resultString = `${resTerritoryFrom.join(', ')} → ${resTerritoryTo.join(', ')}`
                        resTerritoryFrom.length ? (item.Territory = { value: resultString, label: 'Адрес: ' }) : null
                    }
                })
            }
            delete item.info
            return item
        })

        return (this.historyData = normalizedData)
    }
}

export default OrderHistoryStore
