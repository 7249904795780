import {computed, makeObservable} from "mobx"
import pagedCollectionStore from "../../../utils/stores/PagedCollectionStore"
import {getComments, switchCommentsVisibility} from "../../../services/ApiService"
import {closeModalFn, openModalFn} from "../../../utils/controllers/ModalController"

class RequestCommentsStore extends pagedCollectionStore {
    constructor({id}) {
        super({action: () => getComments(id)})
        this.id = id
        makeObservable(this, ({
            isCommentVisible: computed
        }))
    }

    get isCommentVisible() {
        return this.collection.some(el => !el.isHidden)
    }

    toggleCommentVisible = () => {
        openModalFn["progress-backdrop"]()
        switchCommentsVisibility({
            orderId: this.id,
            show: !this.isCommentVisible,
            commentIdList: this.collection.map(el => el.id)
        }).then(() => {
            this.reLoad()
        }).finally(() => closeModalFn["progress-backdrop"]())
    }
    // {
//     "id": 1,
//     "text": "test",
//     "date": "2023-05-30T10:27:26.705569",
//     "author": "dealerLogin",
//     "authorId": "52521d1c-69d2-4fb7-bc6b-b5567ad3b1c0",
//     "isHidden": false
// }

}

export default RequestCommentsStore