import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { css } from '@emotion/css'
import Drawer from '@mui/material/Drawer'
import DrawerCircularProgress from '../DrawerCircularProgress/DrawerCircularProgress'
import Box from '@mui/material/Box'
import arrowLeft from '../../../assets/images/common/arrowLeft.svg'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import crossLight from '../../../assets/images/common/cross_light.svg'
import { getStyles } from '../../../utils/utils/commonUtils'
import commonStyles from '../../../styles/commonStyles'
import { useTheme } from '@mui/material'

function RightDrawer({
    open,
    onClose,
    children,
    loading,
    backButtonClick,
    title,
    titleComp,
    onTop,
    isReportDetailsOpen,
}) {
    const classes = getStyles(commonStyles)
    const theme = useTheme()

    // когда открывается окно отчетов дровер заявки исчезает
    // когда окно отчетов закрывается дровер появляется вновь
    const [isVisible, setIsVisible] = React.useState(false)
    useEffect(() => {
        if (isReportDetailsOpen) {
            setTimeout(() => {
                setIsVisible(!isVisible)
            }, 300)
        } else {
            setIsVisible(false)
        }
    }, [isReportDetailsOpen])

    return (
        <Drawer
            name={'menu'}
            variant={'temporary'}
            anchor={'right'}
            open={!!open}
            onClose={onClose}
            BackdropProps={{
                className: css({ background: 'rgba(21, 21, 21, 0.2)' }),
            }}
            classes={{
                root: css({
                    overflow: 'hidden',
                    position: 'relative',
                }),
                paper: css({
                    '&.MuiPaper-root': {
                        // background: theme.palette.grey[100],
                        width: 380,
                        borderRight: 'none',
                        boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.25)',
                        height: '100%',
                        paddingBottom: '40px',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                        },
                    },
                }),
            }}
            sx={{
                zIndex: onTop ? 1202 : 1200,
                // стили появления и исчезновения дровера
                transition: 'opacity 0.3s',
                opacity: isReportDetailsOpen ? 0 : 1,
                visibility: isVisible ? 'hidden' : 'visible',
            }}
            id='right-drawer'
        >
            {title || titleComp ? (
                <Box name={'topbar'} className={css(classes.drawerTopBar)}>
                    <Box className={'fx'}>
                        {backButtonClick ? (
                            <img alt={''} src={arrowLeft} className={'pointer'} onClick={backButtonClick} />
                        ) : null}

                        <Box ml={1.5} className={'aln-center'}>
                            {titleComp ? (
                                titleComp
                            ) : (
                                <Typography variant={'subtitle1'} color={'common.white'}>
                                    {title}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    <IconButton onClick={onClose}>
                        <img alt={''} src={crossLight} />
                    </IconButton>
                </Box>
            ) : null}

            <Box mt={7} sx={{ height: '100%' }}>
                {children}
                {loading ? <DrawerCircularProgress /> : null}
            </Box>
        </Drawer>
    )
}

export default observer(RightDrawer)
