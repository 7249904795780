import arrowDown from "../../../assets/images/common/arrow-down.svg"
import React from "react"
import {observer} from "mobx-react"

function TableSortIcon({isReverse}) {
    return (
        <img
            alt={""}
            src={arrowDown}
            style={{transform: `rotate(${isReverse ? 0 : "180deg"})`}}
        />
    )
}

export default observer(TableSortIcon)
