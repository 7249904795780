import DataLoaderStore from 'src/utils/stores/DataLoaderStore'
import {
    checkAllOrders,
    closeReport,
    editReportComment,
    endDataChecking,
    endDataClarifying,
    getOrders,
    getReports,
    receiveReport,
    reportForceClose,
    reportToPay,
    resendForDataCheck,
    resendForDocumentCheck,
    sendReport,
    startCheckingReport,
    startDataChecking,
    startDataClarifying,
} from 'src/services/ApiService'
import { computed, makeObservable, observable } from 'mobx'
import { closeModalFn, openModalFn } from 'src/utils/controllers/ModalController'
import { notifySuccess } from 'src/services/ToastifyService/ToastifyService'
import { gState } from 'src/utils/controllers/GlobalStateController'
import OrdersStore from 'src/stores/OrdersStore'
import ReportOrderDetailsStore from 'src/views/Reports/stores/ReportOrderDetailsStore'
import { getComp } from 'src/utils/utils/DI'
import { getContracts } from '../../../services/ApiService'

class SelectedReportStore extends DataLoaderStore {
    contracts = []
    contract = null
    constructor({ id, onClose }) {
        super({ action: () => getReports({ reportId: id }) })
        this.reportId = id
        this.isReportDrawerOpen = false
        this.isCommentEdit = false
        this.closeReportDetails = onClose
        this.statusesStore = getComp('StatusesStore')
        this.ordersStore = new OrdersStore()
        this.reportOrderDetailsStore = new ReportOrderDetailsStore({ reportId: id, ordersStore: this.ordersStore })
        this.newComment = ''
        this.newStatus = ''
        this.contentState = 'default'
        makeObservable(this, {
            isReportDrawerOpen: observable,
            newStatus: observable,
            contentState: observable,
            contracts: observable,
            contract: observable,
            // availableStatusesToChange: computed,
            isCommentEdit: observable,
            newComment: observable,
            allNonChecked: computed,
            allOrdersVerify: computed,
            hasClarifyOrders: computed,
        })
    }

    setData(data) {
        const newData = data ? data.content[0] : {}
        super.setData(newData)
    }

    toChangeRecheckingState = () => {
        this.contentState = 'changeRechecking'
    }

    toDefaultState = () => {
        this.newStatus = ''
        this.contentState = 'default'
    }
    availableStatusesToChange() {
        const availableStatuses = [2, 4]
        return this.data.status === 101
            ? this.statusesStore.reportStatuses.filter((status) => availableStatuses.includes(status.id))
            : []
    }
    // get availableStatusesToChange() {
    //     const availableStatuses = [2, 4]
    //     return this.data.status === 101
    //         ? this.statusesStore.reportStatuses.filter((status) => availableStatuses.includes(status.id))
    //         : []
    // }

    get allNonChecked() {
        return this.data.ordersDataCheckingStatuses.every((el) => el === 0 || el === 3)
    }

    get allOrdersVerify() {
        return this.data.ordersDataCheckingStatuses.every((el) => el === 1 || el === 2)
    }

    get hasClarifyOrders() {
        return this.data.ordersDataCheckingStatuses.some((el) => el === 2)
    }

    closeReportDrawer = () => {
        this.isReportDrawerOpen = false
        this.isCommentEdit = false
        this.newComment = ''
    }

    openReportDrawer = (dealer) => {
        if (dealer) {
            getContracts().then((res) => {
                this.contracts = res
                this.contract = this.contracts.find((el) => el.financialSourceType === this.data.financialSourceId)
                
            })
        }
        this.isReportDrawerOpen = true
    }

    toggleEditComment = () => {
        this.isCommentEdit = !this.isCommentEdit
        if (this.isCommentEdit && !!this.data.comment) {
            this.newComment = this.data.comment
        }
    }

    saveNewComment = () => {
        openModalFn['progress-backdrop']()
        editReportComment({
            text: this.newComment,
            reportId: this.reportId,
        })
            .then(() => {
                notifySuccess(
                    this.data.comment
                        ? gState['intl'].formatMessage({ id: 'Комментарий изменен' })
                        : gState['intl'].formatMessage({ id: 'Комментарий добавлен' })
                )
                this.reLoad()
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    setNewComment = (e) => {
        this.newComment = e.target.value
    }

    reLoadAction = () => {
        this.toDefaultState()
        this.reLoad({ page: 0, size: this.pageSize, reportId: this.reportId }).then(() => {
            this.ordersStore.reLoad({
                page: 0,
                size: this.ordersStore.pageSize,
                reportId: this.reportId,
            })
        })
    }

    startDataChecking = () => {
        openModalFn['progress-backdrop']()
        startDataChecking({ reportId: this.reportId })
            .then(() => {
                this.reLoadAction()
                notifySuccess(`Отчет №${this.reportId} проверяется`)
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    forceClose = (apiCallback) => {
        const actionFunction = () => {
            openModalFn['progress-backdrop']()
            reportForceClose({ reportId: this.reportId })
                .then(() => {
                    notifySuccess('Отчет расформирован')
                    this.closeReportDetails()
                    if (apiCallback) {
                        apiCallback()
                    }
                })
                .finally(() => closeModalFn['progress-backdrop']())
        }

        openModalFn['transaction-confirmation']({
            text1: 'Подтвердить расформирование отчета',
            value1: `№${this.reportId}?`,
            action: actionFunction,
            processText: `Выполняется расформирование отчета №${this.reportId}`,
        })
    }

    startReportDataClarifying = () => {
        openModalFn['progress-backdrop']()
        startDataClarifying({ reportId: this.reportId })
            .then(() => {
                this.reLoadAction()
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    endReportDataClarifying = () => {
        openModalFn['progress-backdrop']()
        endDataClarifying({ reportId: this.reportId })
            .then(() => {
                this.reLoadAction()
                this.isReportDrawerOpen = false
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    endDataChecking = () => {
        openModalFn['progress-backdrop']()
        endDataChecking({ reportId: this.reportId })
            .then(() => {
                this.reLoadAction()
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    checkAllOrdersInReport = () => {
        openModalFn['progress-backdrop']()
        checkAllOrders({ ReportId: this.reportId })
            .then(() => {
                this.reLoadAction()
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }

    sendReport = () => {
        openModalFn['progress-backdrop']()
        sendReport({ reportId: this.reportId })
            .then(() => this.reLoadAction())
            .finally(() => closeModalFn['progress-backdrop']())
    }

    downLoadPDF = (callback) => {
        openModalFn['progress-backdrop']()
        getOrders({ page: 0, size: 100000, reportId: this.reportId }).then((res) => {
            callback(res.content)
                .then((result) => {
                    const fileUrl = URL.createObjectURL(result)
                    let link = document.createElement('a')
                    link.href = fileUrl
                    link.download = `report#${this.reportId}.pdf`
                    link.click()
                    link.remove()
                    closeModalFn['progress-backdrop']()
                })
                .catch(() => closeModalFn['progress-backdrop']())
        })
    }

    receiveReport = () => {
        openModalFn['progress-backdrop']()
        receiveReport({ reportId: this.reportId })
            .then(() => {
                this.reLoadAction()
                notifySuccess(`Отчет №${this.reportId} принят`)
            })
            .finally(() => closeModalFn['progress-backdrop']())
    }
    startCheckingReport = () => {
        openModalFn['progress-backdrop']()
        startCheckingReport({ reportId: this.reportId })
            .then(() => this.reLoadAction())
            .finally(() => closeModalFn['progress-backdrop']())
    }
    endCheckingReport = () => {
        openModalFn['progress-backdrop']()
        reportToPay({ reportId: this.reportId })
            .then(() => this.reLoadAction())
            .finally(() => closeModalFn['progress-backdrop']())
    }
    closeReport = () => {
        const actionFunction = () => {
            openModalFn['progress-backdrop']()
            closeReport({ reportId: this.reportId })
                .then(() => this.reLoadAction())
                .then(() => {
                    notifySuccess('Отчет закрыт')
                })
                .finally(() => closeModalFn['progress-backdrop']())
        }
        openModalFn['transaction-confirmation']({
            text1: 'Подтвердить закрытие отчета',
            value1: `№${this.reportId}?`,
            action: actionFunction,
            processText: `Выполняется закрытие отчета №${this.reportId}`,
        })
    }

    resendToDataCheck = () => {
        openModalFn['progress-backdrop']()
        resendForDataCheck({ ReportId: this.reportId })
            .then(() => {
                this.reLoadAction()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    resendToDocumentCheck = () => {
        openModalFn['progress-backdrop']()
        resendForDocumentCheck({ ReportId: this.reportId })
            .then(() => {
                this.reLoadAction()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }
}

export default SelectedReportStore
