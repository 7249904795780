import React, { useEffect, useState } from 'react'
import CustomAccordion from '../utils/CustomAccordion'
import HistoryInfoElement from './components/HistoryInfoElement'
import { observer } from 'mobx-react'
import OrderHistoryStore from './store/OrderHistoryStore'
import { isAdmin, isDealer, isLooker, isOperator } from '../../../../services/AccountService'


const OrderHistory = ({
    id,
    contentState,
    isOrderEdit,
    updateCondition,
    openReportDetails,
    isReportDetailsOpen,
    disableLinks = false, // отключает ссылки на отчеты если true
    financialSourceId
}) => {
    const [historyStore] = useState(() => new OrderHistoryStore())
    const { loadHistory, historyData } = historyStore

   

    useEffect(() => {
        if (!isOperator() && !isDealer()) {
            if ((!isOrderEdit && !isReportDetailsOpen) || (isOrderEdit === false && isReportDetailsOpen)) {
                loadHistory(id)
            }
        }
    }, [id, contentState, isOrderEdit, updateCondition, isReportDetailsOpen, financialSourceId])

    if (isAdmin() && !isLooker() && !isOperator()) {
        return (
            <CustomAccordion summary={'История изменений'}>
                {historyData.map((item, index) => (
                    <HistoryInfoElement
                        key={index}
                        item={item}
                        openReportDetails={openReportDetails}
                        disableLinks={disableLinks}
                    />
                ))}
            </CustomAccordion>
        )
    }

    return null
}

export default observer(OrderHistory)
