import fxStyles from "../../../utils/styles/fxStyles"

const requestsManagementStyles = (theme) => ({
    fileDropArea: {
        height: "100px !important"
    },
    filePaper: {
        width: "100%",
        padding: "12px",
        borderRadius: "12px",
        ...fxStyles.jstSB,
        ...fxStyles.alnCenter
    },
    dealerCard: {
        borderRadius: "4px",
        border: "1px solid #E0DFDF",
        padding: "8px"
    },
    tab: {
        height: "24px",
        minHeight: "unset",
        padding: "4px 8px"
    },
    selectedTab: {
        background: "#a3e7ff"
    },
    tabs: {
        minHeight: "unset",
        marginBottom: "8px"
    },
    dealersScrollContainer: {
        height: "calc(100vh - 734px)",
        flex: "1 1 auto",
        overflow: "auto",
        padding: "3px 0"
    },

    tableWrapper: {
        marginTop: "16px",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            marginTop: "8px"
        }
    }
})

export default requestsManagementStyles
