import React from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import DataUnitEditable from "../../../../../components/customElements/DataUnit/DataUnitEditable"
import {useIntl} from "react-intl"
import {getComp} from "../../../../../utils/utils/DI"
import moment from "moment"
import {isAdmin} from "../../../../../services/AccountService"


function ReportInfo({report}) {
    const intl = useIntl()
    const statusesStore = getComp("StatusesStore")
    const {getStatusName, getStatusImage} = statusesStore
    const {status, closeTime, creationTime, id} = report
    return (
        <>
            <Box name={"status-info"} mb={1.5} mx={2}>
                <DataUnitEditable
                    name={intl.formatMessage({id: "Статус отчёта"})}
                    icon={getStatusImage(status, true)}
                    value={getStatusName(status, true)}
                />
                <DataUnitEditable
                    name={intl.formatMessage({id: "Дата создания"})}
                    value={moment(creationTime).format("DD.MM.YYYY")}
                />
                <DataUnitEditable
                    name={intl.formatMessage({id: "Дата закрытия"})}
                    value={closeTime ? moment(closeTime).format("DD.MM.YYYY") : null}
                />
                {
                    isAdmin() ?
                        <DataUnitEditable
                            name={intl.formatMessage({id: "Id"})}
                            value={id}
                        /> : null
                }
            </Box>
        </>

    )
}

export default observer(ReportInfo)