import React from "react"
import Box from "@mui/material/Box"
import {observer} from "mobx-react"
import {CircularProgress} from "@mui/material"

function DrawerCircularProgress() {
    return (
        <Box sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "rgba(255,255,255,0.35)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <CircularProgress/>
        </Box>
    )
}

export default observer(DrawerCircularProgress)