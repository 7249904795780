import FormStore from '../../../utils/stores/FormStore'
import { action, makeObservable, observable } from 'mobx'

const initState = {
    from: null,
    to: null,
    installedFrom: null,
    installedTo: null,
    status: '',
    statuses: [],
    phone: '',
    dealer: '',
    dealerIds: [],
    id: '',
    source: '',
    sender: '',
    authorId: '',
    authorName: '',
    preferentialCategory: '',
    categories: [],
    address: '',
    fullName: '',
    equipmentId: '',
    dataCheckingStatus: [],
    agreementCheckingStatus: [],
    reasonId: '',
    reason: '',
    priority: '',
    financialSourceIds: [],
    priorities: [],
    IsStrictSearch: false,
    hasDuplicates: false,
    isExpired: false,
    isSuspicious: false,
    surnamePartial: '',
    namePartial: '',
    territory: '',
    district: '',
    locality: '',
}

const validateData = {
    status: { required: true },
    territory: { required: true },
    district: { required: true },
}

class RequestsFilterStore extends FormStore {
    constructor() {
        super({
            initState,
            validateData,
        })
        this.param = null
        this.dateNames = [
            ['from', 'to'],
            ['installedFrom', 'installedTo'],
        ]
        makeObservable(this, {
            isHighlighted: observable,
            makeHighlightTrue: action,
            makeHighlightFalse: action,
            param: observable,
        })
    }

    isHighlighted = false

    makeHighlightTrue = () => {
        if (this.data.id !== '') {
            return
        }
        this.isHighlighted = true
    }

    makeHighlightFalse = () => (this.isHighlighted = false)
    clear = () => {
        this.setData(initState)
        this.makeHighlightFalse()
        this.param = null
        this.clearSelectedFilters()
    }
    makeParamCopy = (param) => {
        this.param = param
    }

    getParamCopy = () => this.param
}

export default RequestsFilterStore
