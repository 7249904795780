import FormStore from '../utils/stores/FormStore'
import {
    changeInstallationDateRequest,
    deleteImage,
    getImages,
    addOrderData,
    restoreImage,
} from '../services/ApiService'
import { closeModalFn, openModalFn } from '../utils/controllers/ModalController'
import moment from 'moment/moment'
import { notifySuccess } from '../services/ToastifyService/ToastifyService'
import { isAdmin } from '../services/AccountService'
import { makeObservable, observable } from 'mobx'
import { getComp } from '../utils/utils/DI'

const initState = {
    id: '',
    surname: '',
    name: '',
    patronymic: '',
    phone: '',
    territory: '',
    district: '',
    locality: '',
    addressType: '',
    address: '',
    house: '',
    building: '',
    apartment: '',
    status: '',
    isTaken: '',
    isTakenByMe: '',
    preferentialCategory: '',
    reason: '',
    created: '',
    additionalInfo: '',
    dealer: '',
    dealerId: '',
    authorId: '',
    authorName: '',
    source: '',
    sender: '',
    equipmentId: '',
    dataCheckingStatus: '',
    images: {
        install: [],
        impossible: [],
        uncompleted: [],
        clarify: [],
    },
    priority: '',
    financialSourceId: '',
    categories: [],
    installationDate: '',
    reasonId: '',
    reportId: '',
    hasDuplicates: false,
    isSuspicious: false,
    reportStatus: null,
    receiverId: null,
    author: '',
    isExpiredInstall: false,
    isExpiredAssign: false,
    isSuspicious: false,
}

const validateData = {
    id: { required: true },
    surname: { required: true },
    name: { required: true },
    patronymic: { required: true },
}

class RequestBaseStore extends FormStore {
    constructor() {
        super({
            initState,
            validateData,
        })
        this.photoSettings = getComp('PhotoSettingsStore')
        this.newStatus = 0
        makeObservable(this, {
            newStatus: observable,
        })
    }

    restorableStatuses = [6, 7, 14, 11] // статусы в которых можно восстановить фото

    isRestorable = () => {
        // в 6 статусе восстанавливается фото только при признаке Требуется уточнение
        if (this.data.status === 6 && ![0,2,3].includes(this.data.dataCheckingStatus)) {
            return false
        }

        return this.restorableStatuses.includes(this.data.status)
    }

    getImages = () => {
        getImages(this.data.id).then((images) => {
            for (const collectionKey in images) {
                this.data.images[collectionKey] = images[collectionKey].reduce((filtered, image) => {
                    if (isAdmin() || !image.isRemoved) {
                        image.url = window.SAT.config.apiUrl + `/images/getimage?imageId=${image.id}`
                        filtered.push(image)
                    }
                    return filtered
                }, [])
            }
            this.photoSettings.setOrder(this.data, this.newStatus)
        })
    }

    deleteImage(id) {
        openModalFn['progress-backdrop']()
        deleteImage({ imageId: id })
            .then(() => {
                notifySuccess('Фотография успешно удалена')
                this.getImages()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    restoreImage(id) {
        openModalFn['progress-backdrop']()
        restoreImage({ imageId: id })
            .then(() => {
                notifySuccess('Фото восстановлено')
                this.getImages()
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    getNewImageUrl = (file) => {
        return URL.createObjectURL(file)
    }

    deleteNewImage = (url, collectionName) => {
        this.data.images[collectionName] = this.data.images[collectionName].filter((image) => image.url !== url)
    }

    clear() {
        this.setData(initState)
    }

    changeInstallationDate = async (id, comment, date, reloadAction) => {
        const utcCorrectDate = moment(date, 'DD-MM-YYYY').local(true).format()
        openModalFn['progress-backdrop']()
        const data = { OrderId: id, date: utcCorrectDate }
        if (comment) {
            data.updateComment = comment
        }
        await changeInstallationDateRequest({
            ...data,
        })
            .then(() => {
                reloadAction()
                notifySuccess('Дата установки обновлена')
            })
            .then(() => {})
            .finally((err) => {
                closeModalFn['progress-backdrop']()
            })
    }

    setOrderData = (id, params, reloadAction) => {
        openModalFn['progress-backdrop']()
        addOrderData({ orderId: id, ...params })
            .then(() => {
                reloadAction && reloadAction()
            })
            .then(() => {
                closeModalFn['progress-backdrop']()
                notifySuccess('Данные заявки обновлены')
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }
}

export default RequestBaseStore
