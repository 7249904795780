import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { getComp } from '../../utils/utils/DI'
import { orderInfoChipsStyles } from './orderInfoChipsStyles'
import { getStyles } from '../../utils/utils/commonUtils'

const OrderInfoChips = ({
    id,
    reportStatus,
    openReportDetails,
    tooltip,
    haveChips,
    propsStyle,
    showOrderStatusIcon,
}) => {
    const classes = getStyles(orderInfoChipsStyles)
    const statusStore = getComp('StatusesStore')

    const openReportDetailsCallback = () => {
        if (openReportDetails) {
            openReportDetails({ id })
        }
    }

    const statusImage = statusStore.getReportStatusImage(reportStatus, true)

    return (
        <Box mx={2} sx={{ ...classes.container, background: haveChips ? 'rgb(237 241 245)' : null, ...propsStyle }}>
            {showOrderStatusIcon && reportStatus && (
                <Tooltip title={tooltip} placement={'top-start'}>
                    <img
                        style={{ width: '20px', height: '20px', marginBottom: '1px' }}
                        alt={`report status №${reportStatus}`}
                        src={statusImage}
                    />
                </Tooltip>
            )}
            <Typography
                sx={{
                    ...classes.text,
                    color: openReportDetails ? '#0871C3' : '#4e4e4e',
                    cursor: openReportDetails ? 'pointer' : 'default',
                    textDecoration: openReportDetails ? 'underline' : 'none',
                }}
                onClick={openReportDetailsCallback}
            >
                {id}
            </Typography>
        </Box>
    )
}

export default OrderInfoChips
