import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import { css } from '@emotion/css'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import DealerSelect from '../../../../../views/RequestsManagement/components/RequestDetails/AssignToDealerView/utils/DealerSelect'
import { observer } from 'mobx-react'
import InputField from '../../../../../components/customElements/InputField/InputField'
import { isAdmin } from '../../../../../services/AccountService'
import { gState } from '../../../../../utils/controllers/GlobalStateController'
import { getComp } from '../../../../../utils/utils/DI'

const ReportChangeStatus = ({ store, currentServiceData, closeChange }) => {
    const status = currentServiceData?.status
    const intl = useIntl()
    const {
        setComment,
        comment,
        contentState,
        newStatus,
        markAsImpossible,
        assignDealer,
        markServiceOrderAsDuplicateFunc,
    } = store
    useEffect(() => {
        store.newStatus = null
    }, [])
    const handleChangeStatus = () => {
        closeChange()
        switch (newStatus) {
            case 7:
                markAsImpossible(isAdmin())
                store.newStatus = null
                store.comment = ''
                break
            case 2:
                assignDealer(isAdmin())
                store.newStatus = null
                store.comment = ''
                break
            case 10:
                markServiceOrderAsDuplicateFunc({ top: true })
                store.newStatus = null
                store.comment = ''
                break
            default:
                break
        }
    }

    return (
        <Box name={'content'} mx={2} className={classNames('fx fx-col', css({ flex: '1 1 auto' }))} my={1}>
            <Box mb={2}>
                <Typography sx={{ fontSize: '14px' }} variant={'h6'}>
                    <FormattedMessage id={'Изменить статус'} />
                </Typography>
            </Box>
            <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                    <FormattedMessage id={'Статус'} />
                </InputLabel>
                <Select
                    value={newStatus}
                    label={intl.formatMessage({ id: 'Статус' })}
                    onChange={(event) => (store.newStatus = event.target.value)}
                >
                    {store.availableStatusesToChange(isAdmin(), status).map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                            <Box className={'aln-center'}>
                                <img alt={''} src={el.imageSrc} />
                                <Box ml={1}>
                                    <Typography variant={'subtitle2'}>{el.name}</Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            { newStatus === 7 || (newStatus === 10 && ![7,11].includes(status))  ? (
                <Box mt={2}>
                    <InputField
                        multiline
                        autoFocus
                        value={comment}
                        onChange={setComment}
                        rows={4}
                        fullWidth
                        placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                    />
                </Box>
            ) : null}
            {newStatus === 2 && isAdmin() && store?.serviceStore && (
                <Box mt={1}>
                    <DealerSelect currentServiceData={currentServiceData} store={store} />
                </Box>
            )}
            {contentState === 'changeRechecking' ? null : (
                <Box mt={2}>
                    <Button
                        fullWidth
                        variant='contained'
                        onClick={handleChangeStatus}
                        disabled={newStatus === 7 ? !comment : !newStatus}
                    >
                        <FormattedMessage id={'Подтвердить'} />
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default observer(ReportChangeStatus)
