import React, { useEffect } from 'react'
import { getStyles } from '../../../../../utils/utils/commonUtils'
import orderHistoryStyles from '../styles/orderHistoryStyles'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'

const HistoryInfoElement = ({ item, openReportDetails, disableLinks }) => {
    const classes = getStyles(orderHistoryStyles)
    const location = useLocation()
    const currentPath = location.pathname

    const itemProperties = [
        'info',
        'dealer',
        'createDate',
        'installationDate',
        'equipmentId',
        'photos',
        'financialSource',
        'reportNumber',
        'serialNumber',
        'Surname',
        'Name',
        'Patronymic',
        'Phone',
        'Territory',
        'District',
        'Locality',
        'AddressType',
        'Address',
        'House',
        'Building',
        'Apartment',
        'Reason',
        'PreferentialCategory',
        'AdditionalInfo',
        'EquipmentId',
        'SenderId',
        'Installed',
        'Categories',
        'www',
        'reasonId'
    ]

    

    const handleOpenReportDetails = (params) => {
        if (openReportDetails) {
            openReportDetails(params)
        }
    }

    const InfoItem = ({ data }) => {
        if (data) {
            // отдельно обрабатывается кейс "Номер отчета:" т.к там поле возвращаемого обьекта отличается
            if (data.label === 'Номер отчета:') {
                return (
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={classes.text}>{data?.label}</Typography>

                        <Typography
                            onClick={() =>
                                data.isAvailable && !disableLinks ? handleOpenReportDetails({ id: data?.value }) : null
                            }
                            sx={{
                                ...classes.text,
                                textDecoration: data.isAvailable && !disableLinks && (currentPath !== '/service') ? 'underline' : 'none',
                                cursor: data.isAvailable && !disableLinks && (currentPath !== '/service') ? 'pointer' : 'default',
                                color: data.isAvailable && !disableLinks && (currentPath !== '/service')  ? '#0871C3' : 'black',
                                marginLeft: '4px',
                            }}
                        >
                            {`${data?.value}`}
                        </Typography>
                    </Box>
                )
            }
            return <Typography sx={classes.text}>{`${data?.label} ${data?.value}`}</Typography>
        }
        return null
    }

    return (
        <Box sx={classes.infoElement}>
            {/* заголовок всех карточек всегда есть, одинаковый и составной */}
            <Typography sx={classes.title}>
                {item.time.value} - {item.author.value}
            </Typography>
            <Typography sx={classes.status}>
                <img style={classes.imgIcon} alt={'status icon'} src={item.status.icon} />
                {item.status.value}
            </Typography>
            {/* Остальные свойства карточки отображаются если есть */}
            {itemProperties.map((prop) => (
                <InfoItem key={prop} data={item[prop]} />
            ))}
        </Box>
    )
}

export default observer(HistoryInfoElement)
