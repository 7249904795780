const detailsPageStyles = (theme) => {
    return {
        backdrop: {
            position: 'fixed',
            // top: "var(--appbar-height)",
            padding: '32px 72px calc(32px + var(--footer-height))',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1201,
            background: 'rgba(0,0,0,0.25)',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        paper: {
            width: '100%',
            overflowY: 'auto',
            height: '100%',
            borderRadius: '4px',
            paddingTop: 0,
            [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                padding: '8px',
            },
        },
        stickyTitle: {
            position: 'sticky',
            top: 'var(--appbar-height)',
            background: 'white',
            zIndex: 1202,
        },

        stickyTitleStyles: {
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#F6F6F6',
            paddingTop: '24px',
            boxShadow: 'none',
            transition: 'padding 0.3s, fontSize 0.3s',
            cursor: 'default',
            // псевдоэлементы нужны для чтобы перекрыть выступающие тени от таблицы по бокам от заголовка
            '&:after': {
                content: '""',
                display: 'block',
                height: '40px',
                width: '10px',
                position: 'absolute',
                top: 0,
                left: '-10px',
                backgroundColor: '#F6F6F6',
            },

            '&:before': {
                content: '""',
                display: 'block',
                height: '40px',
                width: '10px',
                position: 'absolute',
                top: 0,
                right: '-10px',
                backgroundColor: '#F6F6F6',
            },
        },
    }
}

export default detailsPageStyles
