import RequestBaseStore from "../../../stores/RequestBaseStore"


class AddPhotosStore extends RequestBaseStore {
    constructor() {
        super()
    }

}

export default AddPhotosStore