import React from 'react'
import { observer } from 'mobx-react'
import DataUnitEditable from '../DataUnit/DataUnitEditable'
import Box from '@mui/material/Box'
import { getComp } from '../../../utils/utils/DI'
import { gState } from '../../../utils/controllers/GlobalStateController'

function OrderStatus({ status, onEdit, report, isService, styleEdit }) {
    const intl = gState['intl']
    const statusStore = getComp('StatusesStore')
    const {
        getStatusName,
        getStatusImage,
        getReportOrderStatusName,
        getReportOrderStatusImage,
        getServiceOrderStatusName,
        getServiceOrderStatusImage,
    } = statusStore

    return (
        <Box name={'status-info'} mb={1.5} mx={2} sx={{ flexGrow: 1 }}>
            <DataUnitEditable
                name={intl.formatMessage({ id: 'Состояние заявки' })}
                value={
                    report
                        ? getReportOrderStatusName(status)
                        : isService
                        ? getServiceOrderStatusName(status)
                        : getStatusName(status)
                }
                icon={
                    report
                        ? getReportOrderStatusImage(status)
                        : isService
                        ? getServiceOrderStatusImage(status)
                        : getStatusImage(status)
                }
                onEdit={onEdit}
                styleEdit={styleEdit}
            />
        </Box>
    )
}

export default observer(OrderStatus)
