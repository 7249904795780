import {observer} from "mobx-react"
import React from "react"
import Box from "@mui/material/Box"
import CustomAccordion from "../customElements/RequestDataAccordions/utils/CustomAccordion"
import Typography from "@mui/material/Typography"
import {FormattedMessage, useIntl} from "react-intl"
import moment from "moment"
import {useTheme} from "@emotion/react"
import {Checkbox, FormControlLabel} from "@mui/material"
import {isAdmin, isDealer, isDealerOperator, roles} from "../../services/AccountService"

function RequestCommentsBlock({defaultOpenState, store }) {
    const theme = useTheme()
    const intl = useIntl()

    return store.collection && store.collection.length ? (
        <CustomAccordion summary={intl.formatMessage({id: "Комментарии"})} defaultOpenState={defaultOpenState}>
            {store.collection.slice().reverse().map((comment, i) => {
                const {text, date, authorName, role} = comment
                const authorRole = roles[role]

                return (
                    <Box
                        sx={{
                            padding: "8px 8px 4px 12px",
                            borderRadius: "4px",
                            marginTop: i ? "2px" : 0,
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            position: "relative",
                            border: "1px solid #EBE8E8",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)"

                            // хвостик
                            // "&:before": store.collection.length - 1 === i && {
                            //     content: '""',
                            //     display: "block",
                            //     position: "absolute",
                            //     bottom: 0,
                            //     left: "-9px",
                            //     borderLeft: "10px solid transparent",
                            //     borderRight: "10px solid transparent",
                            //     borderTop: "10px solid transparent",
                            //     borderBottom: "10px solid rgb(237 241 245)",
                            //     boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)"
                            // }
                        }}
                    >
                        <Typography variant={"body1"} sx={{marginBottom: "4px", fontWeight: "600", fontSize: "12px"}}>
                            {isDealer() || isDealerOperator() ? authorRole : authorName || authorRole}
                        </Typography>
                        <Typography variant={"body1"} sx={{wordBreak: "break-word", fontSize: "14px"}}>
                            {text}
                        </Typography>
                        <Typography sx={{fontSize: "12px", textAlign: "right"}}>
                            {moment(date).format("DD.MM.YYYY, HH:mm")}
                        </Typography>
                    </Box>
                )
            })}
            {isAdmin() ? (
                <Box mt={2} className={"fx-nowrap aln-center"}>
                    <FormControlLabel
                        control={<Checkbox checked={store.isCommentVisible} onChange={store.toggleCommentVisible} />}
                        label={
                            <Typography variant="h6" sx={{fontWeight: "500"}}>
                                <FormattedMessage id={"Показать комментарии установщику"} />
                            </Typography>
                        }
                    />
                </Box>
            ) : null}
        </CustomAccordion>
    ) : null
}

export default observer(RequestCommentsBlock)
