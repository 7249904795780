import { makeAutoObservable } from 'mobx'
import * as XLSX from 'xlsx'
import { formatAddress, formatName, formatPhoneNumber } from '../../../helpers/ordersUtiles'
import moment from 'moment'
import { getComp } from '../../../utils/utils/DI'
import { getTitleFinancialSource } from '../../../helpers/switchFinancialSource'

class ParamsFilterStore {
    params = {}
    statuses = []
    DealerIds = []
    isExpired = false
    isExpiredAssign = false
    isExpiredInstall = false
    isExpiredFrom = ''
    isExpiredTo = ''
    statusesPortal = []
    constructor() {
        makeAutoObservable(this)
    }

    todayParams = (DealerIds, statuses) => {
        this.statusesPortal = statuses
        this.params = {
            statuses: statuses,
            DealerIds: DealerIds,
            isExpired: true,
        }
    }

    periodParams = (DealerIds, isExpiredAssign, isExpiredInstall, ExpiredFrom, ExpiredTo, statuses) => {
        this.statusesPortal = statuses
        this.params = {
            DealerIds: DealerIds,
            ExpiredFrom: ExpiredFrom,
            ExpiredTo: ExpiredTo,
        }
        if (isExpiredAssign) {
            this.params = {
                ...this.params,
                IsExpiredHistoryAssign: isExpiredAssign,
            }
        }

        if (isExpiredInstall) {
            this.params = {
                ...this.params,
                IsExpiredHistoryInstall: isExpiredInstall,
            }
        }
    }

    totalParams = (DealerIds, isExpiredAssign, isExpiredInstall, statuses) => {
        this.statusesPortal = statuses
        this.params = {
            DealerIds: DealerIds,
        }
        if (isExpiredAssign) {
            this.params = {
                ...this.params,
                IsExpiredHistoryAssign: isExpiredAssign,
            }
        }

        if (isExpiredInstall) {
            this.params = {
                ...this.params,
                IsExpiredHistoryInstall: isExpiredInstall,
            }
        }
    }

    loadFile = (data) => {
        const dealersStore = getComp('DealersStore')
        const statusStore = getComp('StatusesStore')

        const rows = data
            .map((row) => {
                if (row.checked) {
                    const { status, financialSourceId, dealer, sender, id, created, installationDate, phone } = row

                    return [
                        statusStore.getStatusName(status),
                        statusStore?.financialSourcesName[financialSourceId - 1] ||
                            getTitleFinancialSource(financialSourceId),
                        dealersStore.getDealer(dealer)?.displayName,
                        sender,
                        id,
                        created ? moment(created).format('DD.MM.YYYY') : null,
                        installationDate ? moment(installationDate).format('DD.MM.YYYY') : null,
                        formatName(row),
                        formatAddress(row),
                        formatPhoneNumber(phone),
                    ]
                }
            })
            .filter(Boolean)

        const fields = [
            [
                'Статус',
                'Оплата',
                'Дилер',
                'Источник',
                'Номер заявки',
                'Дата создания',
                'Дата установки',
                'ФИО',
                'Адрес',
                'Телефон',
            ],
            [],
            ...rows,
        ]

        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(fields)

        const colWidths = fields[0].map((_, colIndex) => {
            return {
                wch: Math.max(...fields.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))),
            }
        })

        ws['!cols'] = colWidths

        XLSX.utils.book_append_sheet(wb, ws, 'Отчет')
        XLSX.writeFile(wb, 'Отчет.xlsx')
    }
}

const paramsFilterStore = new ParamsFilterStore()

export default paramsFilterStore
