import { makeAutoObservable } from 'mobx';
import { gState } from 'src/utils/controllers/GlobalStateController';
import { isAdmin } from 'src/services/AccountService';
import boxIcon from 'src/assets/images/common/box.svg';
import { closeModalFn, modalData, openModalFn } from 'src/utils/controllers/ModalController';
import { addReportToStorage } from 'src/services/ApiService';
import { notifyInfo, notifySuccess } from 'src/services/ToastifyService/ToastifyService';
import { getDeclensionOfTheWord } from 'src/helpers/declensionOfTheWord';
import moment from 'moment/moment';
import { getComp } from 'src/utils/utils/DI';
import { useState } from 'react';
import DatesStore from 'src/stores/DatesStore';
import { getPeriodDaysString } from 'src/helpers/getReportPeriodDate';
import StoragesStore from 'src/views/Reports/stores/StoragesStore';

class ReportStoragesStore {
    selectedReports = [];
    tableCheckedStatus = false;
    #availableReportStatuses = [100, 101, 102, 200];
    selectedWarehouse = null;
    selectedShelv = null;
    selectedBox = null;

    storagesStore = StoragesStore;
    constructor() {
        makeAutoObservable(this);
    }

    #setSelectedReports = (report) => {
        this.selectedReports.push(report);
    };

    #toggleTableCheckedStatus = () => {
        this.tableCheckedStatus = !this.tableCheckedStatus;
    };

    #deleteSelectedReport = (report) => {
        this.selectedReports = this.selectedReports.filter((selectedReport) => selectedReport.id !== report.id);
    };

    checkIsReportSelected = (report) => {
        return this.selectedReports.some((el) => el.id === report.id);
    };

    clearSelectedReports = () => {
        this.selectedReports = [];
    };

    onClickReportCheckBox = (report) => {
        if (this.checkIsReportSelected(report)) {
            this.#deleteSelectedReport(report);
        } else {
            this.#setSelectedReports(report);
        }
        this.#toggleTableCheckedStatus();
    };

    get isTableRowSelected() {
        return !!this.selectedReports.length;
    }

    #filterAvailableReportsToSelect = (reports) => {
        return reports.filter((el) => this.#availableReportStatuses.includes(el.status));
    };

    onClickTableCheckBox = (collection) => {
        const availableCollection = this.#filterAvailableReportsToSelect(collection);
        if (availableCollection.length === this.selectedReports.length) {
            this.clearSelectedReports();
        } else {
            this.selectedReports = availableCollection;
        }
        this.#toggleTableCheckedStatus();
    };

    getActionTabs = () => {
        return [
            {
                name: gState['intl'].formatMessage({ id: 'Разместить на хранение' }),
                action: () => openModalFn['storage-drawer'](),
                icon: boxIcon,
                isActive: isAdmin(),
            },
        ];
    };

    openNotifier = (textComp, reLoad) => {
        this.selectedWarehouse = modalData['add-to-storage'].warehouse;
        this.selectedShelv = modalData['add-to-storage'].shelv;
        this.selectedBox = modalData['add-to-storage'].box;

        notifyInfo({
            textComp,
            action: () => this.addToStorage(reLoad),
            width: '516px',
        });
        closeModalFn['add-to-storage']();
    };

    downloadFile = () => {
        const sortedKeys = [
            'row',
            'status',
            'id',
            'authorId',
            'month',
            'ordersCount',
            'creationTime',
            'closeTime',
            'storage',
            'shelv',
            'box',
        ];

        const data = [
            [
                '№ п/п',
                gState['intl'].formatMessage({ id: 'Статус' }),
                gState['intl'].formatMessage({ id: 'Номер отчета' }),
                gState['intl'].formatMessage({ id: 'Автор' }),
                gState['intl'].formatMessage({ id: 'Наименование' }),
                gState['intl'].formatMessage({ id: 'Кол-во заявок в отчёте' }),
                gState['intl'].formatMessage({ id: 'Дата создания' }),
                gState['intl'].formatMessage({ id: 'Дата закрытия' }),
                this.storagesStore.storagesName,
                this.storagesStore.shelvName,
                this.storagesStore.boxName,
            ],
        ];

        this.selectedReports.forEach((report, i) => {
            data.push(
                sortedKeys.map((key) => {
                    switch (key) {
                        case 'row': {
                            return ++i;
                        }
                        case 'status': {
                            return getComp('StatusesStore').getReportStatusName(report[key]);
                        }
                        case 'authorId': {
                            return getComp('AuthorsStore').getName(report[key])?.name;
                        }
                        case 'month': {
                            const datesStore = new DatesStore();
                            const getReportName = () => {
                                return !report.interval
                                    ? gState['intl'].formatMessage(
                                          {
                                              id: 'Отчет №{id} за {month} {year} года',
                                              defaultMessage: 'Отчет №{id} за {month} {year} года',
                                          },
                                          {
                                              id: report.id,
                                              month: datesStore.getMonthName(report.month),
                                              year: report.year,
                                          }
                                      )
                                    : gState['intl'].formatMessage(
                                          {
                                              id: 'Отчет №{id} за {days} {month} {year} года',
                                              defaultMessage: 'Отчет №{id} за {days} {month} {year} года',
                                          },
                                          {
                                              id: report.id,
                                              month: datesStore.getMonthName(report.month, true),
                                              year: report.year,
                                              days: getPeriodDaysString(report.interval, report.month, report.year),
                                          }
                                      );
                            };
                            return getReportName();
                        }
                        case 'creationTime': {
                            return moment(report[key]).local().format('DD.MM.YYYY, HH:mm');
                        }
                        case 'closeTime': {
                            const value = moment(report[key]).local().format('DD.MM.YYYY, HH:mm');
                            if (moment(report[key]).local().format('DD.MM.YYYY, HH:mm') === 'Invalid date') {
                                return '-';
                            }
                            return value;
                        }
                        case 'storage': {
                            return StoragesStore.getReportStorageData(report['storage'], 1);
                        }
                        case 'shelv': {
                            return StoragesStore.getReportStorageData(report['storage'], 2);
                        }
                        case 'box': {
                            return StoragesStore.getReportStorageData(report['storage'], 3);
                        }
                        default: {
                            return report[key];
                        }
                    }
                })
            );
        });

        const blobData = data.map((el) => el.join(window.SAT.config.csvParams.divider)).join('\n');
        const encodeData = new TextEncoder('utf-16be').encode(blobData);
        const blob = new File(
            ['\uFEFF', encodeData],
            gState['intl'].formatMessage({ id: 'Отчёты' }) + ' ' + `(${moment().format('DD.MM.YYYY, HH:mm')})`,
            { type: `text/csv;${window.SAT.config.csvParams.charset}` }
        );

        const link = window.URL.createObjectURL(blob);
        window.location = link;

        this.selectedReports = [];
    };

    addToStorage = (reLoad) => {
        addReportToStorage({
            reportIds: this.selectedReports.map((el) => el.id),
            id: this.selectedBox.id,
        }).then(() => {
            closeModalFn['storage-drawer']();
            const message = gState['intl'].formatMessage(
                {
                    id: '{size} {report} {place} по адресу {warehouse} > {shelv} > {box}.',
                    defaultMessage: '{size} {report} {place} по адресу {warehouse} > {shelv} > {box}.',
                },
                {
                    size: this.selectedReports.length,
                    report: getDeclensionOfTheWord(
                        ['отчёт', 'отчёта', 'отчётов', 'отчёт', 'отчёта', 'отчётов'],
                        this.selectedReports.length
                    ),
                    place: getDeclensionOfTheWord(
                        ['размещен', 'размещены', 'размещены', 'размещен', 'размещены', 'размещены'],
                        this.selectedReports.length
                    ),
                    warehouse: this.selectedWarehouse.name,
                    shelv: this.selectedShelv.name,
                    box: this.selectedBox.name,
                }
            );
            notifySuccess(message);
            this.clearSelectedReports();
            reLoad();
        });
    };
}

export default ReportStoragesStore;
